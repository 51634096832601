import React from "react";
import DashboardAdmin from "../DashboardAdmin";
import ReportItem from "./ReportItem";

export default function ReportList() {
  return (
    <DashboardAdmin>
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <h4 className="card-title">Reports</h4>
                </div>
              </div>
              {/* Content */}
              <div className="row">
                <ReportItem
                  reportName={"Appointments"}
                  url={"/users/report/appointment"}
                />
                <ReportItem
                  reportName={"Pacientes por CIE 10"}
                  url={"/users/report/patientsCie10"}
                />
                {/* New Offline Payments Conciliation Report */}
                <ReportItem
                  reportName={"Offline Payments Conciliation"}
                  url={"/users/report/offlinePayments"}
                />
              </div>
              {/* End Content */}
            </div>
          </div>
        </div>
      </div>
    </DashboardAdmin>
  );
}
