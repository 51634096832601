import React, { useState, useEffect } from "react";
import {
  TextField,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Grid,
  Typography,
  Snackbar,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DashboardAdmin from "../DashboardAdmin";
import useBDocTranslator from "../../hooks/useBDocTranslator";
import useUserState from "../../hooks/useUserState";
import axios from "axios";
import { format, parseISO } from "date-fns";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3),
    overflowX: "auto",
  },
  paper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 650,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  filterButtons: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
  },
  dialogPaper: {
    minWidth: "600px",
    maxWidth: "800px",
  },
  dialogContent: {
    padding: theme.spacing(3),
  },
  formField: {
    marginBottom: theme.spacing(2),
  },
  paymentDetails: {
    marginBottom: theme.spacing(3),
  },
  paymentDetailItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  dialogActions: {
    padding: theme.spacing(2, 3),
  },
}));

export default function OfflinePaymentsReport() {
  const classes = useStyles();
  const { t } = useBDocTranslator();
  const userState = useUserState();
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [filters, setFilters] = useState({
    status: "",
    paymentMethod: "",
    startDate: "",
    endDate: "",
    conciliationStatus: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/offlinePayments`,
        {
          headers: {
            Authorization: `Bearer ${userState[0].jwt}`,
          },
        }
      );
      setPayments(response.data);
      setFilteredPayments(response.data);
    } catch (error) {
      console.error("Error fetching payments:", error);
      setError(t("Error fetching payments. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters({ ...filters, [name]: value });
  };

  const applyFilters = () => {
    let filtered = payments;
    if (filters.status) {
      filtered = filtered.filter(
        (payment) => payment.concilation_status === filters.status
      );
    }
    if (filters.paymentMethod) {
      filtered = filtered.filter(
        (payment) =>
          payment.payment_method.id === parseInt(filters.paymentMethod)
      );
    }
    if (filters.startDate) {
      filtered = filtered.filter(
        (payment) => parseISO(payment.created_at) >= parseISO(filters.startDate)
      );
    }
    if (filters.endDate) {
      filtered = filtered.filter(
        (payment) => parseISO(payment.created_at) <= parseISO(filters.endDate)
      );
    }
    setFilteredPayments(filtered);
  };

  const handleOpenDialog = (payment) => {
    setSelectedPayment(payment);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleUpdateConciliationStatus = async () => {
    setLoading(true);
    setError(null);
    try {
      await axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/offlinePayments/update`,
        {
          id: selectedPayment.id,
          concilation_status: selectedPayment.concilation_status,
          comments: selectedPayment.comments,
          updated_at: new Date(),
        },
        {
          headers: {
            Authorization: `Bearer ${userState[0].jwt}`,
          },
        }
      );
      await fetchPayments();
      handleCloseDialog();
    } catch (error) {
      console.error("Error updating conciliation status:", error);
      setError(t("Error updating conciliation status. Please try again."));
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    const headers = [
      "ID",
      "Patient Name",
      "Payment Method",
      "Amount",
      "Date",
      "Conciliation Status",
      "Reference",
      "Origin Reference",
      "Comments",
      "Appointment ID",
      "Affiliation ID",
    ];

    const csvData = [
      headers,
      ...filteredPayments.map((p) => [
        p.id,
        `${p.patient.firstName} ${p.patient.lastname}`,
        p.payment_method.name,
        p.amount,
        format(parseISO(p.created_at), "dd/MM/yyyy HH:mm"),
        p.concilation_status,
        p.reference,
        p.origin_reference,
        p.comments,
        p.appointment_id,
        p.affiliation_id,
      ]),
    ];

    const csvContent =
      "data:text/csv;charset=utf-8," +
      csvData.map((row) => row.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "offline_payments_report.csv");
    document.body.appendChild(link);
    link.click();
  };

  return (
    <DashboardAdmin>
      <Typography variant="h4" gutterBottom>
        {t("Offline Payments Conciliation Report")}
      </Typography>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel>{t("Conciliation Status")}</InputLabel>
              <Select
                name="status"
                value={filters.status}
                onChange={handleFilterChange}
              >
                <MenuItem value="">{t("All")}</MenuItem>
                <MenuItem value="in-review">{t("In Review")}</MenuItem>
                <MenuItem value="payed">{t("Payed")}</MenuItem>
                <MenuItem value="declined">{t("Declined")}</MenuItem>
                <MenuItem value="invalid">{t("Invalid")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel>{t("Payment Method")}</InputLabel>
              <Select
                name="paymentMethod"
                value={filters.paymentMethod}
                onChange={handleFilterChange}
              >
                <MenuItem value="">{t("All")}</MenuItem>
                {/* Add payment method options here */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="startDate"
              label={t("Start Date")}
              type="date"
              value={filters.startDate}
              onChange={handleFilterChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              name="endDate"
              label={t("End Date")}
              type="date"
              value={filters.endDate}
              onChange={handleFilterChange}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
        <div className={classes.filterButtons}>
          <Button variant="contained" color="primary" onClick={applyFilters}>
            {t("Apply Filters")}
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleDownload}
            style={{ marginLeft: "10px" }}
          >
            {t("Download CSV")}
          </Button>
        </div>
      </Paper>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t("ID")}</TableCell>
              <TableCell>{t("Patient")}</TableCell>
              <TableCell>{t("Payment Method")}</TableCell>
              <TableCell>{t("Amount")}</TableCell>
              <TableCell>{t("Date")}</TableCell>
              <TableCell>{t("Conciliation Status")}</TableCell>
              <TableCell>{t("Actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredPayments.map((payment) => (
              <TableRow key={payment.id}>
                <TableCell>{payment.id}</TableCell>
                <TableCell>{`${payment.patient.firstName} ${payment.patient.lastname}`}</TableCell>
                <TableCell>{payment.payment_method.name}</TableCell>
                <TableCell>{payment.amount}</TableCell>
                <TableCell>
                  {format(new Date(payment.created_at), "dd/MM/yyyy HH:mm")}
                </TableCell>
                <TableCell>{payment.concilation_status}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpenDialog(payment)}
                  >
                    {t("Manage")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle>{t("Manage Offline Payment")}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {selectedPayment && (
            <div>
              <div className={classes.paymentDetails}>
                <Typography variant="h6" gutterBottom>
                  {t("Payment Details")}
                </Typography>
                <div className={classes.paymentDetailItem}>
                  <Typography variant="body1">{t("ID")}:</Typography>
                  <Typography variant="body1">{selectedPayment.id}</Typography>
                </div>
                <div className={classes.paymentDetailItem}>
                  <Typography variant="body1">{t("Patient")}:</Typography>
                  <Typography variant="body1">
                    {`${selectedPayment.patient.firstName} ${selectedPayment.patient.lastname}`}
                  </Typography>
                </div>
                <div className={classes.paymentDetailItem}>
                  <Typography variant="body1">{t("Amount")}:</Typography>
                  <Typography variant="body1">
                    {selectedPayment.amount}
                  </Typography>
                </div>
                <div className={classes.paymentDetailItem}>
                  <Typography variant="body1">{t("Date")}:</Typography>
                  <Typography variant="body1">
                    {format(
                      parseISO(selectedPayment.created_at),
                      "dd/MM/yyyy HH:mm"
                    )}
                  </Typography>
                </div>
                <div className={classes.paymentDetailItem}>
                  <Typography variant="body1">
                    {t("Payment Method")}:
                  </Typography>
                  <Typography variant="body1">
                    {selectedPayment.payment_method.name}
                  </Typography>
                </div>
                <div className={classes.paymentDetailItem}>
                  <Typography variant="body1">{t("Reference")}:</Typography>
                  <Typography variant="body1">
                    {selectedPayment.reference || t("N/A")}
                  </Typography>
                </div>
                <div className={classes.paymentDetailItem}>
                  <Typography variant="body1">
                    {t("Appointment ID")}:
                  </Typography>
                  <Typography variant="body1">
                    {selectedPayment.appointment_id || t("N/A")}
                  </Typography>
                </div>
              </div>
              <FormControl className={classes.formField} fullWidth>
                <InputLabel>{t("Conciliation Status")}</InputLabel>
                <Select
                  value={selectedPayment.concilation_status}
                  onChange={(e) =>
                    setSelectedPayment({
                      ...selectedPayment,
                      concilation_status: e.target.value,
                    })
                  }
                  disabled={loading}
                >
                  <MenuItem value="in-review">{t("In Review")}</MenuItem>
                  <MenuItem value="payed">{t("Payed")}</MenuItem>
                  <MenuItem value="declined">{t("Declined")}</MenuItem>
                  <MenuItem value="invalid">{t("Invalid")}</MenuItem>
                </Select>
              </FormControl>
              <TextField
                className={classes.formField}
                label={t("Comments")}
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                value={selectedPayment.comments || ""}
                onChange={(e) =>
                  setSelectedPayment({
                    ...selectedPayment,
                    comments: e.target.value,
                  })
                }
              />
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCloseDialog} color="primary">
            {t("Cancel")}
          </Button>
          <Button
            onClick={handleUpdateConciliationStatus}
            color="primary"
            variant="contained"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : t("Update")}
          </Button>
        </DialogActions>
      </Dialog>
      {loading && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "20px 0",
          }}
        >
          <CircularProgress />
        </div>
      )}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
      >
        <Alert onClose={() => setError(null)} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </DashboardAdmin>
  );
}
