import React from "react";
import { TextField, Button } from "@material-ui/core";
import PaymentIcon from "@material-ui/icons/Payment";

export default function CheckoutGreenpay({
  cardNumber,
  setCardNumber,
  cardName,
  setCardName,
  cardExp,
  setCardExp,
  cardCvv,
  setCardCvv,
  handlePayment,
}) {
  return (
    <form>
      <TextField
        id="standard-full-width"
        variant="outlined"
        label="Nombre en la Tarjeta"
        style={{ margin: 8 }}
        placeholder="Nombre en la Tarjeta"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        value={cardName}
        onChange={(e) => setCardName(e.target.value)}
      />
      <TextField
        id="standard-full-width"
        variant="outlined"
        label="Numero de Tarjeta"
        style={{ margin: 8 }}
        placeholder="Ingrese Numero de Tarjeta"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ maxLength: 16 }}
        value={cardNumber}
        onChange={(e) => setCardNumber(e.target.value)}
      />
      <TextField
        id="standard-full-width"
        variant="outlined"
        label="Fecha de Expiracion"
        style={{ margin: 8 }}
        placeholder="Mes / Año"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        type="month"
        value={cardExp}
        onChange={(e) => setCardExp(e.target.value)}
      />
      <TextField
        id="standard-full-width"
        variant="outlined"
        label="CVV"
        style={{ margin: 8 }}
        placeholder="Ingrese CVV"
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ maxLength: 3 }}
        value={cardCvv}
        onChange={(e) => setCardCvv(e.target.value)}
      />
      <Button
        variant="contained"
        color="primary"
        fullWidth
        onClick={handlePayment}
        startIcon={<PaymentIcon />}
      >
        Pagar
      </Button>
    </form>
  );
}
