import React, { useState } from "react";
import axios from "axios";
import useUserState from "../../../hooks/useUserState";
import swal from "sweetalert";

export const PlanWizardLogic = ({ setOpen }) => {
  const localState = useUserState();
  const [plan, setPlan] = useState({
    name: "",
    description: "",
    price: "",
    billing_cycle: "",
    beneficiary_qty: "",
    status: "",
  });
  const [planSpecialties, setPlanSpecialties] = useState([]);
  const [providersPlan, setProvidersPlan] = useState([]);
  const [planList, setPlanList] = useState([]);
  const [planToEdit, setPlanToEdit] = useState({});

  const savePlan = async (plan) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/plans`,
        plan,
        {
          headers: {
            Authorization: `Bearer ${localState[0].jwt}`,
          },
        }
      );
      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  const getProviderList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/provider/enabled`,
        {
          headers: {
            Authorization: `Bearer ${localState[0].jwt}`,
          },
        }
      );
      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  const getSpecialtiesList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/specialty/findAll`,
        {
          headers: {
            Authorization: `Bearer ${localState[0].jwt}`,
          },
        }
      );
      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  const createPlan = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/plans`,
        { plan, providersPlan, planSpecialties },
        {
          headers: {
            Authorization: `Bearer ${localState[0].jwt}`,
          },
        }
      );
      return response.data;
    } catch (e) {
      swal(
        "Error",
        "Something went wrong: " + e.response?.data?.message,
        "error"
      );
      setOpen(false);
      console.log(e);
    }
  };

  const isCompletePlanInfoForm = () => {
    // if planToEdit is not empty, then we are editing a plan, so we don't need to validate the others states, just return true to continue, otherwise validate the other states
    if (planToEdit?.id) {
      return true;
    }

    // check if plan object in state is empty and return false if it is
    return (
      plan.beneficiary_qty !== "" &&
      plan.billing_cycle !== "" &&
      plan.description !== "" &&
      plan.name !== "" &&
      plan.price !== "" &&
      plan.status !== ""
    );
  };

  const getPlans = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/plans/getPlansByAdmin`,
        {
          headers: {
            Authorization: `Bearer ${localState[0].jwt}`,
          },
        }
      );
      setPlanList(response.data);
      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  // reestablish the planToEdit and other states to empty accordinly
  const resetPlanToEdit = () => {
    // refresh the screen
    window.location.reload();
  };

  // method to use getAssignationsByPlanId endpoint and set the info to the planToEdit state as a new property (object) called providersPlan
  const getProvidersAssignationsByPlanId = async (planRow) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/plans/provider/getAssignationsByPlanId?plan_id=${planRow.id}`,
        {
          headers: {
            Authorization: `Bearer ${localState[0].jwt}`,
          },
        }
      );
      setPlanToEdit({
        ...planRow,
        providersPlan: response?.data?.providers,
        planSpecialties: response?.data?.plan?.plan_specialty_types,
      });
      return response.data;
    } catch (e) {
      console.log(e);
    }
  };

  const updateProviderList = async (data) => {
    try {
      const dataToUpdate = {
        plan_id: planToEdit?.id,
        providers: data,
        beneficiary_qty: planToEdit?.beneficiary_qty,
        plan_status: planToEdit?.status,
        planName: planToEdit?.name,
        planDescription: planToEdit?.description,
        billing_cycle: planToEdit?.billing_cycle,
        price: planToEdit?.price,
        planSpecialties: planToEdit?.planSpecialties,
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/plans/provider/update/list`,
        dataToUpdate,
        {
          headers: {
            Authorization: `Bearer ${localState[0].jwt}`,
          },
        }
      );
      return response.data;
    } catch (e) {
      console.log(e);
      swal(
        "Error",
        "Something went wrong: " + e.response?.data?.message,
        "error"
      );
    }
  };

  return {
    savePlan,
    plan,
    setPlan,
    getProviderList,
    getSpecialtiesList,
    createPlan,
    planSpecialties,
    setPlanSpecialties,
    providersPlan,
    setProvidersPlan,
    isCompletePlanInfoForm,
    getPlans,
    planList,
    planToEdit,
    setPlanToEdit,
    resetPlanToEdit,
    getProvidersAssignationsByPlanId,
    updateProviderList,
  };
};
