import { Button } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import { FaCheckDouble, FaUserClock } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import ProfileLogo from "../../images/profile-2.svg";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function AppointmentHistory(props) {
  let history = useHistory();
  const { t } = useBDocTranslator();

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  //Function for Edit the selected Provider
  const handleClickEdit = () => {
    history.push("/admin/patient/edit/" + props.patientId);
  };

  // Appointments Status Collections and Styles to apply
  const APPT_STATUS_CODES = {
    NAPPT: "text-warning",
    CAPPT: "text-success",
    IPAPPT: "text-success",
    CDAPPT: "text-info",
    CCAPPT: "text-danger",
    RSAPPT: "text-warning",
  };

  return (
    <>
      <div className="row col-12 container-appt-summary">
        {/* Photo and Patient Data */}
        <div className="text-center col-md-4 col-sm-12">
          <img
            src={props.profileImage || ProfileLogo}
            alt="avatar"
            className="rounded-circle img-fluid w-50"
          />
          <div className="mt-2">
            <h5 className="pl-2 font-weight-bold">
              <span>{t("Mr(s). ")}</span>
              {props.patient_fullname || "Josua Zarcht"}
            </h5>
          </div>
        </div>
        {/* division */}
        <hr className="divider" />

        {/* Appointment Data */}
        <div className="w-100 col-md-7 col-sm-12">
          <div>
            <p className="m-3">
              <span className="text-label">{t("Appointment Type")}: </span>
              <span className="text-span-value">
                {props.event_type?.toUpperCase() || "CONSULTATION"}
              </span>
            </p>
            <p className="m-3">
              <span className="text-label">{t("Appointment Date")}: </span>{" "}
              <span className="text-span-value">
                {props.appt_date_start ||
                  new Date().toLocaleString("en-US", options)}
              </span>
            </p>
            <p className="m-3">
              <span className="text-label">{t("Appointment Status")}: </span>
              <span
                className={`font-weight-bold ${
                  props.appt_status_code
                    ? APPT_STATUS_CODES[props.appt_status_code]
                    : "text-warning"
                }`}
              >
                {t(props.appt_status_name)?.toUpperCase() || "N/A"}
              </span>
            </p>
            {/* Payment Status */}
            <p className="m-3">
              <span className="text-label">
                {t("Payment Status")}
                {":"}{" "}
              </span>
              <span className="text-span-value">
                {props.paymentStatus?.toUpperCase() === "COMPLETED" ? (
                  <FaCheckDouble className="paymentIcon-completed" />
                ) : (
                  <FaUserClock className="paymentIcon-await" />
                )}
              </span>
            </p>
          </div>
          <div className="row m-3">
            <Button
              //Feature under construction
              variant="contained"
              color="primary"
              size="medium"
              className="w-auto m-1"
              startIcon={<DescriptionIcon />}
              //className="w-30-custom"
              onClick={handleClickEdit}
            >
              {t("See Patient Detail")}
            </Button>
          </div>
        </div>
      </div>
      <hr className="divider-dashboard-appt" />
    </>
  );
}
