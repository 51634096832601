import React, { forwardRef, useState } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import TodayIcon from "@material-ui/icons/Today";
import useBDocTranslator from "../hooks/useBDocTranslator";
import ModalSendEmail from "./ModalSendEmail";
import SendIcon from "@material-ui/icons/Send";
import Axios from "axios";
import swal from "sweetalert";
import useUserState from "../hooks/useUserState";

const tableIcons = {
  AddToQueue: forwardRef((props, ref) => (
    <AddToQueueIcon {...props} ref={ref} />
  )),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  CalendarToday: forwardRef((props, ref) => <TodayIcon {...props} ref={ref} />),
};

function TableCieReport({ data }) {
  const { t } = useBDocTranslator();
  const [cieSelected, setCieSelected] = useState([]);
  const [isModalEmailOpen, setIsMoadlEmailOpen] = useState(false);
  const userLocalState = useUserState();

  const sendEmail = (msg) => {
    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/emails/send/cie`,
      {
        cies: cieSelected.map((c) => c.cie),
        msg,
      },
      {
        headers: { Authorization: "Bearer " + userLocalState[0]?.jwt },
      }
    )
      .then((response) => {
        setIsMoadlEmailOpen(false);
        swal({
          title: t("Success!"),
          text: t("Message Sent"),
          icon: "success",
          button: "OK",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: "CIE10", field: "cie", width: 20 },
          { title: t("Description"), field: "description", width: 500 },
          { title: t("Patients"), field: "patients", width: 100 },
        ]}
        data={data}
        title={t("Patients per CIE 10")}
        icons={tableIcons}
        options={{
          selection: true,
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF",
          },
          search: false,
        }}
        onSelectionChange={(rows) => setCieSelected(rows)}
        actions={[
          {
            icon: SendIcon,
            tooltip: "Send Email",
            onClick: (e, row) => setIsMoadlEmailOpen(true),
          },
        ]}
      />
      <ModalSendEmail
        close={() => setIsMoadlEmailOpen(false)}
        isOpen={isModalEmailOpen}
        title="Send Email To Patients With This CIE10"
        send={sendEmail}
      >
        <p>{cieSelected.map((c) => `${c.cie}`).join(" | ")}</p>
      </ModalSendEmail>
    </div>
  );
}

export default TableCieReport;
