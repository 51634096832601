import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import stethoscope from "../../images/folders.svg";
import useUserState from "../hooks/useUserState";
import Axios from "axios";
import HealthMeasurementDialog from "./HealthMeasurementDialog";
import useBDocTranslator from "../hooks/useBDocTranslator";
import EHRFilesDialog from "./EHRFilesDialog";
import Loading from "react-loading";
import useUploadEHRFiles from "../hooks/useUploadEHRFiles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "whitesmoke",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "900px",
    height: "400px",
  },
}));

export default function EHRFilesModalForm(props) {
  const classes = useStyles();
  const { t } = useBDocTranslator();

  const {
    responseMessage,
    handleUploadEHRFile,
    successFlag,
    setUploadedFiles,
    loading,
    uploadedFiles,
  } = useUploadEHRFiles(props.patientId, props.handleRefreshData);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          {
            <div className={classes.paper}>
              <div className="row col-md-12">
                <div className="col-md-4">
                  <img
                    src={stethoscope}
                    alt="rx icon"
                    className="w-30-custom"
                  />
                </div>
                <div className="col-md-8">
                  <h3>{t("Appointment Files")}</h3>
                </div>
                <div className="text-center">
                  {loading && (
                    <Loading
                      type="spin"
                      color="#387ADF"
                      height={50}
                      width={50}
                    />
                  )}
                </div>
                <div className="col-md-12">
                  <p
                    className={`font-weight-bold  ${
                      responseMessage.includes("Error")
                        ? "text-danger"
                        : "text-success"
                    } text-center`}
                  >
                    {t(responseMessage)}
                  </p>
                </div>
              </div>
              {/* Form */}
              <div className="row col-md-12 mt-3 mb-3">
                <EHRFilesDialog
                  handleUploadEHRFile={handleUploadEHRFile}
                  handleClose={props.handleClose}
                  successFlag={successFlag}
                  patientId={props.patientId}
                  uploadedFiles={uploadedFiles}
                  setUploadedFiles={setUploadedFiles}
                  loading={loading}
                />
              </div>
            </div>
          }
        </Fade>
      </Modal>
    </div>
  );
}
