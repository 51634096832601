import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import useBDocTranslator from "../hooks/useBDocTranslator";
import useUserState from "../hooks/useUserState";
import Axios from "axios";
import { MenuItem, TextField } from "@material-ui/core";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import SelectAppt from "../providers/SelectAppt";

export const prescTypes = [
  {
    label: "Pharmacy Prescription",
    value: "phar",
  },
  {
    label: "Diagnostic Imaging",
    value: "img",
  },
  {
    label: "Laboratory Tests",
    value: "lab",
  },
];

export const presTypeObjLabel = {
  phar: "Pharmacies",
  img: "Diagnostic imaging places",
  lab: "Laboratories",
};

export default function PrenscriptionDialog(props) {
  const { t, changeDirectLanguage } = useBDocTranslator();
  let btnStatus = props.enabledSaveBtn;
  const saveResponse = props.responseMessage;
  const [pharmacies, setPharmacies] = useState([]);
  const [prescType, setPrescType] = useState();
  const userLocalState = useUserState();

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/pharmacy/findAll`, {
      headers: { Authorization: "Bearer " + userLocalState[0].jwt },
    })
      .then((response) => {
        setPharmacies(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div
      style={{ height: "fit-content" }}
      className="shadow p-3 m-2 bg-white rounded-lg col-md-12 col-sm-12 position-relative prescription-card d-flex flex-column justify-content-between"
    >
      <SelectAppt
        onChange={(v) => props.setApptSelected(v)}
        value={props.apptSelected}
      />
      {/* Presciption types */}
      <TextField
        id="prescription-types"
        select
        fullWidth
        variant="outlined"
        margin="normal"
        placeholder={t("Select Prescription Type")}
        label={t("Prescription Type")}
        onChange={(e) => {
          setPrescType(e.target.value);
          props?.setPharmacy("select");
        }}
        value={prescType || t("select")}
      >
        <MenuItem value="select">{t("Select")}</MenuItem>
        {prescTypes.map((item, idx) => {
          return (
            <MenuItem key={idx} value={item.value}>
              {t(item.label)}
            </MenuItem>
          );
        })}
      </TextField>
      {/* Pharmacies */}
      {!!prescType && prescType !== "select" && (
        <>
          <TextField
            id="pharmacy-list"
            select
            fullWidth
            variant="outlined"
            margin="normal"
            label={prescType ? t(presTypeObjLabel[prescType]) : ""}
            onChange={(e) => props?.setPharmacy(e.target.value)}
            value={props?.pharmacy || t("select")}
          >
            <MenuItem value="select">{t("Select")}</MenuItem>
            {pharmacies?.length > 0 &&
              pharmacies
                .filter((i) => i.type === prescType)
                .map((item, idx) => {
                  return (
                    <MenuItem key={idx} value={item.id}>
                      {`${item.name} - ${item.city}`}
                    </MenuItem>
                  );
                })}
          </TextField>
          {/* Dispense To as a date selection */}
          <div className="form-group">
            <label htmlFor="dispense-to">{t("Valida hasta")}</label>
            <input
              type="date"
              className="form-control"
              value={props.dispenseTo}
              onChange={(e) => props.setDispenseTo(e.target.value)}
            />
          </div>
          <div className="pb-5">
            <ReactQuill
              theme="snow"
              value={props.prescriptionValue}
              onChange={props.handleNotes}
              style={{ height: "100px" }}
            />
          </div>
          <div className="mt-2">
            <Button
              variant="contained"
              color="primary"
              onClick={props.handleSave}
              className="col-md-6"
              disabled={
                !props.pharmacy || !props.dispenseTo || !props.prescriptionValue
              }
            >
              {t("Save")}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
