import React, { useEffect, useMemo } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import useBDocTranslator from "../hooks/useBDocTranslator";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useUserState from "../hooks/useUserState";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "whitesmoke",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "900px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: "20px",
  },
  info: {
    fontSize: "18px",
  },
}));

const ModalCompleteInfo = ({ open, close }) => {
  const classes = useStyles();
  const { t } = useBDocTranslator();
  let history = useHistory();
  const userLocalState = useUserState();

  useEffect(() => {
    if (!!open) {
      window.sessionStorage.setItem("showCompleteInfo", true);
    }
  }, [open]);

  const isImage = useMemo(() => {
    if (!userLocalState) return false;
    if (
      !!userLocalState[0]?.user.completeInfo &&
      userLocalState[0]?.user.completeInfo.includes("image")
    ) {
      return true;
    }
    return false;
  }, [userLocalState]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <p className={classes.title}>
            {t("You have to complete your user information")}
          </p>
          {isImage && <p className={classes.info}>{t("Add profile image")}</p>}
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/patient/editProfile")}
            className="col-md-6"
            size="small"
          >
            {t("Go To Edit Profile")}
          </Button>
        </div>
      </Fade>
    </Modal>
  );
};

export default ModalCompleteInfo;
