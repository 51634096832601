import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import CheckoutLogic from "./CheckoutLogic";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  form: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
}));

const PaymentFormModal = ({
  open,
  onClose,
  appointmentId,
  affiliationId,
  patientId,
}) => {
  const classes = useStyles();
  const [idType, setIdType] = useState(
    appointmentId ? "appointment" : "affiliation"
  );

  const { reportOfflinePayment } = CheckoutLogic();

  const validationSchema = Yup.object({
    payment_method_id: Yup.string().required("Método de pago es requerido"),
    appointment_id: Yup.string().when("idType", {
      is: "appointment",
      then: Yup.string().required("ID de cita es requerido"),
    }),
    reference: Yup.string().required("Referencia es requerida"),
    origin_reference: Yup.string(),
    comments: Yup.string(),
    amount: Yup.number()
      .positive("El monto debe ser positivo")
      .required("Monto es requerido"),
  });

  const formik = useFormik({
    initialValues: {
      patient_id: patientId,
      payment_method_id: "",
      appointment_id: appointmentId || "",
      affiliation_id: null,
      reference: "",
      origin_reference: "",
      comments: "",
      amount: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      // Aquí puedes manejar el envío del formulario
      console.log(values);
      // Enviar submitValues a tu endpoint
      try {
        const response = await reportOfflinePayment(values);
        console.log(response);
        if (response.status === 201) {
          swal(
            "Exito",
            "Pago reportado correctamente, en las proximas horas sera verificado.",
            "success"
          );
        }
      } catch (error) {
        swal("Error", "Error al enviar el pago", "error");
      }
      onClose(); // Cerrar el modal después de enviar
    },
  });

  useEffect(() => {
    if (appointmentId) {
      setIdType("appointment");
      formik.setFieldValue("appointment_id", appointmentId);
      formik.setFieldValue("affiliation_id", "");
    } else if (affiliationId) {
      setIdType("affiliation");
      formik.setFieldValue("affiliation_id", affiliationId);
      formik.setFieldValue("appointment_id", "");
    }
  }, [appointmentId, affiliationId]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">Reportar Pago</DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextField
            select
            fullWidth
            id="payment_method_id"
            name="payment_method_id"
            label="Método de Pago"
            value={formik.values.payment_method_id}
            onChange={formik.handleChange}
            error={
              formik.touched.payment_method_id &&
              Boolean(formik.errors.payment_method_id)
            }
            helperText={
              formik.touched.payment_method_id &&
              formik.errors.payment_method_id
            }
          >
            <MenuItem value="4">Sinpe Movil</MenuItem>
            <MenuItem value="5">Kash App</MenuItem>
          </TextField>

          {idType === "appointment" ? (
            <TextField
              fullWidth
              id="appointment_id"
              name="appointment_id"
              label="ID de Cita"
              value={formik.values.appointment_id}
              onChange={formik.handleChange}
              error={
                formik.touched.appointment_id &&
                Boolean(formik.errors.appointment_id)
              }
              helperText={
                formik.touched.appointment_id && formik.errors.appointment_id
              }
              disabled={!!appointmentId}
            />
          ) : (
            <TextField
              fullWidth
              id="affiliation_id"
              name="affiliation_id"
              label="ID de Afiliación"
              value={formik.values.affiliation_id}
              onChange={formik.handleChange}
              error={
                formik.touched.affiliation_id &&
                Boolean(formik.errors.affiliation_id)
              }
              helperText={
                formik.touched.affiliation_id && formik.errors.affiliation_id
              }
              disabled={!!affiliationId}
            />
          )}

          <TextField
            fullWidth
            id="reference"
            name="reference"
            label="Referencia del Pago"
            value={formik.values.reference}
            onChange={formik.handleChange}
            error={formik.touched.reference && Boolean(formik.errors.reference)}
            helperText={formik.touched.reference && formik.errors.reference}
          />

          <TextField
            fullWidth
            id="origin_reference"
            name="origin_reference"
            label="Numero de Sinpe Movil / Kash App"
            value={formik.values.origin_reference}
            onChange={formik.handleChange}
          />

          <TextField
            fullWidth
            id="comments"
            name="comments"
            label="Comentarios"
            multiline
            rows={4}
            value={formik.values.comments}
            onChange={formik.handleChange}
          />

          <TextField
            fullWidth
            id="amount"
            name="amount"
            label="Monto"
            type="number"
            value={formik.values.amount}
            onChange={formik.handleChange}
            error={formik.touched.amount && Boolean(formik.errors.amount)}
            helperText={formik.touched.amount && formik.errors.amount}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancelar
        </Button>
        <Button onClick={formik.handleSubmit} color="primary">
          Enviar Pago
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentFormModal;
