import React from "react";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "1em",
    cursor: "pointer",
  },
}));

const BackToReportsList = () => {
  const history = useHistory();
  const classes = useStyles();
  return (
    <ArrowBackIcon
      className={classes.button}
      onClick={() => history.push("/users/report/list")}
    />
  );
};

export default BackToReportsList;
