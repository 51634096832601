import React, { useState, useEffect } from "react";
import DashboardMUI from "../dashboard/DashboardMui";
import FullCalendar, { flexibleCompare, padStart } from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import CalendarIcon from "../../images/appointment-icon.svg";
import Button from "@material-ui/core/Button";
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Axios from "axios";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import userLocalState from "../hooks/useUserState";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { startOfWeek, format, addDays, addHours } from "date-fns";
import { es } from "date-fns/locale";
import useBDocTranslator from "../hooks/useBDocTranslator";
import PlannerMenuTab from "./PlannerMenuTab";
import PlannerCalendar from "./PlannerCalendar";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Planner() {
  const [slots, setSlots] = useState([]);
  let history = useHistory();
  const localState = userLocalState();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useBDocTranslator();
  const [calendarSelection, setCalendarSelection] = useState(1);
  // location data
  const locationsAssigned = localState[0]?.extendedData?.provider_locations;

  //this method manage the backdrop status
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log(slots);
    fetchSchedule();

    if (slots.length > 0) {
      handleSave();
    }
  }, [slots]);

  const getDayName = (date) => {
    return format(date, "EEE", { locale: es });
  };

  //Method for fetch All the scheduled form the current provider
  const fetchSchedule = () => {
    setOpen(true);
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/sheduleProvider/findByProviderId?providerId=${localState[0].extendedData.id}`,
      {
        headers: { Authorization: "Bearer " + localState[0].jwt },
      }
    )
      .then((response) => {
        console.log(response);
        if (response?.data?.length > 0) {
          const eventParsed = parseEvents(response.data);

          setEvents(eventParsed);
          setOpen(false);
        }
      })
      .catch((error) => {
        setOpen(false);
        console.log(error);
      });
  };

  //Handle Back to the previusly screen on the Stack
  const handleCancel = () => {
    history.goBack();
  };

  //Handle save when the button save is active
  const handleSave = () => {
    setOpen(true);
    const params = prepareParams();
    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/sheduleProvider/create`,
      params[0], //when saving on demand for each selection
      {
        headers: { Authorization: "Bearer " + localState[0].jwt },
      }
    )
      .then((res) => {
        setOpen(false);
        fetchSchedule();
        // swal("sucess", res.data.message, "success");
        enqueueSnackbar("Slot time Saved!", {
          variant: "success",
        });
      })
      .catch((err) => console.log({ errorMessages: err }));
  };

  const prepareParams = () => {
    const resultArr = [];
    if (slots.length > 0) {
      slots.forEach((item) => {
        //Setting the provider id on each obj
        item.formattedTimes.provider_id = localState[0].extendedData.id;
        item.formattedTimes.practice_location_id = calendarSelection;
        resultArr.push(item.formattedTimes);
      });
      return resultArr;
    }
  };

  //Method transform the object received to a format that the calendar can render in corresponding days
  const parseEvents = (objToParse) => {
    let result = [];
    if (objToParse) {
      objToParse.forEach((item) => {
        let dayStart = retreiveDate(item.week_day);
        let start = new Date(dayStart);

        start.setHours(
          item.time_start.split(":")[0],
          item.time_start.split(":")[1]
        );

        let dayEnd = retreiveDate(item.week_day);
        let end = new Date(dayEnd);

        end.setHours(item.time_end.split(":")[0], item.time_end.split(":")[1]);

        const obj = {};
        obj.id = item.id;
        obj.start = start;
        obj.end = end;
        obj.backgroundColor =
          item?.practice_location_id === 1 ? "#257CEA" : "#12A615";
        obj.practice_location_id = item.practice_location_id;

        result.push(obj);
      });
      return result;
    }
  };

  //Method that asign the weekday in the current week by the num of day (from 0 to 6)
  const retreiveDate = (compareTo) => {
    const today = new Date();

    const firstDayWeek = startOfWeek(today, {
      weekStartsOn: 0,
      locale: "en-US",
    });
    for (let i = 0; i <= 6; i++) {
      const newDate = addDays(new Date(firstDayWeek), i);
      if (compareTo === i) {
        let dateResult = format(newDate, "MM-dd-yyyy");
        return dateResult;
      }
    }
  };

  const handleUnSelect = (id) => {
    if (id) {
      swal({
        title: t("Are you sure you want to delete this Slot Selected?"),
        text: t(
          "remember that you can select the availabilty block that you want"
        ),
        icon: "warning",
        buttons: true,
        dangerMode: false,
      }).then((ok) => {
        if (ok) {
          setOpen(true);
          Axios.delete(
            `${process.env.REACT_APP_API_URL_BASE}/sheduleProvider/delete?id=${id}`,
            {
              headers: { Authorization: "Bearer " + localState[0].jwt },
            }
          )
            .then((res) => {
              console.log(res);
              setOpen(false);
              fetchSchedule();
              // swal("sucess", res.data.message, "success");
              enqueueSnackbar(t("Slot time deleted!"), {
                variant: "success",
              });
              // fetchSchedule();
            })
            .catch((err) => console.log({ errorMessages: err }));
        }
      });
    }
  };

  // filter the events only for the one that contain the practice location id= 2
  const filterEvents = (events, locationId) => {
    return events.filter((item) => item.practice_location_id === locationId);
  };

  return (
    <DashboardMUI
      sectionTitle={t("Calendar Availability")}
      icon={CalendarIcon}
      description={t(
        "Here you can set your availability for Bookings, your patients will be able to book appointments on selected dates ranges"
      )}
    >
      {/* Element that block the screen when request its happen */}
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="row bg-white p-2">
        <div className="col-md-12">
          <PlannerMenuTab
            calendarSelection={calendarSelection}
            setCalendarSelection={setCalendarSelection}
            calendarsAvailable={locationsAssigned}
          />
        </div>
        <>
          {
            <PlannerCalendar
              handleUnSelect={handleUnSelect}
              getDayName={getDayName}
              setSlots={setSlots}
              events={filterEvents(events, calendarSelection)}
            />
          }
          {/* {calendarSelection === 0 ? (
            <PlannerCalendar
              handleUnSelect={handleUnSelect}
              getDayName={getDayName}
              setSlots={setSlots}
              events={filterEvents(events, 1)}
            />
          ) : (
            <PlannerCalendar
              handleUnSelect={handleUnSelect}
              getDayName={getDayName}
              setSlots={setSlots}
              events={filterEvents(events, 2)}
            />
          )} */}
        </>

        <div className="col-md-4">
          <div>
            <h5 className="font-weight-bold pl-1">{t("Instructions")}</h5>
            <div>
              <p className="text-justify text-instructions">
                {t(
                  "Please select your DEFAULT preferred time slots for booking availability, you can select the different slots of hours per day and can change it in the future for more flexibility. If you have any suggestions feel free to contact Tenant Administrator or Support."
                )}
              </p>
            </div>
          </div>
          {/* Buttons Section */}
          <div className="col d-flex justify-content-center">
            <div className="p-3">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={handleSave}
                disabled
              >
                {t("Save")}
              </Button>
            </div>

            <div className="p-3">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </DashboardMUI>
  );
}
