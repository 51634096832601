import React, { forwardRef, useMemo } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import HealthImage from "../../images/healthcondition.svg";
import useBDocTranslator from "../hooks/useBDocTranslator";
import EditIcon from "@material-ui/icons/Edit";
import {
  measurementPrompt,
  measurementTypes,
} from "../../const/healthMeasurement";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { makeStyles } from "@material-ui/core";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  EcgActions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1em",
  },
  actionIcon: {
    cursor: "pointer",
  },
}));

function Healthmeasurement(props) {
  const { t } = useBDocTranslator();
  const classes = useStyles();

  const dataFormated = useMemo(() => {
    if (!props.data) return [];
    return props.data
      .map((i) => {
        const mt = t(
          measurementTypes.find((mt) => mt.value === i.measurement_type)?.label
        );
        if (i.measurement_type === "ECG") {
          return {
            ...i,
            measurement_type: mt,
            result: (
              <span>
                {i.result.split("/*/")?.length
                  ? i.result.split("/*/")[0]
                  : "None"}
              </span>
            ),
            actions: i.result.split("/*/")?.length ? (
              <div className={classes.EcgActions}>
                <CloudDownloadIcon
                  className={classes.actionIcon}
                  onClick={() => {
                    window.open(i.result.split("/*/")[1].trim(), "_blank");
                  }}
                />
                {props.type === "pro" && (
                  <EditIcon
                    className={classes.actionIcon}
                    onClick={() => {
                      if (props.openEdit) props.openEdit(i);
                    }}
                  />
                )}
              </div>
            ) : (
              ""
            ),
          };
        }
        return {
          ...i,
          measurement_type: mt,
          result: measurementPrompt[i.measurement_type]
            ? `${i.result} ${measurementPrompt[i.measurement_type]}`
            : i.result,
        };
      })
      ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
  }, [props.data]);

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: "Id", field: "id" },
          { title: t("Measurement"), field: "measurement_type" },
          { title: t("Result"), field: "result" },
          { title: t("Register date"), field: "created_at", type: "datetime" },
          { title: t("Actions"), field: "actions" },
        ]}
        // data={props.data}
        data={dataFormated}
        title={t("Health Monitor Results")}
        icons={tableIcons}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF",
          },
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <span>
                <img style={{ width: "15%" }} src={HealthImage} alt="" />
              </span>
            ),
          },
        }}
      />
    </div>
  );
}

export default Healthmeasurement;
