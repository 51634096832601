import React, { useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { format } from "date-fns";
import useBDocTranslator from "../hooks/useBDocTranslator";
import useUserState from "../hooks/useUserState";
import Axios from "axios";

const SelectAppt = ({ onChange = () => {}, value, patientId }) => {
  const { t } = useBDocTranslator();
  const userLocalState = useUserState();
  const [appts, setAppts] = useState();

  useEffect(() => {
    if (!userLocalState) return;
    const params = {
      provider_id: userLocalState[0].extendedData.id,
    };
    if (patientId) {
      params.patient_id = patientId;
    }
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/appointments/select`, {
      headers: {
        Authorization: "Bearer " + userLocalState[0].jwt,
      },
      params,
    })
      .then((res) => {
        // setLoading(false);
        setAppts(res.data);
        // getEvents(res.data);
      })
      .catch((err) => {
        // setLoading(false);
        console.log(err);
      });
  }, []);

  return (
    <FormControl
      variant="outlined"
      // className={classes.form}
      style={{ width: "100%", margin: "0 0 1em 0" }}
    >
      <InputLabel required id="appt">
        {t("Appointment")}
      </InputLabel>
      <Select
        labelId="appt"
        id="appt"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label={t("Appointment")}
      >
        <MenuItem value="none">{t("None")}</MenuItem>
        {appts?.map((a) => (
          <MenuItem value={a}>{`${a.event_type} ${format(
            new Date(a.appt_date_start),
            "yyyy-MM-dd"
          )} ${a.patient.firstName} ${a.patient.lastname}`}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectAppt;
