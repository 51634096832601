import React, { useEffect, useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useBDocTranslator from "../../../hooks/useBDocTranslator";
import { Button, makeStyles } from "@material-ui/core";
import {
  addDays,
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  format,
  lastDayOfMonth,
} from "date-fns";
import Axios from "axios";
import useUserState from "../../../hooks/useUserState";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { CSVLink } from "react-csv";
import DashboardAdmin from "../../DashboardAdmin";
import BackToReportsList from "../BackToReportsList";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    minWidth: "600px",
    background: "#fff",
    padding: "2em",
  },
  graph: {
    height: "400px",
  },
  filterWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "1em",
    justifyContent: "space-between",
  },
  dates: {
    display: "flex",
    alignItems: "center",
    gap: "1em",
  },
  title: {
    fontSize: "25px",
    width: "100%",
    textAlign: "left",
    margin: "10px 0 10px 0",
  },
  extraInfo: {
    width: "100%",
    textAlign: "left",
    display: "flex",
    gap: "1em",
    alignItems: "center",
  },
  filterButton: {
    width: "fit-content",
  },
  finalActions: {
    display: "flex",
    width: "100%",
    justifyContent: "end",
    gap: "1em",
    alignItems: "center",
  },
}));

const PatientsReportPlans = () => {
  const { t } = useBDocTranslator();
  const initDates = {
    start: format(new Date(), "yyyy-MM-01"),
    end: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
  };
  const [dates, setDates] = useState(initDates);
  const userLocalState = useUserState();
  const [graphData, setGraphData] = useState(null);
  const classes = useStyles();

  const getData = async () => {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/reports/admin/order/payment/graph?start=${dates.start}&end=${dates.end}`,
      {
        headers: { Authorization: "Bearer " + userLocalState[0]?.jwt },
      }
    )
      .then((response) => {
        setGraphData(response.data);
      })
      .catch((error) => {
        throw error;
      });

    // Axios.get(
    //   `${process.env.REACT_APP_API_URL_BASE}/reports/admin/order/payment/table?start=${dates.start}&end=${dates.end}`,
    //   {
    //     headers: { Authorization: "Bearer " + userLocalState[0]?.jwt },
    //   }
    // )
    //   .then((response) => {
    //     setGraphData(response.data);
    //   })
    //   .catch((error) => {
    //     throw error;
    //   });
  };
  useEffect(() => {
    getData();
  }, []);

  const handleDateChange = (key, date) => {
    setDates((p) => ({ ...p, [key]: format(date, "yyyy-MM-dd") }));
  };

  const dataToCsv = useMemo(() => {
    const res = [["fecha", "precio total", "cantidad de citas"]];
    if (!graphData) return res;
    for (let v of graphData) {
      res.push([v.date, v.precio_total, v.cantidad_citas]);
    }
    return res;
  }, [graphData]);

  const averageData = useMemo(() => {
    const total = graphData?.reduce(function (prev, cur) {
      return prev + cur.precio_total;
    }, 0);
    const totalAppt = graphData?.reduce(function (prev, cur) {
      return prev + cur.cantidad_citas;
    }, 0);
    const res = [
      {
        label: "Precio Total",
        diario: 0,
        semanal: 0,
        mensual: 0,
        total,
      },
      {
        label: "Cantidad de citas",
        diario: 0,
        semanal: 0,
        mensual: 0,
        total: totalAppt,
      },
    ];
    const days = differenceInDays(new Date(dates.end), new Date(dates.start));
    const weeks = differenceInWeeks(new Date(dates.end), new Date(dates.start));
    const months = differenceInMonths(
      new Date(dates.end),
      new Date(dates.start)
    );

    for (let i in res) {
      const v = res[i];
      res[i].diario = (v.total / days).toFixed(2);

      if (weeks >= 2) {
        res[i].semanal = (v.total / weeks).toFixed(2);
      }
      if (months >= 2) {
        res[i].mensual = (v.total / months).toFixed(2);
      }
      if (months === 1) {
        res[i].semanal = (v.total / weeks).toFixed(2);
      }
    }

    return res;
  }, [graphData]);

  return (
    <DashboardAdmin>
      <div className={classes.wrapper}>
        <BackToReportsList />
        <p className={classes.title}>{t("Patients per time period")}</p>
        <div className={classes.filterWrapper}>
          <div className={classes.dates}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline-start"
                label={t("Date From")}
                value={addDays(new Date(dates.start), 1)}
                onChange={(d) => handleDateChange("start", d)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline-end"
                label={t("Date To")}
                value={addDays(new Date(dates.end), 1)}
                onChange={(d) => handleDateChange("end", d)}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={getData}
            className={classes.filterButton}
          >
            {t("Apply filter")}
          </Button>
        </div>
        <div className={classes.graph}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={graphData}
              margin={{
                top: 5,
                right: 15,
                left: 10,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="precio_total" fill="#8884d8" />
              <Bar dataKey="cantidad_citas" fill="#10cfc9" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className={classes.finalActions}>
          <CSVLink data={dataToCsv}>{t("Download to CSV")}</CSVLink>
        </div>
        {averageData.map((o) => {
          return (
            <div className={classes.extraInfo}>
              {Object.keys(o).map((k) => {
                const v = o[k];
                if (!v) return "";
                if (k === "label") return <p>{`${v}: `}</p>;
                return <p>{`${k}: ${v}`}</p>;
              })}
            </div>
          );
        })}
        {/* <TableOrdersReport data={ciePatients || []} /> */}
      </div>
    </DashboardAdmin>
  );
};

export default PatientsReportPlans;
