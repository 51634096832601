import React from "react";
import DashboardAdmin from "../../DashboardAdmin";
import AppointmentsPerTime from "../../../providers/reports/AppointmentsPerTime";
import BackToReportsList from "../BackToReportsList";

const PatientsReport = () => {
  return (
    <DashboardAdmin>
      <BackToReportsList />
      <AppointmentsPerTime />
    </DashboardAdmin>
  );
};

export default PatientsReport;
