import React from "react";
import axios from "axios";
import { PAYMENT_OBJECT } from "../utils/PaymentObject";
import useUserState from "../hooks/useUserState";
import swal from "sweetalert";

export default function CheckoutLogic() {
  const userState = useUserState();

  const handlePayment = async (payData) => {
    try {
      const stateDNI = userState[0]?.extendedData?.identification;

      if (!stateDNI) {
        swal(
          "Error",
          "Por favor complete los datos de su perfil para realizar pagos - Identificacion Invalida",
          "error"
        );
        return;
      }

      // replace in the payment object the required fields with the user input
      const PAYMENT_OBJ = {
        ...PAYMENT_OBJECT,
        paymentData: {
          card: {
            cardHolder: payData.cardName,
            expirationDate: {
              month: parseInt(payData.cardExp.split("-")[1], 10),
              year: parseInt(payData.cardExp.split("-")[0].slice(-2)),
            },
            cardNumber: payData.cardNumber,
            cvc: payData.cardCvv,
            nickname:
              checkCardType(payData.cardNumber) + payData.cardNumber.slice(-4),
          },
        },
        orderRequestData: {
          ...PAYMENT_OBJECT.orderRequestData,
          orderReference: payData.appointmentId.toString(),
          amount: payData.price,
          description: `PAGO-DE-${payData.service_name}`,
          additional: {
            ...PAYMENT_OBJECT.orderRequestData.additional,
            customer: {
              ...PAYMENT_OBJECT.orderRequestData.additional.customer,
              name:
                userState[0]?.extendedData?.firstName +
                " " +
                userState[0]?.extendedData?.lastname,
              email: userState[0].user?.email,
              identification: stateDNI,
            },
            products: [
              {
                ...PAYMENT_OBJECT.orderRequestData.additional.products[0],
                description: payData.service_name,
                skuId: `SKU-${payData.service_name}`,
                price: payData.price,
              },
            ],
          },
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/payments/process-payment`,
        PAYMENT_OBJ,
        {
          headers: {
            Authorization: `Bearer ${userState[0].jwt}`,
          },
          timeout: 30000,
        }
      );

      if (response.status === 200) {
        swal(
          "Pago exitoso",
          "El pago se ha procesado correctamente",
          "success"
        );
      }
      return response.data;
    } catch (error) {
      console.error(error);
      swal("Error", "Error al procesar el pago", "error");
    }
  };

  // Function to check the card bin and get the card type
  const checkCardType = (cardNumber) => {
    // Visa
    let re = new RegExp("^4");
    if (cardNumber.match(re) != null) return "visa";

    // Mastercard
    re = new RegExp("^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)");
    if (cardNumber.match(re) != null) return "mastercard";

    // AMEX
    re = new RegExp("^3[47]");
    if (cardNumber.match(re) != null) return "amex";

    // Discover
    re = new RegExp(
      "^(6011|65|64[4-9]|62212[6-9]|6221[3-9]|622[2-8]|6229[01]|62292[0-5])"
    );
    if (cardNumber.match(re) != null) return "discover";

    // Diners
    re = new RegExp("^3(?:0[0-5]|[68][0-9])[0-9]{11}$");
    if (cardNumber.match(re) != null) return "diners";

    // JCB
    re = new RegExp("^(?:2131|1800|35\\d{3})\\d{11}$");
    if (cardNumber.match(re) != null) return "jcb";

    return "unknown";
  };

  const handleSubcription = async (payData) => {
    // const redirectionURL = "https://sandbox-checkoutform.greenpay.me/";
    const redirectionURL = "https://checkout-form.greenpay.me/";
    try {
      // replace in the payment object the required fields with the user input
      const PAYMENT_OBJ = {
        ...PAYMENT_OBJECT.orderRequestData,
        orderReference: payData.affiliationId.toString(),
        amount: payData.price,
        description: `PAGO-DE-${payData.planName}`,
        callback: "https://hcgconsulting.net/patient/checkout/gp/result/",
        additional: {
          ...PAYMENT_OBJECT.orderRequestData.additional,
          customer: {
            ...PAYMENT_OBJECT.orderRequestData.additional.customer,
            name:
              userState[0]?.extendedData?.firstName +
              " " +
              userState[0]?.extendedData?.lastname,
            email: userState[0].user?.email,
            identification: userState[0]?.extendedData?.identification,
          },
          products: [
            {
              ...PAYMENT_OBJECT.orderRequestData.additional.products[0],
              description: payData.planName,
              skuId: `SKU-${payData.planName}`,
              price: payData.price,
            },
          ],
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/payments/process-membership`,
        PAYMENT_OBJ,
        {
          headers: {
            Authorization: `Bearer ${userState[0].jwt}`,
          },
          timeout: 30000,
        }
      );

      const session = response?.data?.data?.session;
      return redirectionURL + session;
    } catch (error) {
      console.error(error);
      swal("Error", "Error al procesar el pago", "error");
    }
  };

  const handleSubcriptionCallback = async (verificationData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/payments/check-membership`,
        verificationData,
        {
          headers: {
            Authorization: `Bearer ${userState[0].jwt}`,
          },
          timeout: 30000,
        }
      );

      if (response.status === 200) {
        swal(
          "Pago verificado exitosamente",
          "El pago se ha procesado correctamente",
          "success"
        );
      }
      return response;
    } catch (error) {
      console.error(error);
      swal(
        "Error",
        "Error al Verificar el pago, contacte con Soporte",
        "error"
      );
    }
  };

  const reportOfflinePayment = async (paymentInfo) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/offlinePayments/save`,
        paymentInfo,
        {
          headers: {
            Authorization: `Bearer ${userState[0].jwt}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.error(error);
      swal("Error", "Error al reportar el pago, contacte con Soporte", "error");
    }
  };

  return {
    handlePayment,
    handleSubcription,
    handleSubcriptionCallback,
    reportOfflinePayment,
  };
}
