import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useState } from "react";
import useUserState from "../hooks/useUserState";
import useDebounce from "../hooks/useDebounce";
import Axios from "axios";

const SelectCIE = ({ setCie, label, style, multiple = false }) => {
  const userLocalState = useUserState();
  const [cieSearch, setCieSearch] = useState("");
  const [cieOptions, setCieOptions] = useState();
  const [cieInner, setCieInner] = useState([]);

  const cieSearchDebounced = useDebounce(cieSearch, 500);

  const getCie = async () => {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/cie/get${
        cieSearch ? `?search=${cieSearch}` : ""
      }`,
      {
        headers: { Authorization: "Bearer " + userLocalState[0]?.jwt },
      }
    )
      .then((response) => {
        setCieOptions(response.data);
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    getCie();
  }, [cieSearchDebounced]);

  return (
    <Autocomplete
      id="cie"
      onChange={(e, newValue) => {
        setCie(newValue);
        setCieInner(newValue || []);
      }}
      multiple={multiple}
      options={cieOptions || []}
      style={style}
      getOptionLabel={(option) => `${option.code} - ${option.description}`}
      renderInput={(params) => (
        <TextField
          {...params}
          onChange={(e) => setCieSearch(e.target.value)}
          label={label}
          variant="outlined"
        />
      )}
    />
  );
};

export default SelectCIE;
