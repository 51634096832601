import React, { useEffect, useState } from "react";
import Axios from "axios";
import { Button } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import EditIcon from "@material-ui/icons/Edit";
import { useHistory } from "react-router-dom";
import ProfileLogo from "../../images/profile-2.svg";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import EventAvailableIcon from "@material-ui/icons/EventAvailable";
import EventBusyIcon from "@material-ui/icons/EventBusy";
import swal from "sweetalert";
import { FaCheckDouble, FaUserClock } from "react-icons/fa";
import TextField from "@material-ui/core/TextField";
import UseUserState from "../hooks/useUserState";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { resizeByLink } from "./../utils/ResizeLinkCloudinary";
import LaunchIcon from "@material-ui/icons/Launch";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import Alert from "@material-ui/lab/Alert";
import useBDocTranslator from "../hooks/useBDocTranslator";
import OfflinePinIcon from "@material-ui/icons/OfflinePin";
import Chip from "@material-ui/core/Chip";

export default function AppointmentSummary(props) {
  const userLocalState = UseUserState();
  const [loading, setLoading] = useState(false);
  const [newPrice, setNewPrice] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);
  const { t, changeDirectLanguage } = useBDocTranslator();

  let history = useHistory();
  const [disableButton, setDisableButton] = useState(true);
  const [disableStartButton, setDisableStartButton] = useState(true);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  const [open, setOpen] = useState(false);

  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "whitesmoke",
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "800px",
    },
  }));
  const classes = useStyles();

  // Appointments Status Collections and Styles to apply
  const APPT_STATUS_CODES = {
    NAPPT: "text-warning",
    CAPPT: "text-success",
    IPAPPT: "text-success",
    CDAPPT: "text-info",
    CCAPPT: "text-danger",
    RSAPPT: "text-warning",
  };

  /* Function to manage the conection to the Online consultation */
  const handleJoin = () => {
    const { event_code, providerFullname, password, appointmentId, patientId } =
      props;
    history.push({
      pathname: "/admin/provider/lobby",
      state: {
        event_code,
        providerFullname,
        password,
        appointmentId,
        patientId,
      },
    });
  };

  /* Function to manage the button for Join on the Consultation verifing the dates */
  const handleDisable = () => {
    const { appt_date_start, appt_date_end } = props;
    const start_date = Date.parse(appt_date_start);
    // const end_date = Date.parse(appt_date_end);
    const rigthNow = Date.now();
    //const rangeToStart = 300000; // 5 minutes en milliseconds
    if (appt_date_start && appt_date_end) {
      // const timetoStart = start_date - rigthNow;
      return !(rigthNow < start_date && props.appt_status_code === "CAPPT");
    }
  };

  useEffect(() => {
    changeDirectLanguage();
    setDisableButton(handleDisable);
    setDisableStartButton(props.appt_status_code.toUpperCase() !== "CAPPT");
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeStatus = (newStatus) => {
    if (newStatus === props.appt_status_code)
      return swal(
        t("Warning"),
        t(
          "You cannot set the same Status, please contact support for more details"
        ),
        "warning"
      );
    if (props.appointmentId) {
      setLoading(true);

      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/appointments/updateStatus`,
        {
          id: props.appointmentId,
          appt_status_code: newStatus,
          providerName: props.patient_fullname,
        },
        {
          headers: {
            Authorization: "Bearer " + userLocalState[0].jwt,
          },
        }
      )
        .then((res) => {
          setLoading(false);
          swal(t("Success!"), t("Appointment status Changed"), "success");
        })
        .catch((err) => {
          setLoading(false);
          swal(
            "Error!",
            t("Something went wrong, please contact the Admin ") + err,
            "error"
          );
          console.log(err);
        });
    }
  };

  const handleUpdatePrice = () => {
    if (newPrice && props.appointmentId) {
      setLoading(true);
      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/services/updatePrice`,
        {
          appointment_id: props.appointmentId,
          price_total: newPrice,
          orderId: props.orderId,
        },
        {
          headers: {
            Authorization: "Bearer " + userLocalState[0].jwt,
          },
        }
      )
        .then((res) => {
          setLoading(false);
          setBtnStatus(!btnStatus);
          swal(
            t("Success!"),
            t("Price For this Appointment was Changed"),
            "success"
          );
        })
        .catch((err) => {
          setLoading(false);
          swal(
            "Error!",
            t("Something went wrong, please contact the Admin ") + err,
            "error"
          );
          console.log(err);
        });
    } else {
      swal("Error!", t("Please type New price"), "error");
    }
  };

  // Method for On Site or Clinic consultation
  const handleStarConsultation = () => {
    history.push({
      pathname: "/admin/patient/edit/" + props.patientId,
      state: { appointmentId: props.appointmentId },
    });
  };

  // Method for sending payment reminder
  const handleReminder = () => {
    if (props.paymentStatus.toUpperCase() !== "COMPLETED") {
      Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/payments/requestPay`,
        {
          appointment_id: props.appointmentId,
          orderId: props.orderId,
        },
        {
          headers: { Authorization: "Bearer " + userLocalState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          swal(t("Success!"), t("Reminder Sent to the Patient"), "success");
        })
        .catch((error) => {
          console.log(error);
          swal(
            "Error!",
            t(
              "Something's Wrong Retrieving the Appointment details, please contact Support"
            ),
            "warning"
          );
        });
    } else {
      swal(
        "Error!",
        t("Appointment was payed by the Patient, cannot send an reminder"),
        "warning"
      );
    }
  };

  return (
    <>
      <div className="row col-12 container-appt-summary">
        {/* Photo and Patient Data */}
        <div className="text-center col-md-4 col-sm-12">
          <img
            src={props?.profileImage || ProfileLogo}
            alt="avatar"
            className="rounded-circle img-fluid w-50"
          />
          <div className="mt-2">
            <h4 className="pl-2 font-weight-bold">
              <span>{"Mr(s). "}</span>
              {props?.patient_fullname || "Undefined Name"}
            </h4>
          </div>
          {/*  Affiliation badge, plan name */}
          {
            <div className="col-md-12 col-12 text-center mb-2">
              {props?.affiliations?.map((affiliation, index) => {
                if (affiliation?.status === "active") {
                  return (
                    <Chip
                      className="m-1"
                      color={"primary"}
                      key={index}
                      label={affiliation?.plan?.name}
                      variant={"outlined"}
                    />
                  );
                }
              })}
            </div>
          }
        </div>
        {/* division */}
        <hr className="divider" />

        {/* Appointment Data */}
        <div className="w-100 col-md-7 col-sm-12">
          <div>
            <p className="m-3">
              <span className="text-label">{t("Appointment Type")}: </span>
              <span className="text-span-value">
                {t(props.event_type)?.toUpperCase() || "CONSULTATION"}
              </span>
            </p>
            <p className="m-3">
              <span className="text-label">{t("Appointment Date")}: </span>{" "}
              <span className="text-span-value">
                {props.appt_date_start ||
                  new Date().toLocaleString("en-US", options)}
              </span>
            </p>
            <p className="m-3">
              <span className="text-label">{t("Appointment Status")}: </span>
              <span
                className={`font-weight-bold ${
                  props.appt_status_code
                    ? APPT_STATUS_CODES[props.appt_status_code]
                    : "text-warning"
                }`}
              >
                {t(props.appt_status_name)?.toUpperCase() || "NO-STATUS"}
              </span>
            </p>
            {/* Price */}
            <p className="m-3">
              <span className="text-label">{t("Price")}: </span>
              <span className="text-span-value">$ {props.price || 0} usd</span>
            </p>

            {/* Payment Status */}
            <p className="m-3">
              <span className="text-label">Payment Status: </span>
              <span className="text-span-value">
                {props.paymentStatus?.toUpperCase() === "COMPLETED" ? (
                  <FaCheckDouble className="paymentIcon-completed" />
                ) : (
                  <FaUserClock className="paymentIcon-await" />
                )}
              </span>
            </p>
          </div>
          <div className="row m-3">
            {/* <Button
                         //Feature under construction
                         variant="contained"
                         color="primary"
                         size="medium"
                         className="w-auto m-1"
                         startIcon={<DescriptionIcon />}
                         //className="w-30-custom"
                         //onClick={handleClick}
                         >
                         Detail
                         </Button> */}

            <Button
              variant="contained"
              color="primary"
              size="medium"
              className="w-auto m-1"
              startIcon={<EditIcon />}
              style={{ backgroundColor: "#f13a53" }}
              onClick={handleOpen}
            >
              {t("Manage")}
            </Button>
            {/* If the Event TYpe if Differente to Virtual Show the Button Start Consultation and hide Join Btn */}

            {props?.event_type.toUpperCase() !== "VIRTUAL" ? (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                className="w-auto m-1"
                startIcon={<LaunchIcon />}
                style={{ backgroundColor: "#3eb53c" }}
                onClick={handleStarConsultation}
                disabled={disableStartButton}
              >
                {t("Start")}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="medium"
                className="w-auto m-1"
                startIcon={<VideoCallIcon />}
                style={{ backgroundColor: "#3eb53c" }}
                onClick={handleJoin}
                disabled={disableButton}
              >
                {t("Join")}
              </Button>
            )}
          </div>
        </div>

        <div>
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              {
                <div className={classes.paper}>
                  <img
                    src={
                      props?.profileImage
                        ? resizeByLink(props?.profileImage, "upload")
                        : ProfileLogo
                    }
                    alt="avatar"
                    className="rounded-circle img-fluid row mx-auto w-30-custom"
                  />
                  <h2 className="font-weight-bold" id="transition-modal-title">
                    {props?.patient_fullname || "Josua Zarcht"}
                  </h2>
                  <p id="transition-modal-element">
                    {t("Appointment due to")}:{" "}
                    {props?.appt_date_start ||
                      new Date().toLocaleString("en-US", options)}
                  </p>
                  <p id="transition-modal-description">
                    {t("Service")}: <b>{props?.service_name}</b>
                  </p>
                  <p id="transition-modal-status">
                    {t("Status")}:{" "}
                    <span
                      className={`font-weight-bold ${
                        props?.appt_status_code
                          ? APPT_STATUS_CODES[props?.appt_status_code]
                          : "text-warning"
                      }`}
                    >
                      {t(props?.appt_status_name)?.toUpperCase()}
                    </span>
                  </p>
                  {/* Price */}
                  <p id="transition-modal-price">
                    {t("Price")}: <b>$ {props?.price || "N/A"} usd</b>
                  </p>
                  {/* Appointment Reason */}
                  <div className="my-3">
                    <span>
                      <b>{t("Appointment reason")}:</b>
                    </span>
                    <Alert severity="success">
                      {props?.appointmentReason || t("No reason provided")}
                    </Alert>
                  </div>
                  {/* Control to change Service Price */}

                  <div className="form-group justify-content-center p-3">
                    <fieldset className="fieldset row mb-2">
                      <h3 className="label-new-service">
                        {t("Set New Price")} {": "}
                      </h3>
                      <TextField
                        id="input-price"
                        className="mt-2"
                        label={t("Price") + " ($)"}
                        variant="outlined"
                        value={newPrice}
                        onChange={(e) => setNewPrice(e.target.value)}
                      />

                      <div className="col-md-6">
                        <Button
                          className="mt-3"
                          variant="contained"
                          color="secondary"
                          startIcon={<CheckCircleOutlineIcon />}
                          onClick={handleUpdatePrice}
                          disabled={
                            props?.paymentStatus.toUpperCase() === "COMPLETED"
                              ? true
                              : btnStatus
                          }
                        >
                          {t("Save")}
                        </Button>
                      </div>
                    </fieldset>
                  </div>

                  {/* Fin Control change Service */}

                  <div className="modal-footer">
                    <Tooltip title={t("Confirm")} arrow>
                      <IconButton
                        aria-label="confirm"
                        onClick={() => handleChangeStatus("CAPPT")}
                      >
                        <EventAvailableIcon style={{ color: "green" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("Cancel")} arrow>
                      <IconButton
                        aria-label="cancel"
                        onClick={() => handleChangeStatus("CCAPPT")}
                      >
                        <EventBusyIcon style={{ color: "red" }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t("Payment Reminder")} arrow>
                      <IconButton aria-label="cancel" onClick={handleReminder}>
                        <TouchAppIcon style={{ color: "#3d73c3" }} />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={t("Concluded")} arrow>
                      <IconButton
                        aria-label="cancel"
                        onClick={() => handleChangeStatus("CDAPPT")}
                      >
                        <OfflinePinIcon style={{ color: "#17a2b8" }} />
                      </IconButton>
                    </Tooltip>
                    {/* <button
                                         type="button"
                                         className="btn btn-primary"
                                         onClick={handleClose}
                                         >
                                         Edit Appointment
                                         </button> */}
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={handleClose}
                    >
                      {t("Close")}
                    </button>
                  </div>
                </div>
              }
            </Fade>
          </Modal>
        </div>
      </div>
      <hr className="divider-dashboard-appt" />
    </>
  );
}
