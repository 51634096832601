import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
//Icons
import Box from "@material-ui/core/Box";
import {
  FaHeartbeat,
  FaAllergies,
  FaCapsules,
  FaClinicMedical,
  FaStethoscope,
  FaFilePrescription,
  FaMedkit,
  FaPeopleCarry,
  FaSyringe,
  FaFileMedicalAlt,
  FaUser,
  FaUserFriends,
} from "react-icons/fa";
import { GiScalpel } from "react-icons/gi";
import HealthCondition from "./HealthCondition";
import Consultations from "./Consultations";
import Prescriptions from "./Prescriptions";
import Healthmeasurement from "./Healthmeasurements";
import DependantsPatient from "./EHR/DependantsPatient";
import { Button } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DependantsModalForm from "./DependantsModalForm";
import VaccinesPatient from "./EHR/VaccinesPatient";
import VaccinesModalForm from "./VaccinesModalForm";
import HealthConditionModalForm from "./HealthConditionModalForm";
import HealthMeasurementModalForm from "./HealthMeasurementModalForm";
import useBDocTranslator from "../hooks/useBDocTranslator";
import AppointmentFiles from "./AppointmentFiles";
import EHRFilesModalForm from "./EHRFilesModalForm";
import useCalcBmi from "../hooks/useCalcBmi";
// import BackgroundForm from "./BackgroundForm";
import BackgroundTable from "./BackgroundTable";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function TabScrollablePatient(props) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const { t } = useBDocTranslator();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //Handle the modal
  const handleClose = () => {
    setOpen(false);
  };

  const { calcBmi } = useCalcBmi();

  const calcBodyMass = useMemo(() => {
    const measurementsSorted = props.healthMeasurement.sort(function (a, b) {
      return new Date(b.created_at) - new Date(a.created_at);
    });
    const latestSize = measurementsSorted.find(
      (i) => i.measurement_type === "SIZ"
    );
    const latestWeight = measurementsSorted.find(
      (i) => i.measurement_type === "WEI"
    );

    if (!latestWeight && !latestSize) {
      return {
        error: true,
        message: "Please add Weight and Size to calculate Body Mass Index",
      };
    }
    if (!latestWeight && !!latestSize) {
      return {
        error: true,
        message: "Please add Weight to calculate Body Mass Index",
      };
    }
    if (!!latestWeight && !latestSize) {
      return {
        error: true,
        message: "Please add Size to calculate Body Mass Index",
      };
    }
    if (latestSize && latestWeight) {
      const bmiResult = calcBmi(latestSize.result, latestWeight.result);
      return {
        latestSize,
        latestWeight,
        data: bmiResult,
      };
    }
    return {
      error: true,
      message: "Please add Weight and Size to calculate Bosy Mass Index",
    };
  }, [props.healthMeasurement]);

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          textColor="primary"
          aria-label="scrollable force tabs example"
        >
          <Tab
            label={t("Health Conditions")}
            icon={<FaHeartbeat style={{ fontSize: 20 }} />}
            {...a11yProps(0)}
          />
          <Tab
            label={t("Allergies")}
            icon={<FaAllergies style={{ fontSize: 20 }} />}
            {...a11yProps(1)}
          />
          <Tab
            label={t("Vaccines")}
            icon={<FaSyringe style={{ fontSize: 20 }} />}
            {...a11yProps(2)}
          />
          <Tab
            label={t("Medications")}
            icon={<FaCapsules style={{ fontSize: 20 }} />}
            {...a11yProps(3)}
          />
          <Tab
            label={t("Hospitalizations")}
            icon={<FaClinicMedical style={{ fontSize: 20 }} />}
            {...a11yProps(4)}
          />
          <Tab
            label={t("Surgery")}
            icon={<GiScalpel style={{ fontSize: 20 }} />}
            {...a11yProps(5)}
          />
          <Tab
            label={t("Personal Background")}
            icon={<FaUser style={{ fontSize: 20 }} />}
            {...a11yProps(6)}
          />
          <Tab
            label={t("Family Background")}
            icon={<FaUserFriends style={{ fontSize: 20 }} />}
            {...a11yProps(7)}
          />
          <Tab
            label={t("Consultations")}
            icon={<FaStethoscope style={{ fontSize: 20 }} />}
            {...a11yProps(8)}
          />
          <Tab
            label={t("Prescriptions")}
            icon={<FaFilePrescription style={{ fontSize: 20 }} />}
            {...a11yProps(9)}
          />
          <Tab
            label={t("Family group")}
            icon={<FaPeopleCarry style={{ fontSize: 20 }} />}
            {...a11yProps(10)}
          />
          <Tab
            label={t("Health Monitor")}
            icon={<FaMedkit style={{ fontSize: 20 }} />}
            {...a11yProps(11)}
          />
          <Tab
            label={t("Exams and Results")}
            icon={<FaFileMedicalAlt style={{ fontSize: 20 }} />}
            {...a11yProps(12)}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <div className="d-flex justify-content-end m-2">
          <Button
            variant="contained"
            color="primary"
            className="m-1"
            startIcon={<AddCircleIcon />}
            onClick={() => setOpen(true)}
          >
            {t("New")}
          </Button>
        </div>
        <HealthCondition
          data={props.healthConditions}
          conditionType="H-CONDITION"
          tableTitle={t("Health Conditions")}
          handleDeleteCondition={props.handleDeleteCondition}
          cie
        />
        <HealthConditionModalForm
          open={open}
          modalTitle={t("Health Condition")}
          handleClose={handleClose}
          patientId={props.patientId}
          type="pat"
          conditionType="H-CONDITION"
          handleRefreshData={props.handleRefreshData}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <div className="d-flex justify-content-end m-2">
          <Button
            variant="contained"
            color="primary"
            className="m-1"
            startIcon={<AddCircleIcon />}
            onClick={() => setOpen(true)}
          >
            {t("New")}
          </Button>
        </div>
        <HealthCondition
          data={props.healthConditions}
          conditionType="ALLERGIES"
          tableTitle={t("Allergies")}
          handleDeleteCondition={props.handleDeleteCondition}
        />
        <HealthConditionModalForm
          open={open}
          modalTitle={t("Allergies")}
          handleClose={handleClose}
          patientId={props.patientId}
          conditionType="ALLERGIES"
          handleRefreshData={props.handleRefreshData}
        />
      </TabPanel>
      {/* Vaccines Tab */}
      <TabPanel value={value} index={2}>
        <div className="d-flex justify-content-end m-2">
          <Button
            variant="contained"
            color="primary"
            className="m-1"
            startIcon={<AddCircleIcon />}
            onClick={() => setOpen(true)}
          >
            {t("New")}
          </Button>
        </div>
        <VaccinesPatient data={props.vaccines} tableTitle={t("Vaccines")} />
        <VaccinesModalForm
          open={open}
          handleClose={handleClose}
          patientId={props.patientId}
          handleRefreshData={props.handleRefreshData}
        />
      </TabPanel>

      <TabPanel value={value} index={3}>
        <div className="d-flex justify-content-end m-2">
          <Button
            variant="contained"
            color="primary"
            className="m-1"
            startIcon={<AddCircleIcon />}
            onClick={() => setOpen(true)}

            //onClick={props.handleSave}
          >
            {t("New")}
          </Button>
        </div>
        <HealthCondition
          data={props.healthConditions}
          conditionType="MEDICATIONS"
          tableTitle={t("Medications")}
          handleDeleteCondition={props.handleDeleteCondition}
        />
        <HealthConditionModalForm
          open={open}
          modalTitle={t("Medications")}
          handleClose={handleClose}
          patientId={props.patientId}
          conditionType="MEDICATIONS"
          handleRefreshData={props.handleRefreshData}
        />
      </TabPanel>

      <TabPanel value={value} index={4}>
        <div className="d-flex justify-content-end m-2">
          <Button
            variant="contained"
            color="primary"
            className="m-1"
            startIcon={<AddCircleIcon />}
            onClick={() => setOpen(true)}

            //onClick={props.handleSave}
          >
            {t("New")}
          </Button>
        </div>
        <HealthCondition
          data={props.healthConditions}
          conditionType="HOSPITALIZATIONS"
          tableTitle={t("Hospitalizations")}
          handleDeleteCondition={props.handleDeleteCondition}
        />
        <HealthConditionModalForm
          open={open}
          modalTitle={t("Hospitalizations")}
          handleClose={handleClose}
          patientId={props.patientId}
          conditionType="HOSPITALIZATIONS"
          handleRefreshData={props.handleRefreshData}
        />
      </TabPanel>

      <TabPanel value={value} index={5}>
        <div className="d-flex justify-content-end m-2">
          <Button
            variant="contained"
            color="primary"
            className="m-1"
            startIcon={<AddCircleIcon />}
            onClick={() => setOpen(true)}
            //onClick={props.handleSave}
          >
            {t("New")}
          </Button>
        </div>
        <HealthCondition
          data={props.healthConditions}
          conditionType="SURGERIES"
          tableTitle={t("Surgeries")}
          handleDeleteCondition={props.handleDeleteCondition}
        />
        <HealthConditionModalForm
          open={open}
          modalTitle={t("Surgeries")}
          handleClose={handleClose}
          patientId={props.patientId}
          conditionType="SURGERIES"
          handleRefreshData={props.handleRefreshData}
        />
      </TabPanel>

      <TabPanel value={value} index={6}>
        {/* <div className="d-flex justify-content-end m-2">
          <Button
            variant="contained"
            color="primary"
            className="m-1"
            startIcon={<AddCircleIcon />}
            onClick={() => setOpen(true)}
          >
            {t("New")}
          </Button>
        </div> */}
        <BackgroundTable
          title="Personal Background"
          data={props.bgPersonal}
          handleRefreshData={props.handleRefreshData}
          type="pat"
        />
        {/* <BackgroundForm
          open={open}
          title="Personal Background"
          handleClose={handleClose}
          patientId={props.patientId}
          type="personal"
          handleRefreshData={props.handleRefreshData}
        /> */}
      </TabPanel>
      <TabPanel value={value} index={7}>
        {/* <div className="d-flex justify-content-end m-2">
          <Button
            variant="contained"
            color="primary"
            className="m-1"
            startIcon={<AddCircleIcon />}
            onClick={() => setOpen(true)}
          >
            {t("New")}
          </Button>
        </div> */}
        <BackgroundTable
          title="Family Background"
          data={props.bgFamily}
          handleRefreshData={props.handleRefreshData}
          type="pat"
        />
        {/* <BackgroundForm
          open={open}
          title="Family Background"
          handleClose={handleClose}
          patientId={props.patientId}
          type="family"
          handleRefreshData={props.handleRefreshData}
        /> */}
      </TabPanel>

      <TabPanel value={value} index={8}>
        <Consultations data={props.consultationNotes} section="patient" />
      </TabPanel>

      <TabPanel value={value} index={9}>
        <Prescriptions data={props.prescription} />
      </TabPanel>

      {/* Dependants TAB */}
      <TabPanel value={value} index={10}>
        <div className="d-flex justify-content-end m-2">
          <Button
            variant="contained"
            color="primary"
            className="m-1"
            startIcon={<AddCircleIcon />}
            onClick={() => setOpen(true)}
          >
            {t("New")}
          </Button>
        </div>
        <DependantsPatient
          data={props.dependants}
          tableTitle={"Family group"}
          handleIncludeBeneficiary={props.handleIncludeBeneficiary}
          affiliationId={props.affiliationId}
        />
        <DependantsModalForm
          open={open}
          handleClose={handleClose}
          patientId={props.patientId}
          handleRefreshData={props.handleRefreshData}
          affiliationId={props.affiliationId}
        />
      </TabPanel>

      {/* Health Measurement TAB */}
      <TabPanel value={value} index={11}>
        <div className="d-flex justify-content-end m-2">
          <Button
            variant="contained"
            color="primary"
            className="m-1"
            startIcon={<AddCircleIcon />}
            onClick={() => setOpen(true)}
          >
            {t("New")}
          </Button>
        </div>
        <Healthmeasurement data={props.healthMeasurement} type="pat" />
        <HealthMeasurementModalForm
          open={open}
          handleClose={handleClose}
          patientId={props.patientId}
          handleRefreshData={props.recall.fetchHealthMeasurements}
          calcBodyMass={calcBodyMass}
          appointmentFiles={props.appointmentFiles}
          type="pat"
        />
      </TabPanel>
      {/* Documents */}
      <TabPanel value={value} index={12}>
        <div className="d-flex justify-content-end m-2">
          <Button
            variant="contained"
            color="primary"
            className="m-1"
            startIcon={<AddCircleIcon />}
            onClick={() => setOpen(true)}
          >
            {t("New")}
          </Button>
        </div>
        <AppointmentFiles data={props.appointmentFiles} />
        <EHRFilesModalForm
          open={open}
          handleClose={handleClose}
          patientId={props.patientId}
          handleRefreshData={props.handleRefreshData}
        />
      </TabPanel>
    </div>
  );
}
