import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import DashboardAdmin from "../../DashboardAdmin";
import swal from "sweetalert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useHistory } from "react-router-dom";
import TableAffiliations from "../../../utils/TableAffiliations";
import { AffliationsLogic } from "./AffliationsLogic";
import AffiliationModalEdit from "./AffiliationModalEdit";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import AffiliationModalAssignation from "./AffiliationModalAssignation";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "50px",
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function AffiliationsPlan() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openAFFModal, setOpenAFFModal] = useState(false);
  const [selectedAffiliation, setSelectedAffiliation] = useState({});

  const { getAffiliationList, affiliationList } = AffliationsLogic();

  const history = useHistory();

  //this method manage the backdrop status
  const handleClose = () => {
    setOpen(false);
    if (openAFFModal) {
      setOpenAFFModal(false);
    }
  };

  useEffect(() => {
    getAffiliationList();
  }, []);

  // Open the modal to edit the affiliation and pass the data to the modal
  const handleEditAffiliation = (data) => {
    setSelectedAffiliation(data);
    setOpen(true);
  };

  return (
    <DashboardAdmin>
      <div className="d-flex justify-content-end col-md-12 m-3 ">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setOpenAFFModal(true)}
        >
          Create Affiliation
        </Button>
      </div>
      {/* Element that block the screen when request its happen */}
      <div className={classes.root}>
        <TableAffiliations
          clickEvent={handleEditAffiliation}
          data={affiliationList}
        />
      </div>
      <AffiliationModalEdit
        open={open}
        handleClose={handleClose}
        data={selectedAffiliation}
      />
      <AffiliationModalAssignation
        open={openAFFModal}
        handleClose={handleClose}
      />
    </DashboardAdmin>
  );
}
