import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function PlannerMenuTab({
  calendarSelection,
  setCalendarSelection,
  calendarsAvailable,
}) {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const calendars = calendarsAvailable || ["Virtual"];

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setCalendarSelection(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        {calendars.map((calendar, index) => (
          <Tab label={calendar?.name} key={index} value={calendar?.id} />
        ))}
        {/* <Tab label="Virtual" />
        <Tab label="On Site" /> */}
      </Tabs>
    </Paper>
  );
}
