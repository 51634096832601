import React from "react";

const useCalcBmi = () => {
  const calcBmi = (size, weight) => {
    const h = Number(size) / 100;
    const w = Number(weight);
    const bmi = w / h ** 2;
    return bmi.toFixed(2);
  };
  return { calcBmi };
};

export default useCalcBmi;
