import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, Container } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(8),
    alignItems: "center",
    padding: "30px",
    backgroundColor: "white",
    boxShadow: "5px 5px 16px 1px #caced0",
    borderRadius: "10px",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
export default function PlanReview({
  planInfo,
  planSpecialtiesInfo,
  planProvidersInfo,
  specialties,
  providers,
  planToEdit,
}) {
  const classes = useStyles();

  const getSpecialtyName = (specialty_id) => {
    return specialties.find((s) => s.id === parseInt(specialty_id))
      ?.speciality_name;
  };

  return (
    <Container component="main">
      <CssBaseline />
      <div className={classes.paper}>
        <div className="mb-3">
          <h3>Plan Review</h3>
          <p>
            Review the plan information below and click <strong>finish</strong>{" "}
            to create the plan.
          </p>
        </div>
        {/* Plan Basic Info Accordion */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>
              Plan Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <div className="col-md-12">
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="planName">Plan Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="planName"
                      name="planName"
                      placeholder="Enter Plan Name"
                      value={planInfo.name || planToEdit.name}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label htmlFor="planDescription">Plan Description</label>
                    <input
                      type="text"
                      className="form-control"
                      id="planDescription"
                      name="planDescription"
                      placeholder="Enter Plan Description"
                      value={planInfo.description || planToEdit.description}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="col-md-6">
                  <label htmlFor="planPrice">Plan Price</label>
                  <input
                    type="text"
                    className="form-control"
                    id="planPrice"
                    name="planPrice"
                    placeholder="Enter Plan Price"
                    value={planInfo.price || planToEdit.price}
                    disabled
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="planStatus">Plan Status</label>
                  <input
                    type="text"
                    className="form-control"
                    id="planStatus"
                    name="planStatus"
                    placeholder="Enter Plan Status"
                    value={planInfo.status || planToEdit.status}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="col-md-6">
                  <label htmlFor="planBillingCycle">Plan Billing Cycle</label>
                  <input
                    type="text"
                    className="form-control"
                    id="planBillingCycle"
                    name="planBillingCycle"
                    placeholder="Enter Plan Billing Cycle"
                    value={planInfo.billing_cycle || planToEdit.billing_cycle}
                    disabled
                  />
                </div>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {/* Plan Specialties Accordion */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Specialties Associated
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <div className="col-md-12">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Specialty Name</th>
                      <th>Consultations Allowed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {planSpecialtiesInfo?.length > 0 &&
                      !Object.keys(planToEdit).length > 0 &&
                      planSpecialtiesInfo.map((planSpecialty) => {
                        const findingEsp = specialties.find(
                          (specialty) =>
                            specialty?.id ===
                            Number(planSpecialty?.specialty_id)
                        );
                        return (
                          <tr key={planSpecialty.specialty_id}>
                            <td>{findingEsp?.speciality_name}</td>
                            <td>{planSpecialty.num_consultations}</td>
                          </tr>
                        );
                      })}
                    {planToEdit.planSpecialties?.length > 0 ? (
                      planToEdit.planSpecialties.map((specialty) => {
                        if (specialty?.status === "active") {
                          return (
                            <tr key={specialty.id}>
                              <td>
                                {getSpecialtyName(specialty.specialty_id)}
                              </td>
                              <td>{specialty?.num_consultations}</td>
                            </tr>
                          );
                        }
                      })
                    ) : (
                      <tr>
                        <td colSpan={2}>No Specialties Added</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        {/* Plan Providers Accordion */}
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>
              Healthcare Providers
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <div className="col-md-12">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Provider Name</th>
                      <th>Specialty</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!planToEdit?.id &&
                      planProvidersInfo.map((planProvider) => {
                        const findingProv = providers.find(
                          (provider) =>
                            provider?.id === Number(planProvider?.provider_id)
                        );
                        return (
                          <tr key={planProvider.provider_id}>
                            <td>
                              {findingProv?.firstName +
                                " " +
                                findingProv?.lastname}
                            </td>
                            <td>
                              {findingProv?.medical_speciality?.speciality_name}
                            </td>
                          </tr>
                        );
                      })}
                    {planToEdit.providersPlan?.length > 0 ? (
                      planToEdit.providersPlan.map(({ provider }) => (
                        <tr key={provider.id}>
                          <td>
                            {provider?.firstName + " " + provider?.lastname}
                          </td>
                          <td>
                            {provider?.medical_speciality?.speciality_name}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={2}>No Providers Added</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Container>
  );
}
