import React, { useState } from "react";

const AiChat = () => {
  const [messages, setMessages] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const message = event.target.elements.message.value;
    setMessages([...messages, message]);
    event.target.reset();
  };

  return (
    <div className="chat-container">
      {messages.map((message, index) => (
        <p key={index}>{message}</p>
      ))}
      <form onSubmit={handleSubmit}>
        <input type="text" name="message" required />
        <button type="submit">Enviar</button>
      </form>
    </div>
  );
};

export default AiChat;
