// TO DECOMISSION THIS CONFIGURATION
import firebase from "firebase/app"; // For Firebase JS SDK v7.20.0 and later, measurementId is optional
import "firebase/firestore";
import "firebase/analytics";

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_APIKEY,
  authDomain: process.env.REACT_APP_FB_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID,
}; // Initialize Firebase

const fb = firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = fb.firestore();
