export const PAYMENT_OBJECT = {
  paymentData: {
    card: {
      cardHolder: "REQUIRED",
      expirationDate: {
        month: 1,
        year: 25,
      },
      cardNumber: "4242424242424242",
      cvc: "124",
      nickname: "visa4242",
    },
  },
  orderRequestData: {
    merchantId: "82ec3b24-41ec-4b24-b02b-49a66f634277",
    terminal: "HCG Consulting Group-41b6-BAC-CR-USD",
    amount: 1,
    currency: "USD",
    description: "PAGO-DE-CONSULTA",
    orderReference: "APPT-ID",
    callback: "https://hcgconsulting.net/patient/checkout/gp/result/",
    additional: {
      customer: {
        name: "REQUIRED",
        email: "REQUIRED",
        identification: "REQUIRED",
        billingAddress: {
          country: "CR",
          province: "San Jose",
          city: "San Jose",
          street1: "Calle default",
          street2: "Calle default 2",
          zip: "33109",
        },
      },
      products: [
        {
          description: "Consulta-10min",
          skuId: "SKU-CONSULTA-10MIN",
          quantity: 1,
          price: 1,
          type: "Physical",
        },
      ],
    },
  },
};
