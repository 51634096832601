import React, { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import AddToQueueIcon from "@material-ui/icons/AddToQueue";
import { useHistory } from "react-router-dom";
import TodayIcon from "@material-ui/icons/Today";
import useBDocTranslator from "../hooks/useBDocTranslator";

const tableIcons = {
  AddToQueue: forwardRef((props, ref) => (
    <AddToQueueIcon {...props} ref={ref} />
  )),
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  CalendarToday: forwardRef((props, ref) => <TodayIcon {...props} ref={ref} />),
};

function TableAffiliations({ data, clickEvent }) {
  const { t } = useBDocTranslator();
  let history = useHistory();

  const handleAddAppointment = (row) => {
    history.push({
      pathname: "/admin/newAppointment",
      state: { patient: row },
    });
  };

  const filterData = data.map((row) => {
    return {
      id: row.id,
      name:
        row?.plan_owner?.firstName + " " + row?.plan_owner?.lastname || " - ",
      plan_name: row?.plan?.name,
      price: row?.plan?.price,
      billing_cycle: row?.plan?.billing_cycle,
      beneficiary_qty: row?.plan?.beneficiary_qty,
      beneficiaries_assigned: row?.beneficiaries?.length,
      status: row?.status,
      affiliation_dt_start: row?.affiliation_dt_start,
    };
  });

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: "Id", field: "id" },
          { title: t("Holder full name"), field: "name", width: 500 },
          { title: t("Plan name"), field: "plan_name", width: 500 },
          {
            title: t("Price"),
            field: "price",
          },
          { title: t("Billing Cycle"), field: "billing_cycle" },
          {
            title: t("Beneficiaries of Plan"),
            field: "beneficiary_qty",
          },
          {
            title: t("Beneficiaries Assigned"),
            field: "beneficiaries_assigned",
          },
          {
            title: t("Affiliation Status"),
            field: "status",
            lookup: {
              active: t("Active"),
              inactive: t("Inactive"),
              pending: t("Pending"),
            },
          },
          {
            title: t("Affiliation date"),
            field: "affiliation_dt_start",
            type: "date",
          },
        ]}
        data={filterData}
        title={t("Plan Affiliations")}
        icons={tableIcons}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF",
          },
          actionsColumnIndex: -1,
        }}
        actions={[
          {
            icon: Edit,
            tooltip: t("Edit"),
            onClick: (e, row) => clickEvent(row),
          },
        ]}
        localization={{
          body: {
            emptyDataSourceMessage: t("No records to display"),
          },
          header: {
            actions: t("Actions"),
          },
          toolbar: { searchPlaceholder: t("Search") },
        }}
      />
    </div>
  );
}

export default TableAffiliations;
