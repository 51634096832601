import React, { useEffect, useState } from "react";
import DashboardPatient from "../dashboard/DashboardPatient";
import MailSVG from "../../images/mail.svg";
import SearchModuleConsult from "../patient-portal/SearchModuleConsult";
import Axios from "axios";
import swal from "sweetalert";
import Loading from "../utils/Loading";
import { Button } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import UserLocalState from "./../hooks/useUserState";
import { useLocation, useHistory } from "react-router-dom";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import useMessages from "../hooks/useMessages";
import useBDocTranslator from "../hooks/useBDocTranslator";
import DashboardMui from "../dashboard/DashboardMui";
import PacienteSVG from "./../../images/paciente.svg";

function TextConsultation({ type }) {
  const history = useHistory();
  const userLocalState = UserLocalState();
  const { t } = useBDocTranslator();

  useEffect(() => {
    if (type === "pat" && userLocalState[0].user.role.id !== 2) {
      history.push("/admin/dashboard");
    }
    if (type === "pro" && userLocalState[0].user.role.id !== 3) {
      history.push("/patient/dashboard");
    }
  }, [userLocalState, type]);
  return (
    <>
      {type === "pat" && (
        <DashboardPatient>
          <TextConsultationChild type={type} />
        </DashboardPatient>
      )}
      {type === "pro" && (
        <DashboardMui
          sectionTitle={t("Text Consultation")}
          // icon={QuestionAnswerIcon}
          // description={t(
          //   "Details of Patients Registered on Practice or Tenant"
          // )}
        >
          <TextConsultationChild type={type} />
        </DashboardMui>
      )}
    </>
  );
}

function TextConsultationChild({ type }) {
  const [options, setOptions] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [specialties, setSpecialties] = useState([]);
  const [cancel, setCancel] = useState(false);
  const [consult, setConsult] = useState("");
  const [provider, setProvider] = useState(null);
  const [providerSelected, setProviderSelected] = useState({
    name: "",
    title: "",
  });

  const { createMessage, loading } = useMessages();

  const location = useLocation();
  const providerState = location.state;

  const { enqueueSnackbar } = useSnackbar();

  const useStyles = makeStyles((theme) => ({
    consultText: {
      width: "800px",
    },
    buttonStyle: {
      width: "100%",
    },
  }));
  const classes = useStyles();

  const userLocalState = UserLocalState();
  const { t } = useBDocTranslator();

  // filter the providers by providerId
  const filterProviders = (providerId, dataProvider) => {
    const filteredProviders = dataProvider.filter((provider) => {
      return provider.id === providerId;
    });
    return filteredProviders;
  };

  const [cie, setCie] = useState();
  const [isSelectAll, setIsSelectAll] = useState(false);

  useEffect(() => {
    if (type === "pat") {
      Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/textConsultation/findProviders?id=${userLocalState[0]?.extendedData?.id}`,
        {
          headers: { Authorization: "Bearer " + userLocalState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setLoadingData(false);
          setOptions(response.data);
        })
        .catch((err) => console.log(err));
    }

    if (type === "pro") {
      Axios.get(
        `${
          process.env.REACT_APP_API_URL_BASE
        }/textConsultation/findPatients?id=${
          userLocalState[0]?.extendedData?.id
        }${cie ? `&cie=${cie.code}` : ""}`,
        {
          headers: { Authorization: "Bearer " + userLocalState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setOptions(response.data);
          if (!!isSelectAll) {
            setProvider(response.data);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [cie]);

  /** Notify provider when Message was sent */
  const notifyProvider = async (usersIds) => {
    try {
      const response = await Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/textConsultation/${
          type === "pat" ? "notifyProvider" : "notifyPatient"
        }`,
        {
          usersIds,
        },
        {
          headers: { Authorization: "Bearer " + userLocalState[0].jwt }, //the jwt is a variable which holds the token
        }
      );
      console.log(response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSend = async () => {
    try {
      let msgArray = [];
      if (type === "pat") {
        msgArray = [
          {
            send_by_userid: userLocalState[0].user.id,
            send_to_userid: provider?.user_id,
            content: consult,
          },
        ];
      }
      if (type === "pro") {
        msgArray = provider.map((p) => ({
          send_by_userid: userLocalState[0].user.id,
          send_to_userid: p?.user_id,
          content: consult,
        }));
      }
      if (provider && consult) {
        await createMessage(msgArray);
        setConsult("");
        // enqueueSnackbar("Message was Sent!", {
        //   variant: "success",
        // });
        if (type === "pat") {
          await notifyProvider([provider.user_id]);
        }
        if (type === "pro") {
          await notifyProvider(provider.map((p) => p.user_id));
        }
        swal({
          title: t("Success"),
          text: t("Message was Sent!"),
          icon: "success",
          button: false,
        });
      } else {
        enqueueSnackbar(t("Error, Please fill the Form"), {
          variant: "error",
        });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar(
        "Something 's went wrong sending message, Contact support",
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <>
      <div className="d-flex flex-column">
        {type === "pat" && (
          <article className="m-3 p-3 shadow w-30 rounded-lg-custom bg-white">
            <h1 className="font-weight-bold">
              {t("Text Consultation")}{" "}
              <span>
                <img className="sunshine-icon" src={MailSVG} alt="mail" />
              </span>
            </h1>
          </article>
        )}

        <div>
          <div className="flex-column mt-4 shadow bg-white">
            <section className="p-3">
              <h3 className="m-2 font-weight-light">{t("Secure Message")}:</h3>
              <p className="p-2">
                {t(
                  "Please use the filters to select your prefered Healthcare Provider"
                )}
              </p>
            </section>
            <div>
              <SearchModuleConsult
                // handleSearch={handleSearch}
                // handleRefresh={handleRefresh}
                specialties={specialties}
                options={options}
                cancel={cancel}
                setCancel={setCancel}
                preselectedProvider={providerSelected}
                setProvider={setProvider}
                provider={provider}
                type={type}
                setCie={(v) => setCie(v)}
                isSelectAll={isSelectAll}
                onClickSelectAll={(e) => {
                  setIsSelectAll((p) => !p);
                  if (e.target.checked) {
                    setProvider(options);
                  }
                  if (!e.target.checked) {
                    setProvider(null);
                  }
                }}
              />
            </div>

            {provider && (
              <div className={classes.consultText + " d-flex ml-5 mb-5"}>
                <h5>
                  {t("Cost")}: {t("Free")}
                </h5>
              </div>
            )}
            <div className="d-flex m-4 px-3">
              <TextField
                id="Consult"
                placeholder={t("Write your Consult here")}
                multiline
                // className={classes.consultText}
                fullWidth
                rows={8}
                value={consult}
                variant="outlined"
                onChange={(e) => setConsult(e.target.value)}
                disabled={provider === null}
              />
            </div>
            <div
              className={
                "d-flex justify-content-end pb-4 pt-3 " + classes.buttonStyle
              }
            >
              {provider && (
                <Button
                  onClick={handleSend}
                  variant="contained"
                  color="primary"
                  className="mr-4 p-2"
                  disabled={consult === ""}
                >
                  {t("Send")}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TextConsultation;
