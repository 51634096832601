import React, { useEffect, useState, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import Axios from "axios";
import { CSVLink } from "react-csv";
import { makeStyles } from "@material-ui/core/styles";
import DashboardMui from "../../../dashboard/DashboardMui";
import CharSquare from "../../../../images/chart-square.svg";
import useUserState from "../../../hooks/useUserState";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Grid,
} from "@material-ui/core";
import useTranslator from "../../../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    margin: theme.spacing(2),
  },
  chartContainer: {
    padding: theme.spacing(2),
    paddingTop: 50,
    marginTop: theme.spacing(2),
    position: "relative",
  },
  totalPatients: {
    position: "absolute",
    top: 10,
    right: 10,
    fontWeight: "bold",
    fontSize: "1.2rem",
    paddingBottom: 5,
  },
  tableContainer: {
    marginTop: theme.spacing(4),
  },
  table: {
    minWidth: 650,
  },
}));

const COLORS = ["#0088FE", "#00C49F"];

const PatientStatsReport = () => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());
  const localState = useUserState();
  const [totalPatients, setTotalPatients] = useState(0);
  const { t, changeDirectLanguage } = useTranslator();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getData = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/reports/appointments/patients`,
      {
        headers: { Authorization: `Bearer ${localState[0].jwt}` },
        params: {
          startDate,
          endDate,
          providerId: localState[0].extendedData.id,
        },
      }
    );

    setData(response.data.dailyData);
    setTotalPatients(response.data.totals.totalPatients);
  };

  useEffect(() => {
    getData();
    changeDirectLanguage();
  }, [startDate, endDate]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const consultationTypeData = useMemo(() => {
    const virtualTotal = data.reduce(
      (acc, item) => acc + Number(item.virtualPatients),
      0
    );
    const onSiteTotal = data.reduce(
      (acc, item) => acc + Number(item.onSitePatients),
      0
    );
    return [
      { name: "Virtual", value: virtualTotal },
      { name: "On-Site", value: onSiteTotal },
    ];
  }, [data]);

  return (
    <DashboardMui
      sectionTitle={t("Patient Statistics Report")}
      description={t("View patient statistics over time.")}
      icon={CharSquare}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.datePicker}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          label={t("Start Date")}
          value={startDate}
          onChange={(date) => setStartDate(date)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardDatePicker
          className={classes.datePicker}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          label={t("End Date")}
          value={endDate}
          onChange={(date) => setEndDate(date)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <Button variant="contained" color="primary" onClick={getData}>
          {t("Apply Filter")}
        </Button>
      </MuiPickersUtilsProvider>

      <Paper className={classes.chartContainer} elevation={3}>
        <Typography className={classes.totalPatients}>
          {t("Total Patients")}: {totalPatients}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <ResponsiveContainer width="100%" height={400}>
              <BarChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="totalPatients"
                  fill="#8884d8"
                  name={t("Total Patients")}
                />
                <Bar
                  dataKey="virtualPatients"
                  fill="#82ca9d"
                  name={t("Virtual Patients")}
                />
                <Bar
                  dataKey="onSitePatients"
                  fill="#ffc658"
                  name={t("On-Site Patients")}
                />
              </BarChart>
            </ResponsiveContainer>
          </Grid>
          <Grid item xs={12} md={4}>
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={consultationTypeData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) =>
                    `${name} ${(percent * 100).toFixed(0)}%`
                  }
                >
                  {consultationTypeData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </Grid>
        </Grid>
      </Paper>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="patients report table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Date")}</TableCell>
              <TableCell align="right">{t("Total Patients")}</TableCell>
              <TableCell align="right">{t("Virtual Patients")}</TableCell>
              <TableCell align="right">{t("On-Site Patients")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <TableRow key={row.date}>
                  <TableCell component="th" scope="row">
                    {row.date}
                  </TableCell>
                  <TableCell align="right">{row.totalPatients}</TableCell>
                  <TableCell align="right">{row.virtualPatients}</TableCell>
                  <TableCell align="right">{row.onSitePatients}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <CSVLink data={data}>Download CSV</CSVLink>
    </DashboardMui>
  );
};

export default PatientStatsReport;
