import React, { forwardRef } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import HealthImage from "../../images/healthcondition.svg";
import useBDocTranslator from "../hooks/useBDocTranslator";
import { FaRegTrashAlt } from "react-icons/fa";
import Axios from "axios";
import useUserState from "../hooks/useUserState";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  CloudDownloadIcon: forwardRef((props, ref) => (
    <CloudDownloadIcon {...props} ref={ref} />
  )),
};

function BackgroundTable(props) {
  const { t } = useBDocTranslator();
  const userLocalState = useUserState();

  const deleteBg = (id) => {
    if (!id) return;

    Axios.put(
      `${process.env.REACT_APP_API_URL_BASE}/bg/delete`,
      { id },
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((res) => {
        props.handleRefreshData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={[
          { title: "Id", field: "id", width: 10 },
          { title: t("Content"), field: "content", width: 80 },
          {
            title: t("Register date"),
            field: "created_at",
            type: "datetime",
            width: 80,
          },
        ]}
        data={props.data}
        title={t(props.title)}
        icons={tableIcons}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF",
          },
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <span>
                <img style={{ width: "15%" }} src={HealthImage} alt="" />
              </span>
            ),
          },
        }}
        actions={
          props.type === "pro"
            ? [
                {
                  icon: () => <FaRegTrashAlt />,
                  tooltip: t("Delete"),
                  onClick: (e, row) => deleteBg(row.id),
                },
              ]
            : []
        }
      />
    </div>
  );
}

export default BackgroundTable;
