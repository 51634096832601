import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MessageIcon from "@material-ui/icons/Message";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IconButton from "@material-ui/core/IconButton";
import "../../../styles/consultText.css";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SendIcon from "@material-ui/icons/Send";
import TimelineMessage from "../../widgets/TimelineMessage";
import DraftsIcon from "@material-ui/icons/Drafts";
import useUserState from "../../hooks/useUserState";
import useBDocTranslator from "../../hooks/useBDocTranslator";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "1000px",
    [theme.breakpoints.down("sm")]: {
      width: "800px",
    },
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },
  drawer: {
    width: `calc(100% - ${drawerWidth}px)`,
    flexShrink: 0,
  },
  drawerPaper: {
    backgroundColor: "white",
    width: drawerWidth,
    border: "2px solid #dfdfdf",
    borderRadius: "10px",
  },
  divider: {
    width: 220,
    backgroundColor: "#dfdfdf !important",
  },
  dividerList: {
    width: "90%",
    backgroundColor: "#dfdfdf !important",
    marginLeft: "20px",
  },
  listIcon: {
    color: "#8e8e8e !important",
  },
  deletedIcon: {
    color: "#f96d6d !important",
  },
  answeredIcon: {
    color: "#47cc68 !important",
  },
  receivedIcon: {
    color: "#0077B6 !important",
  },
  // necessary for content to be below app bar
  toolbar: {
    zIndex: "1",
  },
  message: {
    width: "100%",
    borderRadius: "5px",
  },
  dashboard: {
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    border: "2px solid #dfdfdf",
    borderRadius: "10px",
  },
  listMessage: {
    width: "100%",
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  messageHeader: {
    width: "100%",
    height: "40px",
  },
  button: {
    width: "100px",
  },
}));

export default function MessageBody(props) {
  const [dashboard, setDashboard] = useState("received");
  const localState = useUserState();
  const { t } = useBDocTranslator();

  const classes = useStyles();

  return (
    <div
      className={classes.dashboard + " mx-1 flex-column container-fluid p-0"}
    >
      <div
        className={
          classes.messageHeader +
          " my-2 align-items-center justify-content-start d-flex"
        }
      >
        <div className="d-flex justify-content-start m-3">
          <IconButton onClick={props.handleBack} aria-label="back">
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <h4
          style={{
            margin: "auto",
            marginLeft: "10px",
            fontWeight: "bold",
          }}
        >
          {props.messageSelected.other_user?.extendedData.firstName +
            " " +
            props.messageSelected.other_user?.extendedData.lastname ||
            "Remitent"}
        </h4>
        <div className="text-center">
          <h6 className="label-read-btn mt-4">{t("Mark as Read")}</h6>
          <IconButton
            onClick={() =>
              props.handleMarkRead(props?.messageSelected?.send_to_userid)
            }
            aria-label="back"
          >
            <DraftsIcon />
          </IconButton>
        </div>
        {dashboard === "receivedA" && (
          <div className="d-flex justify-content-start m-3">
            <IconButton aria-label="back" className={classes.receivedIcon}>
              <MessageIcon />
            </IconButton>
          </div>
        )}
        {dashboard === "readA" && (
          <div className="d-flex justify-content-start m-3">
            <IconButton aria-label="back" className={classes.receivedIcon}>
              <VisibilityIcon />
            </IconButton>
          </div>
        )}
        {dashboard === "answeredA" && (
          <div className="d-flex justify-content-start m-3">
            <IconButton aria-label="back" className={classes.answeredIcon}>
              <CheckBoxIcon />
            </IconButton>
          </div>
        )}
        {dashboard === "rejectedA" && (
          <div className="d-flex justify-content-start m-3">
            <IconButton aria-label="back">
              <ThumbDownIcon />
            </IconButton>
          </div>
        )}
        {dashboard === "deletedA" && (
          <div className="d-flex justify-content-start m-3">
            <IconButton aria-label="back" className={classes.deletedIcon}>
              <DeleteIcon />
            </IconButton>
          </div>
        )}
      </div>

      <div
        className={
          classes.listMessage + " ml-2 justify-content-start d-flex p-3"
        }
      >
        <Typography variant="subtitle1" className={"w-full"} align="left">
          {t("Service - Text Consultation")}
        </Typography>
      </div>
      <div
        className={
          classes.listMessage + " ml-2 justify-content-start d-flex p-3"
        }
      >
        <Typography variant="subtitle1" className={"w-full"} align="left">
          {t("Cost - Free")}
        </Typography>
      </div>
      <div
        className={
          classes.listMessage + " ml-2 justify-content-start d-flex p-3"
        }
      ></div>
      <div
        className={
          classes.listMessage + " ml-2 justify-content-start d-flex p-3"
        }
      >
        <Typography variant="subtitle1" className={"w-full"} align="left">
          <p>
            <span>
              <b>{t("Message")}:</b>{" "}
            </span>
            {props.message}
          </p>
        </Typography>
      </div>
      {/* Timeline */}
      <div className="timeline-message-container">
        <TimelineMessage messages={props.messageSelected} />
      </div>
      <div
        className={classes.listMessage + " justify-content-center d-flex p-3"}
      >
        <TextField
          id="Response"
          placeholder={t("Write your answer here")}
          multiline
          // className={classes.consultText}
          className={"w-full"}
          rows={8}
          value={props.messageResponse}
          variant="outlined"
          onChange={(e) => props.handleSetResponse(e.target.value)}
        />
      </div>
      <div className={classes.listMessage + " justify-content-end d-flex p-3"}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button + " p-2"}
          endIcon={<SendIcon />}
          onClick={() => {
            //if localState user id is equal to send_to_userid then send to send_by_userid
            if (
              localState[0].user.id === props?.messageSelected?.send_to_userid
            ) {
              props.handleResponse(props?.messageSelected?.send_by_userid);
            } else {
              props.handleResponse(props?.messageSelected?.send_to_userid);
            }
          }}
        >
          {t("Send")}
        </Button>
      </div>
    </div>
  );
}
