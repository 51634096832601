import { Backdrop, Button, Fade, makeStyles } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React, { useState } from "react";
import useBDocTranslator from "../hooks/useBDocTranslator";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
import useUserState from "../hooks/useUserState";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    backgroundColor: "whitesmoke",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "900px",
  },
  title: {
    fontSize: "25px",
    color: "#000",
  },
  closeIcon: {
    position: "absolute",
    top: "10px",
    right: "10px",
    cursor: "pointer",
  },
}));

const ModalSendEmail = ({
  template,
  to,
  close = () => {},
  isOpen,
  title,
  children,
  send,
}) => {
  const classes = useStyles();
  const [msg, setMsg] = useState("");
  const { t } = useBDocTranslator();
  const userLocalState = useUserState();

  const sendEmail = () => {
    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/emails/send`,
      {
        to,
        msg,
        template,
      },
      {
        headers: { Authorization: "Bearer " + userLocalState[0]?.jwt },
      }
    )
      .then((response) => {
        close();
        swal({
          title: t("Success!"),
          text: t("Message Sent"),
          icon: "success",
          button: "OK",
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={() => {
          setMsg("");
          close();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onBackdropClick={() => {
          setMsg("");
          close();
        }}
      >
        <div className={classes.paper}>
          <CloseIcon className={classes.closeIcon} onClick={close} />
          <p className={classes.title}>{t(title)}</p>
          {/* <p>{to.map((t) => `${t.email}`).join(" | ")}</p> */}
          {children ? children : <></>}
          <textarea
            name="email-text"
            id="email-text"
            rows="20"
            placeholder={t("Enter Email Message")}
            className="mb-3 w-100 h-60-custom form-control col-md-12"
            onChange={(e) => setMsg(e.target.value)}
            value={msg}
          ></textarea>

          <div className="d-flex justify-content-around">
            {/* Update Condition */}
            <Button
              variant="contained"
              onClick={close}
              className="col-md-5"
              // disabled={!btnStatus}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!msg}
              onClick={() => {
                if (send) {
                  send(msg);
                  return;
                }
                sendEmail();
              }}
              className="col-md-5"
            >
              {t("Send")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ModalSendEmail;
