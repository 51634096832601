export const measurementTypes = [
  {
    value: "BP",
    label: "Blood Pressure",
  },
  {
    value: "ECG",
    label: "Electrocardiogram",
  },
  {
    value: "SpO2",
    label: "Oxygen saturation",
  },
  {
    value: "BT",
    label: "Body Temperature",
  },
  {
    value: "BG",
    label: "Blood Glucose",
  },
  {
    value: "WEI",
    label: "Weight",
    extra: "Weight and Size",
  },
  {
    value: "SIZ",
    label: "Size",
    disabled: true,
  },
  {
    value: "BOM",
    label: "Body Mass Index",
    disabled: true,
  },
  {
    value: "FAT",
    label: "Fat",
  },
  {
    value: "MM",
    label: "Muscle Mass",
  },
  {
    value: "MUS",
    label: "Muscle",
  },
  {
    value: "KCAL",
    label: "Kilocalories",
  },
  {
    value: "WAT",
    label: "Water",
  },
];

export const showExtraOnSelect = ["WEI"];

export const BgMeasurementTypes = [
  {
    value: "mg/dl",
    label: "mg/dl",
  },
  {
    value: "mmol/L",
    label: "mmol/L",
  },
];

export const measurementNormalText = [
  "SpO2",
  "FAT",
  "MM",
  "MUS",
  "WAT",
  "BT",
  "WEI",
  "SIZ",
  "KCAL",
];
export const measurementPrompt = {
  BT: "°C",
  SpO2: "%",
  FAT: "%",
  MM: "kg",
  MUS: "%",
  WAT: "%",
  WEI: "kg",
  SIZ: "cm",
  KCAL: "kcal",
};
