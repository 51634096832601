import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Axios from "axios";
import LoadingSpinner from "../../utils/Loading";
import DashboardAdmin from "../DashboardAdmin";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import useUserState from "../../hooks/useUserState";
import swal from "sweetalert";
import useBDocTranslator from "../../hooks/useBDocTranslator";

import {
  Button,
  CssBaseline,
  TextField,
  Grid,
  Container,
  MenuItem,
  Typography,
} from "@material-ui/core";
import TablePharmacy from "../../utils/TablePharmacy";
import InformationComp from "../../utils/InformationComp";
import { prescTypes } from "../../prescription/PrenscriptionDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "white",
    boxShadow: "5px 5px 16px 1px #1b3342",
    borderRadius: "10px",
  },
  gender: {
    width: "100%",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "transparent",
    borderRadius: 0,
    width: "60px",
    height: "60px",
    marginBottom: "10px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  formControl: {
    minWidth: 120,
    width: 180,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function PharmacyForm() {
  const classes = useStyles();
  let history = useHistory();
  const userState = useUserState();

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    msgClass: "",
  });

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [directions, setDirections] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [pharmacyList, setPharmacyList] = useState([]);

  const { t, changeDirectLanguage } = useBDocTranslator();

  const clearForm = () => {
    document.getElementById("pharmacy-form").reset();
    setEmail("");
    setName("");
    setDirections("");
    setCity("");
    setCountry("");
    setPhone("");
    setType("");
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (email && name && directions && city && country && phone) {
      setLoading(true);
      Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/pharmacy/create`,
        {
          email: email,
          name: name,
          directions: directions,
          city_id: city,
          phone: phone,
          country_id: country,
          type,
        },
        {
          headers: { Authorization: "Bearer " + userState[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            swal(
              t("Success!"),
              t("Pharmacy registered successfully"),
              t("success")
            );
            clearForm();
            getAllPharmacies();
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          swal("Error!", "something went wrong", "error");
        });
    } else {
      setErrorMessage({
        error: "Error, Please complete the fields",
        visible: true,
        msgClass: "alert alert-danger",
      });
    }
  };

  // Fetch the stored roles
  useEffect(() => {
    changeDirectLanguage("es");
    getAllPharmacies();
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/countries/findAll`, {
      headers: {
        Authorization: "Bearer " + userState[0].jwt, //the jwt is a variable which holds the token
      },
    })
      .then((res) => {
        // setRoles(res.data);
        setCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/countries/findCities?id=${country}`,
      {
        headers: {
          Authorization: "Bearer " + userState[0].jwt, //the jwt is a variable which holds the token
        },
      }
    )
      .then((res) => {
        // setRoles(res.data);
        setCities(res.data);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [country]);

  const getAllPharmacies = () => {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/pharmacy/findAll?order=created_at`,
      {
        headers: {
          Authorization: "Bearer " + userState[0].jwt, //the jwt is a variable which holds the token
        },
      }
    )
      .then((res) => {
        setPharmacyList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const clickEvent = (row) => {
    console.log(row);
  };

  return (
    <DashboardAdmin
      sectionTitle="Register Pharmacy"
      description="Register a new pharmacy"
    >
      <Container component="main">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography component="h1" variant="h6" className="mt-2">
            {t("Register a new place in the system")}
          </Typography>
          <Typography component="h6" variant="h6" className="mt-2">
            {errorMessage.visible && (
              <p className="text-danger">{t("Please complete the fields")}</p>
            )}
          </Typography>
          <form className={classes.form} noValidate id="pharmacy-form">
            <Grid container spacing={2} className="justify-content-center">
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  autoComplete="name"
                  name="name"
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label={t("Name")}
                  autoFocus
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  autoComplete="email"
                  name="email"
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="E-mail"
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  autoComplete="phone"
                  name="phone"
                  variant="outlined"
                  required
                  fullWidth
                  id="phone"
                  label={t("Phone number")}
                  autoFocus
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  id="country"
                  required
                  select
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  placeholder={t("Country")}
                  label={t("Country")}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countries.length > 0 &&
                    countries.map((r) => {
                      return (
                        <MenuItem key={r.id} value={r.id}>
                          {r.name}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  id="city"
                  required
                  select
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  placeholder={t("City")}
                  label={t("City")}
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                >
                  {cities.length > 0 &&
                    cities.map((r) => {
                      return (
                        <MenuItem key={r.id} value={r.id}>
                          {r.name}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  id="type"
                  required
                  select
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  placeholder={t("Type")}
                  label={t("Type")}
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  {prescTypes.length > 0 &&
                    prescTypes.map((r) => {
                      return (
                        <MenuItem key={r.id} value={r.value}>
                          {t(r.label)}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6} md={8}>
                <TextField
                  autoComplete="directions"
                  name="directions"
                  variant="outlined"
                  required
                  fullWidth
                  id="directions"
                  label={t("Directions")}
                  autoFocus
                  onChange={(e) => setDirections(e.target.value)}
                />
              </Grid>
              <InformationComp
                title={t("Important!")}
                description={t(
                  "Be sure to not repeat the same pharmacy name in the system in order to avoid data duplication."
                )}
              />
            </Grid>
            {loading && (
              <div className="d-flex justify-content-center">
                <LoadingSpinner type="spinningBubbles" color="#1d3557" />
              </div>
            )}
          </form>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-center ">
            <div className="p-3">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={handleClick}
                disabled={
                  !email || !type || !name || !phone || !city || !country
                }
              >
                {t("Save")}
              </Button>
            </div>

            <div className="p-3">
              <Button
                variant="contained"
                color="default"
                startIcon={<CancelIcon />}
                onClick={(e) => history.goBack()}
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
        </div>
      </Container>
      <div className="m-3 p-3 shadow rounded-lg-custom bg-white">
        <TablePharmacy
          translator={t}
          data={pharmacyList}
          clickEvent={clickEvent}
        />
      </div>
    </DashboardAdmin>
  );
}
