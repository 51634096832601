import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import consultationIcon from "../../images/consultation-icon.svg";
import useUserState from "../hooks/useUserState";
import Axios from "axios";
import TextField from "@material-ui/core/TextField";
import { Button } from "@material-ui/core";
import useBDocTranslator from "../hooks/useBDocTranslator";
import { Autocomplete } from "@material-ui/lab";
import useDebounce from "../hooks/useDebounce";
import { addDays, format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "whitesmoke",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "900px",
  },
  textHelper: {
    lineHeight: "15px",
    margin: 0,
  },
}));

export default function HealthConditionModalForm(props) {
  const classes = useStyles();
  const [conditionNotes, setConditionNotes] = useState(""); // this is the state for prescription
  const [cieSearch, setCieSearch] = useState("");
  const [startDate, setStartDate] = useState("");
  const [cie, setCie] = useState();
  const [cieOptions, setCieOptions] = useState();
  const [responseMessage, setResponseMessage] = useState("");
  const userLocalState = useUserState();
  const { t } = useBDocTranslator();
  const [newConditionNotes, setNewConditionNotes] = useState("");

  const handleConditionNotes = (e) => {
    setConditionNotes(e.target.value);
  };

  const cieSearchDebounced = useDebounce(cieSearch, 500);

  //Method for Save the prescription
  const handleSaveHealthCondition = () => {
    if (!startDate) return;
    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/healthconditions/create`,
      {
        segment_name: props.conditionType,
        detail:
          props.type === "pro"
            ? `${cie?.description} / ${conditionNotes}`
            : `${conditionNotes}`,
        cie: cie?.code,
        patient_id: props.patientId,
        provider_id: userLocalState[0]?.user.id,
        start_date: addDays(new Date(startDate), 1),
      },
      {
        headers: { Authorization: "Bearer " + userLocalState[0]?.jwt },
      }
    )
      .then((response) => {
        setResponseMessage(
          `Record for ${props?.conditionType} saved successfuly`
        );
        setConditionNotes("");
        props.handleRefreshData();
        props.handleClose();
      })
      .catch((error) => {
        console.log(error);
        setResponseMessage(error);
      });
  };

  const getCie = async () => {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/cie/get${
        cieSearch ? `?search=${cieSearch}` : ""
      }`,
      {
        headers: { Authorization: "Bearer " + userLocalState[0]?.jwt },
      }
    )
      .then((response) => {
        setCieOptions(response.data);
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    getCie();
  }, [cieSearchDebounced]);

  useEffect(() => {
    if (
      !!props?.edition &&
      !!props?.updatableData?.detail &&
      !!props?.updatableData?.cie
    ) {
      setNewConditionNotes(props.updatableData.detail.split("/")[1]?.trim());
    }
    if (
      !!props?.edition &&
      !!props?.updatableData?.detail &&
      !props?.updatableData?.cie
    ) {
      setNewConditionNotes(props.updatableData.detail);
    }
    if (!!props?.edition && !props?.updatableData?.start_date) setStartDate();
    if (!!props?.edition && !!props?.updatableData?.start_date) {
      setStartDate(
        format(new Date(props.updatableData.start_date), "yyyy-MM-dd")
      );
    }
  }, [props.updatableData, props.edition]);

  const handleUpdateHealthCondition = async () => {
    if (!startDate) return;
    let detail = `${newConditionNotes}`;
    if (props.type === "pro") {
      if (!cie)
        detail = `${
          props.updatableData.detail.split("/")[0]
        } / ${newConditionNotes}`;
      if (cie) detail = `${cie.description} / ${newConditionNotes}`;
    }
    try {
      const response = await Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/healthconditions/update/${props.updatableData?.id}`,
        {
          detail,
          cie: cie?.code,
          start_date: addDays(new Date(startDate), 1),
        },
        {
          headers: { Authorization: "Bearer " + userLocalState[0]?.jwt },
        }
      );
      props.handleRefreshData();
      props.handleClose();
      setResponseMessage(response.data?.message);
    } catch (error) {
      console.log(error);
      setResponseMessage(error.response?.data?.message);
    }
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={() => {
          setNewConditionNotes("");
          props.handleClose();
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onBackdropClick={() => {
          setNewConditionNotes("");
          props.handleClose();
        }}
      >
        <Fade in={props.open}>
          {
            <div className={classes.paper}>
              <div className="row col-md-12">
                <div className="col-md-4">
                  <img
                    src={consultationIcon}
                    alt="rx icon"
                    className="w-30-custom"
                  />
                </div>
                <div className="col-md-8">
                  <h3>
                    <b>{t("New")}</b> - {props.modalTitle}
                  </h3>
                </div>
              </div>
              {/* Form */}
              <div className="row col-md-12">
                {/* <div className="form-group justify-content-center p-3"> */}
                <div
                  style={{ height: "fit-content" }}
                  className="shadow h-fit p-3 m-2 bg-white rounded-lg col-md-12 col-sm-12 position-relative prescription-card"
                >
                  {props.type === "pro" && (
                    <Autocomplete
                      id="cie"
                      onChange={(e, newValue) => setCie(newValue)}
                      options={cieOptions}
                      getOptionLabel={(option) =>
                        `${option.code} - ${option.description}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          onChange={(e) => setCieSearch(e.target.value)}
                          label={t("CIE Code or description")}
                          variant="outlined"
                        />
                      )}
                    />
                  )}
                  {/* {props.type === "pro" && (
                    <p className={classes.textHelper}>
                      {t("This code can't be change it in the future")}
                    </p>
                  )} */}

                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    margin="normal"
                    id="startDate"
                    name="startDate"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="date"
                    label={t("Start Date")}
                    onChange={(e) => setStartDate(e.target.value)}
                    value={startDate || ""}
                  />

                  {!props.edition && (
                    <textarea
                      name="condition-notes-txt"
                      id="condition-notes-txt"
                      rows="10"
                      placeholder={t("Enter the Health Information")}
                      className="mb-3 w-100 h-60-custom form-control col-md-12"
                      onChange={handleConditionNotes}
                      value={conditionNotes}
                    ></textarea>
                  )}
                  {/* For update when props.edition is true and get the value from prop updatableData */}
                  {props.edition && (
                    <textarea
                      name="condition-notes-txt"
                      id="condition-notes-txt"
                      rows="10"
                      placeholder={t("Enter the Health Information")}
                      className="mb-3 w-100 h-60-custom form-control col-md-12"
                      onChange={(e) => setNewConditionNotes(e.target.value)}
                      value={newConditionNotes}
                    ></textarea>
                  )}
                  {props.edition && (
                    <div className="d-flex justify-content-around">
                      <div className="col-md-5">
                        <p className="font-weight-bold">{t("Created at")}:</p>
                        <p className="font-weight-bold">{t("Updated at")}:</p>
                      </div>
                      <div className="col-md-5">
                        <p>{props.updatableData.created_at}</p>
                        <p>{props.updatableData.updated_at}</p>
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-around">
                    <Button
                      variant="contained"
                      color="primary"
                      disabled={
                        !props.edition
                          ? props.type === "pro"
                            ? !conditionNotes || !cie || !startDate
                            : !conditionNotes || !startDate
                          : !conditionNotes
                      }
                      onClick={handleSaveHealthCondition}
                      className="col-md-5"
                    >
                      {t("Save")}
                    </Button>
                    {/* Update Condition */}
                    {props.edition && (
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleUpdateHealthCondition}
                        className="col-md-5"
                        // disabled={!btnStatus}
                      >
                        {t("Update")}
                      </Button>
                    )}
                  </div>
                  <div className="col-md-6 mt-2">
                    <p className="font-weight-bold">{responseMessage}</p>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
          }
        </Fade>
      </Modal>
    </div>
  );
}
