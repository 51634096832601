import React, { useState, useEffect, useMemo } from "react";
import Button from "@material-ui/core/Button";
import { InputAdornment, MenuItem, TextField } from "@material-ui/core";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";
import {
  BgMeasurementTypes,
  measurementNormalText,
  measurementPrompt,
  measurementTypes,
  showExtraOnSelect,
} from "../../const/healthMeasurement";
import useCalcBmi from "../hooks/useCalcBmi";
import useUploadEHRFiles from "../hooks/useUploadEHRFiles";
import EHRFilesDialog from "./EHRFilesDialog";

export default function HealthMeasurementDialog(props) {
  const userLocalState = useUserState();
  const [measurementResult, setMeasurementResult] = useState("");
  const [measurement, setMeasurement] = useState("");
  const [extraValue, setExtraValue] = useState("");
  const [measurementBgType, setMeasurementBgType] = useState(
    BgMeasurementTypes[0].value
  );
  const { t, language } = useBDocTranslator();
  const [systolicMeasurement, setSystolicMeasurement] = useState("");
  const [diastolicMeasurement, setDiastolicMeasurement] = useState("");

  const { calcBmi } = useCalcBmi();

  const calcBodyMass = useMemo(() => {
    if (measurement !== "WEI") return;
    if (!measurementResult && !extraValue) {
      return {
        error: true,
        message: "Please add Weight and Size",
      };
    }
    if (!measurementResult) {
      return {
        error: true,
        message: "Please add Weight",
      };
    }
    if (!extraValue) {
      return {
        error: true,
        message: "Please add Size",
      };
    }
    if (extraValue && measurementResult) {
      const bmiResult = calcBmi(extraValue, measurementResult);
      return bmiResult;
    }
  }, [extraValue, measurementResult, measurement]);

  // TODO: validar si concatenarlos con slash funciona

  const data = {
    measurement_type: measurement,
    created_by_userId: userLocalState[0].user.id,
    result: measurementResult,
    patient_id: props.patientId,
  };

  // clean values for next use
  const handleCleanValues = () => {
    setMeasurement("");
    setMeasurementResult("");
  };

  useEffect(() => {
    if (props.successFlag) {
      handleCleanValues();
    }
  }, [props.successFlag]);

  const onBpChange = (e) => {
    const newDiastolicValue = e.target.value;
    setDiastolicMeasurement(newDiastolicValue);
    // Actualizar measurementResult con el valor actual de systolicMeasurement y el nuevo valor de diastolicMeasurement
    setMeasurementResult(`${systolicMeasurement}/${newDiastolicValue}`);
  };

  const bgConvertion = useMemo(() => {
    if (measurement === "BG" && measurementBgType === "mg/dl") {
      return `${(measurementResult / 18).toFixed(2)} mmol/L`;
    }
    if (measurement === "BG" && measurementBgType === "mmol/L") {
      return `${(measurementResult * 18).toFixed(2)} mg/dl`;
    }
    return "";
  }, [measurementResult, measurement, measurementBgType]);

  const [ecgFileUrl, setEcgFileUrl] = useState("");

  const {
    responseMessage,
    handleUploadEHRFile,
    successFlag: EHRSuccess,
    setSuccessFlag,
    setUploadedFiles,
    loading,
    uploadedFiles,
  } = useUploadEHRFiles(props.patientId, async (dataFile) => {
    if (dataFile.url?.length > 0) {
      setEcgFileUrl(dataFile.url[0]);
      if (props.type === "pat") {
        let sendData = data;
        sendData.result = `Agregar Notas /*/ ${dataFile.url[0]}`;
        await props.handleMeasureCreate(data);
        props.handleClose();
      }
    }
  });

  useEffect(() => {
    if (props.editValue && props.type === "pro") {
      if (props.editValue && props.editValue.result.split("/*/").length > 0) {
        const splitV = props.editValue.result.split("/*/");
        setMeasurement("ECG");
        setMeasurementResult(splitV[0]);
        setEcgFileUrl(splitV[1]);
        setSuccessFlag(true);
      }
    }
  }, [props.editValue]);

  const disableSave = useMemo(() => {
    if (measurement === "WEI" && !!calcBodyMass?.error) return true;
    if (measurement === "ECG" && !EHRSuccess) return true;
    return false;
  }, [calcBodyMass, measurement, EHRSuccess]);

  return (
    <div className="shadow m-2 bg-white rounded-lg col-md-12 col-sm-12  py-2">
      <div className="col">
        <div className="col-md-12 w-100 mt-2">
          <TextField
            id="measurementType"
            select
            label={t("Measure Type")}
            variant="outlined"
            className="w-100"
            value={measurement}
            onChange={(e) => {
              setMeasurement(e.target.value);
              if (props.editValue) props.setEditValue();
              props.resetResponseMsg();
            }}
            required={true}
          >
            {measurementTypes
              .sort((a, b) =>
                t(a.label).toLowerCase().localeCompare(t(b.label).toLowerCase())
              )
              .filter((i) => !i.disabled)
              .map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {showExtraOnSelect.includes(option.value)
                    ? t(option.extra)
                    : t(option.label)}
                </MenuItem>
              ))}
          </TextField>
        </div>

        <div className="col-md-12 w-100 mt-2">
          {measurementNormalText.includes(measurement) && (
            <div className="row">
              <div className="col-md-6">
                <TextField
                  id={measurement}
                  variant="outlined"
                  className="w-100"
                  type="number"
                  label={
                    measurementTypes.find((i) => i.value === measurement)
                      ?.placeHolder
                      ? t(
                          measurementTypes.find((i) => i.value === measurement)
                            ?.placeHolder
                        )
                      : `${t(
                          measurementTypes.find((i) => i.value === measurement)
                            ?.label || ""
                        )}`
                  }
                  value={measurementResult}
                  onChange={(e) => setMeasurementResult(e.target.value)}
                  required={true}
                  InputProps={
                    measurementPrompt[measurement]
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              {measurementPrompt[measurement]}
                            </InputAdornment>
                          ),
                        }
                      : {}
                  }
                />
              </div>
              {measurement === "WEI" && (
                <>
                  <div className="col-md-6 d-flex align-items-center justify-content-center">
                    <TextField
                      id={extraValue}
                      variant="outlined"
                      className="w-100"
                      label={`${t("Size")}`}
                      value={extraValue}
                      onChange={(e) => setExtraValue(e.target.value)}
                      required={true}
                      type="number"
                      InputProps={
                        measurementPrompt["SIZ"]
                          ? {
                              endAdornment: (
                                <InputAdornment position="end">
                                  {measurementPrompt["SIZ"]}
                                </InputAdornment>
                              ),
                            }
                          : {}
                      }
                    />
                  </div>
                  {calcBodyMass && !calcBodyMass?.error && (
                    <div className="row my-2 ml-2">
                      <div className="col-12 ">
                        {t("Body Mass Index") + ": " + calcBodyMass}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          {measurement === "BP" && (
            <div className="row">
              <div className="col-md-6">
                <TextField
                  id="systolic"
                  label={t("Systolic") + " (alta)"}
                  variant="outlined"
                  className="w-100"
                  type="number"
                  value={systolicMeasurement}
                  onChange={(e) => setSystolicMeasurement(e.target.value)}
                  required={true}
                />
              </div>
              <div className="col-md-6">
                <TextField
                  id="diastolic"
                  label={t("Diastolic") + " (baja)"}
                  variant="outlined"
                  className="w-100"
                  type="number"
                  value={diastolicMeasurement}
                  onChange={(e) => {
                    onBpChange(e);
                  }}
                  required={true}
                />
              </div>
            </div>
          )}
          {measurement === "ECG" && (
            <div className="row">
              {EHRSuccess && props.type === "pro" && (
                <div className="col-md-12">
                  <p>
                    {t(responseMessage)}{" "}
                    {!props.editValue
                      ? t("Add files Notes")
                      : t("Edit Notes file")}
                  </p>
                </div>
              )}
              <div className="col-md-12">
                {!EHRSuccess && (
                  <EHRFilesDialog
                    labelSaveButton={t("Updload file")}
                    handleUploadEHRFile={handleUploadEHRFile}
                    successFlag={EHRSuccess}
                    patientId={props.patientId}
                    uploadedFiles={uploadedFiles}
                    setUploadedFiles={setUploadedFiles}
                    loading={loading}
                    handleClose={() => {
                      if (props.type === "pat") {
                        props.handleClose();
                      }
                    }}
                    noCancel={props.type !== "pat"}
                  />
                )}
                {EHRSuccess && props.type === "pro" && (
                  <TextField
                    id="ecg-notes"
                    label={t("Notes")}
                    variant="outlined"
                    className="w-100"
                    value={measurementResult}
                    onChange={(e) => setMeasurementResult(e.target.value)}
                    required={true}
                  />
                )}
              </div>
            </div>
          )}
          {measurement === "BG" && (
            <>
              <div className="row">
                <div className="col-md-6">
                  <TextField
                    id="bg"
                    label={t("Blood Glucose")}
                    variant="outlined"
                    type="number"
                    className="w-100"
                    value={measurementResult}
                    onChange={(e) => setMeasurementResult(e.target.value)}
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <TextField
                    id="measurementType"
                    select
                    label={t("Measure Type")}
                    variant="outlined"
                    className="w-100"
                    value={measurementBgType}
                    onChange={(e) => setMeasurementBgType(e.target.value)}
                    required={true}
                  >
                    {BgMeasurementTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {t(option.label)}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
              {measurementResult && bgConvertion && (
                <div className="row">
                  <div className="col-12">
                    <p className="text-right">{bgConvertion}</p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {props.type === "pat" && measurement === "ECG" ? (
        <></>
      ) : (
        <div className="row mt-2 mx-1">
          <Button
            variant="contained"
            color="primary"
            disabled={disableSave}
            onClick={async () => {
              let sendData = data;
              if (sendData.measurement_type === "BG") {
                if (measurementBgType === "mg/dl") {
                  sendData.result = `${sendData.result} ${measurementBgType} - ${bgConvertion}`;
                }
                if (measurementBgType === "mmol/L") {
                  sendData.result = `${bgConvertion} - ${sendData.result} ${measurementBgType}`;
                }
              }
              if (sendData.measurement_type === "ECG") {
                sendData.result = `${sendData.result} /*/ ${ecgFileUrl}`;
              }
              if (measurement === "WEI" && !!calcBodyMass.error) return;
              await props.handleMeasureCreate(data, measurement !== "WEI");
              if (sendData.measurement_type === "ECG") {
                setSuccessFlag(false);
              }
              if (measurement === "WEI") {
                const dataSIZ = {
                  measurement_type: "SIZ",
                  created_by_userId: userLocalState[0].user.id,
                  result: extraValue,
                  patient_id: props.patientId,
                };
                await props.handleMeasureCreate(dataSIZ, false);
                const dataBOM = {
                  measurement_type: "BOM",
                  created_by_userId: userLocalState[0].user.id,
                  result: calcBodyMass,
                  patient_id: props.patientId,
                };
                await props.handleMeasureCreate(dataBOM, true);
              }
              props.handleClose();
              if (props.editValue) props.setEditValue();
              setEcgFileUrl();
            }}
            className="col-md-6"
          >
            {t("Save")}
          </Button>
          <Button
            variant="contained"
            color="default"
            onClick={props.handleClose}
            className="col-md-6"
          >
            {t("Cancel")}
          </Button>
        </div>
      )}
    </div>
  );
}
