import React, { useEffect, useState } from "react";
import { makeStyles, Button, useTheme } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, useHistory } from "react-router-dom";

import ListSubheader from "@material-ui/core/ListSubheader";
import BreadCrumbsNav from "./BreadCrumbsNav";
import doctor from "../../images/doctor.svg";
// import Logo from "../../images/logo-doc-nic.jpg";
// import Logo from "../../images/logo-doc-nic.jpg";
import Avatar from "@material-ui/core/Avatar";
import DashSidebarOption from "./DashSidebarOption";
import "../../styles/customs.css";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

//Icons Components
import HomeIcon from "@material-ui/icons/Home";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import TodayIcon from "@material-ui/icons/Today";
import BuildIcon from "@material-ui/icons/Build";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SecurityIcon from "@material-ui/icons/Security";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import { FaCalendarAlt, FaUsers, FaUserMd } from "react-icons/fa";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ReactTooltip from "react-tooltip";
import AppsIcon from "@material-ui/icons/Apps";
import HeadingResume from "../widgets/HeadingResume";
import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import UseUserState from "../hooks/useUserState";
import swal from "sweetalert";
import useMessages from "../hooks/useMessages";
import useBDocTranslator from "../hooks/useBDocTranslator";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "50px",
    height: "50px",
  },
  doctor: {
    paddingTop: "10px",
  },
  menu: {
    marginTop: "40px",
  },
  menuAppIcon: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuName: {
    color: "#ffffff",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuDivider: {
    display: "flex",
    height: "40px",
    maxHeight: "40px",
    width: "2px",
    backgroundColor: "#0c436a9e" /* #dcddde73*/,
    margin: "10px",
    marginRight: "15px",
    borderRadius: "8px",
    border: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#0077B6",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  // appBar: {
  //   //width: `calc(100% - ${drawerWidth}px)`,
  //   //marginLeft: drawerWidth,
  //   backgroundColor: "#0077B6",
  //   zIndex: theme.zIndex.drawer + 1,
  // },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    //backgroundColor: "#212529",
  },
  drawerItems: {
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "60px",
    [theme.breakpoints.down("sm")]: {
      width: "45px",
    },
  },
  menuButton: {
    marginRight: 40,
  },
  // necessary for content to be below app bar
  toolbarSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#e5e5e5",
    padding: theme.spacing(3),
    height: "auto",
    marginLeft: "-15px",
  },
  avatar: {
    //marginLeft: "50px",
    //backgroundColor: theme.palette.secondary.main,
    width: "60px",
    height: "60px",
  },
  superiorToolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconColor: {
    color: "#d9eef7",
  },
}));

/* Functions for manage the rendering of icons from other Libs diff to material-ui */
function FaCalendarComp() {
  return <FaCalendarAlt style={{ fontSize: "1.3rem" }} />;
}

function FaUsersComp() {
  return <FaUsers style={{ fontSize: "1.3rem" }} />;
}

function FaUserMdComp() {
  return <FaUserMd style={{ fontSize: "1.3rem" }} />;
}

export default function DashboardMui(props) {
  const userState = UseUserState();
  const classes = useStyles();
  let history = useHistory();
  const [selectedMenu, setSelectedMenu] = useState(false);

  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowUp, setArrowUp] = useState(false);

  const [profileImg, setProfileImg] = useState(() => {
    if (userState[0]?.user.user_images?.length > 0) {
      return userState[0]?.user.user_images.at(-1).path_resource;
    }
    return doctor;
  });

  const Logo = process.env.REACT_APP_TENANT_LOGO;

  const { getApiMessages, badgeMessage } = useMessages();

  const { t, changeDirectLanguage } = useBDocTranslator();

  //New Messages indicator
  // const [messages, setMessages] = useState([]);
  // const [badgeMessage, setBadgeMessage] = useState(0);
  // breadcumps options for dashboard
  const [breadcrumbsOptions, setBreadcrumbsOptions] = useState(() => {
    return [{ title: "home", url: "/" }];
  });

  const handleClick = (event) => {
    setArrowUp(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setArrowUp(false);
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //TODO Finish this functionality
  const handleSelectMenu = () => {
    console.log("menu");
  };

  //Collections of icons for sidebar menu
  const iconsEnum = [
    {
      option: "Patients",
      icon: FaUsersComp,
      order: 1,
    },
    {
      option: "Home",
      icon: HomeIcon,
      order: 0,
    },
    {
      option: "Providers",
      icon: FaUserMdComp,
      order: 2,
    },
    {
      option: "Appointments",
      icon: TodayIcon,
      order: 3,
    },
    {
      option: "Settings",
      icon: BuildIcon,
      order: 4,
    },
    {
      option: "Roles",
      icon: SecurityIcon,
      order: 5,
    },
    {
      option: "Users",
      icon: SupervisorAccountIcon,
      order: 6,
    },
    {
      option: "My Calendar",
      icon: FaCalendarComp,
      order: 7,
    },
    {
      option: "Text Consultations",
      icon: QuestionAnswerIcon,
      order: 8,
    },
    {
      option: "Appointments Income Report", // Add a new option for the report
      icon: AssessmentIcon,
      order: 9, // Adjust the order as needed
    },
  ];

  const logOut = () => {
    window.sessionStorage.clear();
    history.push("/admin/login");
  };

  //validate if the session or JWT Exist
  const validateSession = () => {
    if (!userState[0]?.jwt) {
      history.push("/admin/login");
    }
  };

  //Find the corresponding Icon
  const findIcon = (optionName, iconsCollection) => {
    const result = iconsEnum.find(({ option }) => option === optionName);
    return result?.icon ? result.icon : HomeIcon;
  };

  //Effect to validate the user session
  useEffect(() => {
    validateSession();
    validateProviderRequestStatus();
    // getMessages();
    getApiMessages();
    buildBreadcumsOptions();
    // change language
    changeDirectLanguage(userState[0]?.language || "en");
  }, []);

  //To go to Text consultation screen
  const handleMessageClick = () => {
    history.push("/admin/textconsult");
  };

  const buildBreadcumsOptions = () => {
    const modules = userState[0]?.user.role.role_modules;
    let result = [{ title: "Home", url: "/admin/dashboard" }];
    if (modules?.length > 0) {
      modules.forEach((role) => {
        result.push({ title: role.module.print_name, url: role.module.route });
      });
      setBreadcrumbsOptions(result);
    }
  };

  const validateProviderRequestStatus = () => {
    const provider = userState[0]?.user.provider;
    // If Logged user is a Provider validate the Application code
    if (provider) {
      // Extracting the Application code
      const {
        status_provider: { code },
      } = provider;
      // If application is different to Approved redirect the user to login
      if (code !== "APR") {
        swal("Alert!", t("under.review.warning"), "warning");
        setTimeout(() => {
          logOut();
        }, 2000);
      }
    }
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        color="primary"
      >
        <Toolbar className={classes.superiorToolbar}>
          <div className="d-flex">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon className={classes.iconColor} />
            </IconButton>
          </div>
          <div className="d-flex">
            <Button className={classes.menuAppIcon}>
              <AppsIcon style={{ color: "white" }} />
            </Button>
            <Button>
              <NotificationsActiveIcon style={{ color: "white" }} />
            </Button>
            {/* Menu Message */}
            <Button onClick={handleMessageClick}>
              <Badge color="error" badgeContent={badgeMessage}>
                <MailIcon style={{ color: "white" }} />
              </Badge>
            </Button>
            <hr className={classes.menuDivider} />

            <div className="img-cropper-profile align-items-center">
              <Avatar src={profileImg} className="avatar-custom" />
            </div>

            <div>
              <IconButton onClick={handleClick} className="mt-2">
                {!arrowUp ? (
                  <KeyboardArrowDownIcon style={{ color: "white" }} />
                ) : (
                  <KeyboardArrowUpIcon style={{ color: "white" }} />
                )}
              </IconButton>
              <Button
                className={classes.menuName}
                aria-controls="simple-menu"
                aria-haspopup="true"
              >
                {`${userState[0]?.extendedData?.firstName} ${userState[0]?.extendedData?.lastname}`}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menu}
              >
                {/* <MenuItem onClick={(e) => handleClose()}>Healthcare profile</MenuItem> */}
                <MenuItem
                  onClick={(e) => {
                    handleClose();
                    history.push(`/provider/editProfile`);
                  }}
                >
                  {t("edit.profile")}
                </MenuItem>
              </Menu>
            </div>
            <Button onClick={logOut}>
              <ExitToAppIcon style={{ color: "white" }} />
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        anchor="left"
      >
        <div className={classes.toolbarSide}>
          <Avatar src={Logo} variant="square" className={classes.logo} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon className={classes.iconColor} />
            ) : (
              <ChevronLeftIcon className={classes.iconColor} />
            )}
          </IconButton>
        </div>

        <Divider />
        <div className={classes.drawerItems}>
          <List>
            {/* default Home Component */}
            <Link to="/admin/dashboard" data-tip={t("home")}>
              <DashSidebarOption
                // linkTo="/admin/dashboard"
                Icon={HomeIcon}
                optionText={t("home")}
              />
              <ReactTooltip />
            </Link>
            {/* Menu Sidebar Options for User */}
            {userState[0]?.user &&
              userState[0]?.user.role.role_modules.map(({ module }) => {
                return (
                  module.print_name !== "Roles" &&
                  module.print_name !== "Users" && (
                    <Link
                      to={module.route}
                      key={module.id}
                      data-tip={t(module.print_name)}
                    >
                      <DashSidebarOption
                        Icon={findIcon(module.print_name, iconsEnum)}
                        optionText={t(module.print_name)}
                      />
                      <ReactTooltip />
                    </Link>
                  )
                );
              })}
          </List>
          <Divider />
          <ListSubheader
            inset
            style={{ fontWeight: "bold", color: "cornflowerblue" }}
          >
            Security
          </ListSubheader>

          <List>
            {/* Menu Sidebar SECURITY OPCIONS FOR ADMIN */}
            {userState[0]?.user &&
              userState[0]?.user.role.role_modules.map(({ module }) => {
                return (
                  userState[0]?.user.role.name.toUpperCase() === "ADMIN" &&
                  (module.print_name === "Roles" ||
                  module.print_name === "Users" ? (
                    <Link
                      to={module.route}
                      key={module.id}
                      data-tip={module.print_name}
                    >
                      <DashSidebarOption
                        Icon={findIcon(module.print_name, iconsEnum)}
                        optionText={module.print_name}
                      />
                    </Link>
                  ) : null)
                );
              })}
          </List>
          <Divider />
          <List>
            <Link to="/admin/reports" data-tip="admin dashboard">
              <ListItem button>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary={t("Reports")} />
              </ListItem>
            </Link>
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <BreadCrumbsNav breadOptions={breadcrumbsOptions} />
        {/* Here in this Div put a section title */}
        {/* <div className={classes.toolbarSidebar}>
          <h1 className="h1-section-title mt-4">
            {props?.sectionTitle || "Section"} {" | "}
            <span className="span-section-title">
              {props?.sectionSubtitle || "Section Detail"}
            </span>
          </h1>
        </div> */}
        <HeadingResume
          title={props.sectionTitle || "Section Title"}
          icon={props.icon}
          description={props.description}
        />
        {props.children}
      </main>
    </div>
  );
}
