// Component to Create a new Appointment
import React, { useEffect } from "react";
import { format } from "date-fns";
import { useState } from "react";
import Axios from "axios";
import LoadingSpin from "../utils/Loading";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import swal from "sweetalert";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { dateToString } from "../utils/DateUtils";
import TimeSlots from "../patients/TimeSlots";
import DashboardMui from "../dashboard/DashboardMui";
import UserLocalState from "../hooks/useUserState";
import calendarIcon from "../../images/appointment-icon.svg";
import useBDocTranslator from "../hooks/useBDocTranslator";
import InformationComp from "../utils/InformationComp";

export default function ProviderAppointment() {
  const userLocalState = UserLocalState();
  const jwtSession = userLocalState[0].jwt;
  const locations = userLocalState[0].extendedData.provider_locations;

  const location = useLocation();
  const [currentUserId, setCurrentUserId] = useState(userLocalState[0].user.id);
  const [providerId, setProviderId] = useState(() => {
    return userLocalState[0].extendedData.id;
  });
  const [patientId, setPatientId] = useState(() => {
    if (location?.state) {
      const {
        patient: { id },
      } = location.state;
      return id;
    }
  });
  const [service_open, setService_open] = React.useState(false);
  const [service_options, setService_options] = React.useState([]);
  const service_loading = service_open && service_options.length === 0;
  const [service_type_name, setService_type_name] = useState("");

  const [duration, setDuration] = useState("");
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const [event_type, setEvent_type] = useState("");
  const [appointment_type, setAppointment_type] = useState("");
  const [appt_date_start, setAppt_date_start] = useState();
  const [appt_date_end, setAppt_date_end] = useState(new Date());
  const [service_type, setService_type] = useState("");
  const [loading_page, setLoading_page] = useState(false);
  const [loadingSlots, setLoadingSlots] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    msgClass: "SOMETHING IS WRONG",
  });
  let history = useHistory();
  const [slotsAvailables, setSlotsAvailables] = useState([]);

  const [slotSelected, setSlotSelected] = useState("");
  const [isSameDay, setIsSameDay] = useState(true);
  const [appointmentReason, setAppointmentReason] = useState("");
  const [patientList, setPatientList] = useState([]);
  const [fetchPatients, setFetchPatients] = useState(false);
  const [loadingInputData, setLoadingInputData] = useState(false);
  const { t, changeDirectLanguage } = useBDocTranslator();
  const [selectedLocation, setSelectedLocation] = useState({
    name: "",
    id: null,
  });

  const clearForm = () => {
    document.getElementById("appointment-form").reset();
    setEvent_type("");
    setAppointment_type("");
    setAppt_date_start(moment().format("YYYY-MM-DD"));
    setAppt_date_end("");
    setService_type("");
    setAppointmentReason("");
  };

  const [consultationCheck, setConsultationCheck] = useState(false);
  const [followupCheck, setFollowupCheck] = useState(false);

  const validateFields = () => {
    let validationResult = "";

    switch ("") {
      case event_type:
        validationResult = "Please Select an Event Type";
        break;
      case appointment_type:
        validationResult = "Please Select an Appointment Type";
        break;
      case service_type:
        validationResult = "Please Select a Service Type";
        break;
      case appointmentReason:
        validationResult = "Please fill out the Reason Field";
        break;
      default:
        validationResult = false;
        break;
    }
    return validationResult;
  };

  const handleClick = (e) => {
    const emptyFields = validateFields();
    if (emptyFields) {
      swal("Error!", t(emptyFields), "error");
      return false;
    }
    if (appt_date_end === "Invalid date") {
      swal(
        "Error!",
        t("Please Select slot time for your appointment"),
        "error"
      );
      return false;
    }
    //event_type != "" && Date.parse(appt_date_start + " " + slotSelected) - Date.now() > 0 && appointment_type != "", (typeof slotSelected === String)
    if (
      event_type != null &&
      appointment_type != null &&
      duration != null &&
      slotSelected != null &&
      appointmentReason != null
    ) {
      setLoading_page(true);
      Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/appointments/create`,
        {
          event_type,
          appointment_type_id: appointment_type,
          appt_date_start: `${appt_date_start} ${slotSelected}`,
          appt_date_end,
          appt_status: "CAPPT",
          service_type_id: service_type,
          appt_reason: appointmentReason,
          time_zone: "America.CostaRica",
          created_by_user: currentUserId,
          patient_id: patientId,
          provider_id: providerId,
          section_indicator: "PRO",
          location_id: eventType === "VIRTUAL" ? 1 : 2,
        },
        {
          headers: { Authorization: "Bearer " + jwtSession },
        }
      )
        .then((response) => {
          setLoading_page(false);
          clearForm();
          swal({
            title: t("Success! Appointment Registered"),
            text: t("Appointment Created & Confirmed successfully"),
            icon: "success",
            button: "OK",
          });
        })
        .catch((error) => {
          setLoading_page(false);
          swal("Error!", error.response?.data?.message, "error");
        });
    } else {
      swal("Warning!", t("Error, Please fill the Form"), "warning");
    }
  };

  const handleCancel = () => {
    history.goBack();
  };

  const valuetext = (value) => {
    setService_type("CON" + value);
    setDuration(value);
  };

  const handleDateEnd = () => {
    const startDateTime = `${appt_date_start} ${slotSelected}`;
    const newDate = Date.parse(startDateTime);
    const time = parseInt(duration) * 60000;
    const end = new Date(newDate + time);
    const newEnd = moment(dateToString(end)).format("yyyy-MM-DD HH:mm:ss");
    console.log("log date", { time: time, end: end, newEnd: newEnd });
    setAppt_date_end(newEnd);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: 300,
      justifyContent: "center",
    },
  }));

  const classes = useStyles();

  const eventType = [
    { title: "Virtual Consultation", value: "VIRTUAL" },
    { title: "At Office", value: "ON-SITE" },
  ];

  //Fecth Services for selected provider
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL_BASE}/services/findByUserId?provider_id=${providerId}`,
          {
            headers: {
              Authorization: `Bearer ${jwtSession}`,
            },
          }
        );

        const services = await response.json();
        if (services) {
          setService_options(Object.keys(services).map((key) => services[key]));
        }
      } catch (error) {
        console.error("Error fetching services:", error);
      }
    };

    fetchData();
  }, [service_loading]);

  //Fetching times slots availables for the selected provider
  const fetchTimesAvailables = async (provider, date) => {
    try {
      setLoadingSlots(true);
      if (selectedLocation.id === null && event_type === "ON-SITE") {
        setLoadingSlots(false);
        return swal("Error!", t("Please select a location"), "error");
      }
      // tranform the date to a ISO following this format 2021-09-01T00:00:00.00
      const dateISO = new Date(date).toISOString().split(".")[0];

      const response = await Axios.get(
        `${
          process.env.REACT_APP_API_URL_BASE
        }/appointments/findHours?providerId=${provider}&date=${dateISO}&locationType=${
          event_type === "VIRTUAL" ? 1 : selectedLocation?.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${jwtSession}`,
          },
        }
      );

      setLoadingSlots(false);

      if (!response.data.length > 0) {
        swal(
          "Sorry!",
          t("there are no places available for consultations"),
          "warning"
        );
      }

      setSlotsAvailables(response.data);
    } catch (error) {
      setLoadingSlots(false);
      setSlotsAvailables([]);
      console.error(error);
      swal("Error!", t("Booking times unavailable"), "error");
    }
  };

  const handleSelection = (e) => {
    e.preventDefault();
    e.target.classList.add("box-hour-selected");
    setSlotSelected(e.target.getAttribute("data-slot"));
    handleDateEnd();
  };

  useEffect(() => {
    changeDirectLanguage();
    const isTheSameDay = moment().format("yyyy-MM-DD") === appt_date_start;
    setIsSameDay(isTheSameDay);
  }, [appt_date_start]);

  // Effect to fetch the Patient for current Provider if any patient if present in the navigation history
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!location?.state) {
          const response = await Axios.get(
            `${process.env.REACT_APP_API_URL_BASE}/patient/findByProviderId?providerId=${providerId}&createdBy=${currentUserId}`,
            {
              headers: {
                Authorization: `Bearer ${jwtSession}`,
              },
            }
          );

          setPatientList(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [fetchPatients]);

  const hasNonVirtualLocation = locations.some(
    (location) => location.name.toUpperCase() !== "VIRTUAL"
  );

  const filterLocations = locations.filter(
    (location) => location.name.toUpperCase() !== "VIRTUAL"
  );

  return (
    <DashboardMui
      sectionTitle={t("Appointment Creation")}
      description={t(
        "In this section you can create a new Appointment for a Patient"
      )}
      icon={calendarIcon}
    >
      <div className="container d-flex justify-content-center">
        <form
          className="col-12 m-3 shadow-sm p-3 mb-5 bg-white rounded"
          id="appointment-form"
        >
          <div className="col form-group">
            <Autocomplete
              onChange={(event, newValue) => {
                setEvent_type(newValue.value);
              }}
              id="combo-box-demo"
              style={{ width: "100%" }}
              options={eventType}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("Event Type")}
                  variant="outlined"
                />
              )}
            />
          </div>
          {/* If EventType is not Vitual render a TextField with menuItems to display the options */}
          {event_type === "ON-SITE" && (
            <div className="col form-group">
              <TextField
                id="outlined-select-location"
                select
                label={t("Location")}
                value={selectedLocation?.name}
                onChange={(event) => {
                  setSelectedLocation({
                    name: event.target.value,
                    id: filterLocations.find(
                      (location) => location.name === event.target.value
                    ).id,
                  });
                }}
                helperText={t("Please select the location")}
                variant="outlined"
                style={{ width: "100%" }}
              >
                {filterLocations.map((option) => (
                  <option key={option.id} value={option.name}>
                    {option.name}
                  </option>
                ))}
              </TextField>
            </div>
          )}
          {/* Warning for on site appointments */}
          {event_type === "ON-SITE" && hasNonVirtualLocation && (
            <div className="col m-1">
              <InformationComp
                title={t("Location")}
                description={`${t(
                  "Your patient will see you at the physical location at"
                )}: ${filterLocations[0]?.name}, ${t("located at")} ${
                  filterLocations[0]?.address
                }`}
                severity={"warning"}
              />
            </div>
          )}

          {/* Checks for Appointment Type */}
          <div className="col form-group justify-content-start">
            <label>{t("Type of Appointment")}</label>
            <div className="row">
              <Paper elevation={1} className="mx-3">
                <div className="form-check p-2 ml-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="consultationCheck"
                    id="consultationCheck"
                    value="option1"
                    checked={consultationCheck}
                    onClick={(e) => {
                      setAppointment_type("CONS");
                      setConsultationCheck(!consultationCheck);
                      setFollowupCheck(false);
                    }}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="consultationCheck"
                  >
                    {t("Consultation")}
                  </label>
                </div>
              </Paper>
              <Paper elevation={1} className="mx-2">
                <div className="form-check p-2 ml-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="followCheck"
                    id="followCheck"
                    value="option2"
                    checked={followupCheck}
                    onClick={(e) => {
                      setAppointment_type("FOLUP");
                      setFollowupCheck(!followupCheck);
                      setConsultationCheck(false);
                    }}
                  />
                  <label className="form-check-label" htmlFor="followCheck">
                    {t("Follow Up")}
                  </label>
                </div>
              </Paper>
            </div>
          </div>

          {/* Input Autocomplete to Select the Patient  */}
          {!location?.state && (
            <div className="col form-group mt-3">
              <Autocomplete
                autoComplete="off"
                //inputValue={patient_email}
                value={patientId}
                //defaultValue={patient_email || ""}
                onChange={(event, newValue) => {
                  setPatientId(newValue.id);
                }}
                /* onInputChange={(e, newValue) => {
                setPatient_email(newValue.email);
              }} */
                id="patientInput"
                style={{ width: "50%" }}
                //open={open}
                onOpen={() => {
                  setFetchPatients(true);
                }}
                onClose={() => {
                  setLoadingInputData(false);
                }}
                // getOptionSelected={(option, value) =>
                //   option.email === value.email
                // }
                getOptionLabel={(option) =>
                  typeof option === "string"
                    ? option
                    : `${option.firstName} ${option.lastname}`
                }
                options={patientList}
                loading={loadingInputData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("Patient Fullname")}
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingInputData ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </div>
          )}

          <div className="col form-group p-3">
            <TextField
              id="outlined-select-service-type"
              select
              label={t("Service")}
              defaultValue={t("Select Service")}
              value={service_type_name}
              onChange={(event, newValue) => {
                setService_type_name(event.target.value);
                setService_type(event.target.value);
                setDuration(newValue.props.duration);
              }}
              helperText={t("Please select the type of service")}
              variant="outlined"
              style={{ width: "100%" }}
            >
              {service_options &&
                service_options.map((option) => (
                  <option
                    duration={option.duration_minute}
                    key={option.code}
                    value={option.id}
                    name={option.name}
                  >
                    {option.name + " - USD $" + option.price}
                  </option>
                ))}
            </TextField>
          </div>

          {/* Appointment Reason */}
          <div className="row d-flex justify-content-center align-items-baseline pb-3">
            <textarea
              cols="30"
              rows="5"
              maxLength="200"
              className="col-md-8 col-sm-12 mt-1 input-condition-detail shadow-sm p-3 mb-5 bg-white rounded"
              onChange={(e) => setAppointmentReason(e.target.value)}
              placeholder={t("Appointment Reason")}
              value={appointmentReason}
            ></textarea>
          </div>

          <div className="row d-flex justify-content-center align-items-baseline pb-3">
            <TextField
              id="datetime-local"
              label={t("Pick a date")}
              type="date"
              // defaultValue={appt_date_start}
              value={appt_date_start}
              className={classes.textField}
              onChange={(event) => {
                setAppt_date_start(event.target.value);
                // handleDateEnd();
                fetchTimesAvailables(providerId, event.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: moment().format("YYYY-MM-DD"),
              }}
            />
          </div>

          {/* Time Slots */}
          <div>
            {loadingSlots ? (
              <LoadingSpin type="spokes" color="#1d3557" />
            ) : (
              <TimeSlots
                data={slotsAvailables}
                handleSelection={handleSelection}
                isSameDay={isSameDay}
              />
            )}
          </div>

          <div className="col d-flex justify-content-center">
            <div className="p-3">
              <Button
                variant="contained"
                color="primary"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={handleClick}
              >
                {t("Save")}
              </Button>
            </div>

            <div className="p-3">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
                onClick={handleCancel}
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
          {loading_page && (
            <div className="d-flex justify-content-center mt-3">
              <LoadingSpin type="spokes" color="#1d3557" />
            </div>
          )}
        </form>
      </div>
    </DashboardMui>
  );
}
