import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import stethoscope from "../../images/stethoscope.svg";
import useUserState from "../hooks/useUserState";
import Axios from "axios";
import HealthMeasurementDialog from "./HealthMeasurementDialog";
import useBDocTranslator from "../hooks/useBDocTranslator";
import { Button, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    overflowY: "auto",
    backgroundColor: "whitesmoke",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "100%",
    maxWidth: "900px",
    height: "400px",
  },
}));

export default function BackgroundForm(props) {
  const classes = useStyles();
  const [bg, setBg] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const userLocalState = useUserState();
  const { t } = useBDocTranslator();

  //Method creates a new health measurement record in the database
  const createBg = () => {
    if (!bg) return;

    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/bg/create`,
      {
        content: bg,
        type: props.type,
        patient_id: props.patientId,
      },
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((res) => {
        props.handleRefreshData();
        props.handleClose();
      })
      .catch((error) => {
        console.log(error);
        setResponseMessage("Error " + error);
      });
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        // className="flex items-center justify-center overflow-y-auto"
        open={props.open}
        onClose={() => {
          props.handleClose();
          setResponseMessage("");
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          {
            <div className={classes.paper}>
              <div className="row col-md-12">
                <div className="col-md-8">
                  <h3>{t(props.title)}</h3>
                </div>
                <div className="col-12 mt-4 mb-2">
                  <TextField
                    id="systolic"
                    label={t(props.title)}
                    variant="outlined"
                    className="w-100"
                    value={bg}
                    onChange={(e) => setBg(e.target.value)}
                    required={true}
                  />
                </div>
              </div>
              <div className="row mt-2 mx-1">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={createBg}
                  className="col-md-6"
                >
                  {t("Save")}
                </Button>
                <Button
                  variant="contained"
                  color="default"
                  onClick={props.handleClose}
                  className="col-md-6"
                >
                  {t("Cancel")}
                </Button>
              </div>
            </div>
          }
        </Fade>
      </Modal>
    </div>
  );
}
