import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import DashboardPatient from "../dashboard/DashboardPatient";
import VideoFrame from "../videoconference/VideoFrame";
import { useMediaQuery } from "@material-ui/core";
import Button from "@material-ui/core/Button";

export default function LobbyPatient() {
  const location = useLocation();
  useEffect(() => {
    console.log(location.state);
  }, []);

  const {
    event_code,
    patient_fullname,
    password,
    providerFullname,
    appointmentId,
    profileImage,
    rating,
  } = location.state;

  return (
    <div className="col-md-12 col-sm-12">
      <VideoFrame
        eventCode={event_code}
        displayName={patient_fullname}
        providerFullname={providerFullname}
        appointmentId={appointmentId}
        profileImage={profileImage}
        rating={rating}
        // password={password}
      />
    </div>
  );
}

/**

<DashboardPatient>
      <div className="col-md-12 col-sm-12">
        {isMobile ? (
          <div className="text-center">
            <h3>Unirme a la Cita 📲</h3>
            <p>
              Al dar click en el boton <b>Unirme a la Cita</b>, sera
              redireccionado a otra pestana, al finalizar la consulta podra
              volver a esta pagina para continuar usando la plataforma.
            </p>
            <Button
              variant="contained"
              color="primary"
              href={`https://meets.hcgconsulting.net/${event_code}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Unirme a la Cita
            </Button>
          </div>
        ) : (
          <VideoFrame
            eventCode={event_code}
            displayName={patient_fullname}
            providerFullname={providerFullname}
            appointmentId={appointmentId}
            profileImage={profileImage}
            rating={rating}
            // password={password}
          />
        )}
      </div>
    </DashboardPatient>


 */
