import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import useBDocTranslator from "../hooks/useBDocTranslator";
import { MenuItem, TextField, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Axios from "axios";
import useUserState from "../hooks/useUserState";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { prescTypes, presTypeObjLabel } from "./PrenscriptionDialog";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function PrescriptionEHR(props) {
  const [pharmacies, setPharmacies] = useState([]);
  const [prescType, setPrescType] = useState();
  const { t } = useBDocTranslator();
  const userLocalState = useUserState();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/pharmacy/findAll`, {
      headers: { Authorization: "Bearer " + userLocalState[0].jwt },
    })
      .then((response) => {
        setPharmacies(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSave = () => {
    props.handleSave();
    setSnackbarMessage(t("Prescription created successfully"));
    setSnackbarSeverity("success");
    setOpenSnackbar(true);
    props.handleRefreshData();
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <div
      style={{ height: "fit-content" }}
      className="shadow p-3 m-2 bg-white rounded-lg col-md-12 col-sm-12 position-relative prescription-card d-flex flex-column justify-content-between"
    >
      <div className="col-sm-12 col-md-12 mt-1 text-center">
        <p className="font-weight-bold">{props.responseMessage}</p>
      </div>
      {/* Prescription types */}
      <TextField
        id="prescription-types"
        select
        fullWidth
        variant="outlined"
        margin="normal"
        placeholder={t("Select Prescription Type")}
        label={t("Prescription Type")}
        onChange={(e) => {
          setPrescType(e.target.value);
          props?.setPharmacy("select");
        }}
        value={prescType || t("select")}
      >
        <MenuItem value="select">{t("Select")}</MenuItem>
        {prescTypes.map((item, idx) => (
          <MenuItem key={idx} value={item.value}>
            {t(item.label)}
          </MenuItem>
        ))}
      </TextField>
      {/* Pharmacies */}
      {!!prescType && prescType !== "select" && (
        <>
          <TextField
            id="pharmacy-list"
            select
            fullWidth
            variant="outlined"
            margin="normal"
            label={prescType ? t(presTypeObjLabel[prescType]) : ""}
            onChange={(e) => props?.setPharmacy(e.target.value)}
            value={props?.pharmacy || t("select")}
          >
            <MenuItem value="select">{t("Select")}</MenuItem>
            {pharmacies?.length > 0 &&
              pharmacies
                .filter((i) => i.type === prescType)
                .map((item, idx) => (
                  <MenuItem key={idx} value={item.id}>
                    {`${item.name} - ${item.city}`}
                  </MenuItem>
                ))}
          </TextField>
          {/* Dispense To as a date selection */}
          <div className="form-group">
            <label htmlFor="dispense-to">{t("Valida hasta")}</label>
            <input
              type="date"
              className="form-control"
              value={props.dispenseTo}
              onChange={(e) => props.setDispenseTo(e.target.value)}
            />
          </div>
          <div className="pb-5">
            <ReactQuill
              theme="snow"
              value={props.prescriptionValue}
              onChange={props.handleNotes}
              style={{ height: "200px" }}
            />
          </div>
          <div className="mt-2">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSave}
              className="col-md-6"
              disabled={
                !props.pharmacy || !props.dispenseTo || !props.prescriptionValue
              }
            >
              {t("Save")}
            </Button>
          </div>
        </>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
