import { Grid } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
function InformationComp({ title, description, style, severity }) {
  return (
    <Grid style={{ style }}>
      <Alert severity={severity || "info"}>
        <AlertTitle>{title}</AlertTitle>
        {description}
      </Alert>
    </Grid>
  );
}
export default InformationComp;
