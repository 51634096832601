import React, { useState, useEffect, useMemo } from "react";
import swal from "sweetalert";
import Axios from "axios";
import { useParams, useHistory } from "react-router-dom";
import { Button, TextField } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";

import UseUserState from "../hooks/useUserState";
import DashboardPatient from "../dashboard/DashboardPatient";
import { useLocation } from "react-router-dom";
import CheckoutResume from "./CheckoutResume";
import HeadingResume from "../widgets/HeadingResume";
import mobilePaymentIcon from "../../images/mobile-payment.svg";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import PaypalButton from "./PaypalButton";
import useBDocTranslator from "../hooks/useBDocTranslator";
import CheckoutStripeWrapper from "./CheckoutStripeWrapper";
import { handlePaymentSuccess } from "../../services/paymentService";
import CheckoutGreenpay from "./CheckoutGreenpay";
import CheckoutLogic from "./CheckoutLogic";
import PaymentFormModal from "./PaymentFormModal";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Checkout(props) {
  const location = useLocation();
  let history = useHistory();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { handlePayment } = CheckoutLogic();
  const [openModal, setOpenModal] = useState(false);

  //jwt received in the email an placed on uri
  let { id } = useParams();
  const [redirectAfterPay, setRedirectAfertPay] = useState(false);
  const { t, changeDirectLanguage } = useBDocTranslator();
  const [clientSecret, setClientSecret] = useState(null);
  const memClientSecret = useMemo(() => clientSecret, [clientSecret]);
  const [paymentGateway, setPaymentGateway] = useState(null);
  const [cardCvv, setCardCvv] = useState("");
  const [cardExp, setCardExp] = useState("");
  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");

  const [errors, setErrors] = useState([]);
  const userState = UseUserState();
  const {
    appointmentId,
    patient_fullname,
    service_name,
    service_duration,
    price,
  } = location.state;

  // Get the default payment gateway from configuration
  const paymentPreference = async () => {
    try {
      const preference = await Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/configuration/findConfigByprefix`,
        {
          prefixConfig: "default_payment_gateway",
        },
        {
          headers: { Authorization: "Bearer " + userState[0].jwt },
        }
      );

      return preference.data.length > 0 ? preference.data[0] : null;
    } catch (error) {
      console.log("error", error);
    }
  };

  //this method manage the backdrop status
  const handleClose = () => {
    setOpen(false);
  };

  //Handle the response on successfuly payment with paypal
  const handlePaypalSuccess = (response) => {
    handlePaymentSuccess(
      response,
      appointmentId,
      userState,
      setOpen,
      setErrors,
      setRedirectAfertPay,
      t
    );
  };

  useEffect(() => {
    changeDirectLanguage();
    if (redirectAfterPay) {
      setTimeout(() => {
        history.push({
          pathname: "/patient/appointments",
        });
      }, 3000);
    }
  }, [redirectAfterPay]);

  const createPaymentIntent = async () => {
    try {
      const createIntentResponse = await Axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/payments/create-payment-intent`,
        {
          items: [
            {
              id: appointmentId.toString(),
              amount: price * 100, // for $14 => 1400
            },
          ],
        },
        {
          headers: { Authorization: "Bearer " + userState[0].jwt },
        }
      );

      console.log("createIntentResponse", createIntentResponse);

      return createIntentResponse.data;
    } catch (e) {
      console.log("Create Intent Error", e);
    }
  };

  // Stripe create intent payment
  useEffect(() => {
    setOpen(true);
    if (memClientSecret !== null) {
      setOpen(false);
      return;
    }

    paymentPreference().then((res) => {
      setPaymentGateway(res?.config_value);
      if (res.config_value === "stripe") {
        createPaymentIntent()
          .then((res) => setClientSecret(res.clientSecret))
          .catch((errors) => {
            setOpen(false);
            console.log("createIntentErr", errors);
          });
      } else {
        setOpen(false);
      }
    });

    // setOpen(false);
  }, []);

  const handleGPPayment = async () => {
    setOpen(true);
    const data = {
      appointmentId,
      cardCvv,
      cardExp,
      cardName,
      cardNumber,
      price,
      service_name,
      userState,
    };

    const response = await handlePayment(data);
    setOpen(false);
    if (response) {
      setRedirectAfertPay(true);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const SINPE_INFO = {
    phone: process.env.REACT_APP_SINPE_PHONE_NUMBER,
    holder: process.env.REACT_APP_SINPE_HOLDER_NAME,
    description: `Pago de ${patient_fullname} CITA: ${appointmentId}`,
  };

  return (
    <DashboardPatient>
      {/* Element that block the screen when request its happen */}
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <HeadingResume
        title={t("Checkout & Payment")}
        description={t(
          "Once you make the payment we'll notify to Healthcare Provider and we'll send you invoice copy"
        )}
        icon={mobilePaymentIcon}
      />
      <div>
        <div className="row m-3 p-3 shadow w-30 rounded-lg-custom bg-white">
          <CheckoutResume
            serviceType={service_name}
            serviceDuration={service_duration}
            price={price}
            sinpeInfo={SINPE_INFO}
          />
          <div className="col-md-6 col-sm-12 pl-md-3">
            {/* Stripe Payment Component */}
            {paymentGateway && paymentGateway === "stripe" && (
              <CheckoutStripeWrapper clientSecret={memClientSecret} />
            )}
            {paymentGateway && paymentGateway === "gp" && (
              <CheckoutGreenpay
                cardCvv={cardCvv}
                setCardCvv={setCardCvv}
                cardExp={cardExp}
                setCardExp={setCardExp}
                cardName={cardName}
                setCardName={setCardName}
                cardNumber={cardNumber}
                setCardNumber={setCardNumber}
                handlePayment={handleGPPayment}
              />
            )}
            {/* Divider with a - OR - and a button to Report Payment */}
            <div className="divider-container">
              <hr className="divider-line" />
              <span className="divider-text">O</span>
            </div>
            <Button
              variant="contained"
              fullWidth
              onClick={handleOpenModal}
              startIcon={<SaveIcon />}
            >
              {t("Reportar Pago")}
            </Button>
            {/* Paypal Buttons */}
            {/* <div className="mt-2 paypal-buttons text-center w-100">
              <div className="m-2">
                <span>{t("Secure Payments with PayPal")}</span>
              </div>
              <PaypalButton
                handlePaypalSuccess={handlePaypalSuccess}
                backdropOpen={setOpen}
                amount={`${price}.00`}
              />
            </div> */}
            {/* <div className="sq-error-message">
              {errors && (
                <li key={`sq-error-${errors.errorMessage}`}>
                  {errors.errorMessage}
                </li>
              )}
            </div> */}
          </div>
          {/* Modal */}
          <PaymentFormModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            appointmentId={appointmentId}
            patientId={userState[0]?.extendedData?.id}
          />
        </div>
      </div>
    </DashboardPatient>
  );
}
