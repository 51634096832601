import React, { useEffect } from "react";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core";
import useBDocTranslator from "../hooks/useBDocTranslator";
import FileUpload from "../utils/FileUpload";

const useStyles = makeStyles((theme) => ({
  noCancelWrapperButton: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default function EHRFilesDialog({
  handleClose = () => {},
  successFlag,
  handleUploadEHRFile,
  uploadedFiles,
  setUploadedFiles,
  noCancel = false,
  labelSaveButton,
}) {
  const { t } = useBDocTranslator();
  const classes = useStyles();
  // const [uploadedFiles, setUploadedFiles] = useState([]);

  useEffect(() => {
    if (successFlag) {
      setUploadedFiles([]);
    }
  }, [successFlag]);

  return (
    <div
      className={`shadow m-2 bg-white rounded-lg col-md-12 col-sm-12 position-relative dependants-card ${
        noCancel ? "p-2" : ""
      }`}
    >
      <div className="col mt-3">
        <p className="lead">
          {t(
            "En esta seccion puede subir documentos clinicos a su expediente."
          )}
        </p>

        <div className="mt-2 mx-1 justify-center">
          <FileUpload
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
          />
        </div>
      </div>

      {/* Action buttons */}
      <div
        className={`row mt-2 mx-1 mb-3  ${
          noCancel ? classes.noCancelWrapperButton : ""
        }`}
      >
        {!noCancel && (
          <Button
            variant="contained"
            color="default"
            onClick={handleClose}
            className="col-md-6"
          >
            {t("Cancel")}
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          style={{ width: noCancel ? "100%" : "" }}
          onClick={() => handleUploadEHRFile()}
          className="col-md-6"
          disabled={uploadedFiles.length === 0 || successFlag}
        >
          {labelSaveButton || t("Save")}
        </Button>
      </div>
    </div>
  );
}
