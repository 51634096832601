import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Typography, Grid, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";
import DashboardMui from "../../dashboard/DashboardMui";
import AssessmentIcon from "@material-ui/icons/Assessment";
import BarChartIcon from "@material-ui/icons/BarChart";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import ReceiptIcon from "@material-ui/icons/Receipt";
import CharSquare from "../../../images/chart-square.svg";
import useBDocTranslator from "../../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
  card: {
    minWidth: 275,
    margin: theme.spacing(2),
    textDecoration: "none",
    color: theme.palette.text.primary,
    transition: "0.3s",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "200px",
    "&:hover": {
      transform: "scale(1.00)",
      boxShadow: theme.shadows[2],
    },
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
  },
  icon: {
    fontSize: "40px",
    marginBottom: theme.spacing(1),
  },
  cardContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: theme.spacing(2),
    margin: 10,
  },
}));

const ReportsSelection = () => {
  const classes = useStyles();
  const { t, changeDirectLanguage } = useBDocTranslator();
  const reports = [
    {
      title: "Ingresos por citas",
      path: "/admin/report/appointments/income",
      icon: <MonetizationOnIcon className={classes.icon} />,
    },
    {
      title: "Reporte de pacientes",
      path: "/admin/report/patients",
      icon: <ReceiptIcon className={classes.icon} />,
    },
    {
      title: "Reporte de citas",
      path: "/admin/report/appointment",
      icon: <AssessmentIcon className={classes.icon} />,
    },
  ];

  useEffect(() => {
    changeDirectLanguage("es");
  }, []);

  return (
    <DashboardMui
      sectionTitle={t("Reports Selection")}
      icon={CharSquare}
      description={t(
        "View and manage various reports related to appointments, patients, and more."
      )}
    >
      <Paper elevation={3} className={classes.root}>
        <Grid container spacing={3}>
          {reports.map((report) => (
            <div key={report.title} className={classes.cardContainer}>
              <Link to={report.path} className={classes.card}>
                <Card variant="outlined">
                  <CardContent>
                    {report.icon}
                    <Typography variant="h6" className={classes.title}>
                      {report.title}
                    </Typography>
                    <Typography color="textSecondary">
                      {t("Click to view report")}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </div>
          ))}
        </Grid>
      </Paper>
    </DashboardMui>
  );
};

export default ReportsSelection;
