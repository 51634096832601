import React from "react";

export default function HeadingResume(props) {
  return (
    <>
      <article className="mt-3 mb-3 p-3 shadow w-30 rounded-sm-custom bg-white">
        <h2 className="font-weight-bold">
          {props.title + " "}
          {props.icon && (
            <span>
              <img className="sunshine-icon" src={props.icon} alt="svg-icon" />
            </span>
          )}
        </h2>
        <p>{props.description}</p>
      </article>
    </>
  );
}
