import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";
import "../../styles/consultText.css";
import useBDocTranslator from "../hooks/useBDocTranslator";
import SelectCIE from "../utils/SelectCIE";
import { Checkbox, FormControlLabel } from "@material-ui/core";

export default function SearchModule(props) {
  //const [value, setValue] = useState([]);
  const [country, setCountry] = useState(null);
  const [specialty, setSpecialty] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const { t } = useBDocTranslator();

  const clearAll = () => {
    setRefresh(true);
    setCountry(null);
    setSpecialty(null);
    props.setProvider("");
  };

  useEffect(() => {
    if (props.preselectedProvider.name !== "") {
      props.setProvider(props.preselectedProvider);
    }
    if (refresh || props.cancel) {
      setRefresh(false);
      props.setCancel(false);
    }
    // else {
    //   if (country || specialty || Array.isArray(specialty) || props.provider) {
    //     // props.handleSearch(country, specialty, props.provider);
    //   } else {
    //     // props.handleRefresh();
    //   }
    // }
  }, [country, specialty, props.provider]);

  const useStyles = makeStyles((theme) => ({
    cieDiv: {
      width: "300px",
    },
    cieWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "end",
      padding: "0 1.6em 0 1.6em",
      alignItems: "center",
    },
  }));

  const classes = useStyles();

  return (
    <div className="p-3 mb-4 col-sm-12 ">
      <div className="flex-column">
        {/* Search by Country */}
        {/* <Autocomplete
        id="search-by-country"
        onChange={(e, newValue) => setCountry(newValue)}
        options={countries}
        getOptionLabel={(option) => option.name}
        style={{ width: 300 }}
        renderInput={(params) => (
            <TextField
            {...params}
            label="Filter by Country"
            variant="outlined"
            className="bg-white"
            />
        )}
        /> */}
        {/* Search By Specialty */}

        {/* Search by Providers name */}
        {props.type === "pro" && (
          <div className={classes.cieWrapper}>
            <FormControlLabel
              className="my-0 md:mr-4"
              control={
                <Checkbox
                  checked={props.isSelectAll}
                  onChange={(e) => props.onClickSelectAll(e)}
                  color="primary"
                />
              }
              label={t("Select All Patients")}
            />
            <div className={classes.cieDiv}>
              <SelectCIE
                setCie={props.setCie}
                label={t("Filter by CIE Code or description")}
              />
            </div>
          </div>
        )}
        <div className={"d-flex justify-content-center "}>
          <Autocomplete
            multiple={props.type === "pro"}
            className="m-4"
            id="search-by-name"
            onChange={(e, newValue) => {
              props.setProvider(newValue);
            }}
            options={props.options}
            getOptionLabel={(option) =>
              props.type === "pat"
                ? option.firstName +
                    " " +
                    option.lastname +
                    " - " +
                    option?.medical_speciality || ""
                : option.firstName + " " + option.lastname
            }
            style={{ width: 300 }}
            value={
              props.type === "pro" ? props?.provider || [] : props?.provider
            }
            // disabled
            renderInput={(params) => (
              <TextField
                {...params}
                label={
                  props.type === "pat"
                    ? t("Select Providers")
                    : t("Select Patients")
                }
                variant="outlined"
                className="bg-white"
              />
            )}
          />
        </div>
        {/* <IconButton 
        onClick={() => {clearAll();}} 
        variant="contained" 
        color="primary" 
        aria-label="refresh">
        <RefreshIcon />
        </IconButton> */}
      </div>
      {/* <div className="d-flex justify-content-end m-3">
        <Button
        onClick={() => props.handleSearch(country, specialty, provider)}
        variant="contained"
        color="primary"
        >
        Search
        </Button>
    </div> */}
    </div>
  );
}

const countries = [
  { name: "United States", code: "USA" },
  { name: "Costa Rica", code: "CRC" },
  { name: "Bahamas", code: "BHA" },
  { name: "Jamaica", code: "JAM" },
  { name: "Nicaragua", code: "NIC" },
];
