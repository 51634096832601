import React from "react";
import FullCalendar, { padStart } from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";

export default function PlannerCalendar({
  events,
  setSlots,
  handleUnSelect,
  getDayName,
}) {
  return (
    <div className="col-md-8">
      <FullCalendar
        plugins={[interactionPlugin, timeGridPlugin, dayGridPlugin]}
        firstDay={1}
        initialView="timeGridWeek"
        selectable="true"
        themeSystem="bootstrap"
        eventClick={(arg) => {
          handleUnSelect(arg.event.id);
        }}
        events={events}
        selectOverlap={(evn) => {
          return false;
        }}
        dayHeaderFormat={{
          weekday: "long",
        }}
        dayHeaderContent={(arg) => getDayName(arg.date)}
        select={(info) => {
          const values = [
            //...slots, when we will save builk data we have to uncomment this rest line
            {
              start: info.startStr,
              end: info.endStr,
              formattedTimes: {
                week_day: info.start.getDay(),
                time_start:
                  padStart(info.start.getHours(), 2) +
                  ":" +
                  info.start.getMinutes().toString().padEnd(2, "0"),
                time_end:
                  padStart(info.end.getHours(), 2) +
                  ":" +
                  info.end.getMinutes().toString().padEnd(2, "0"),
              },
            },
          ];
          setSlots(values);
          // setEvents([...events, values[0]]);
        }}
        headerToolbar={{
          left: "",
          right: "",
        }}
        // unselect={(e, view) => {
        //   console.log(e.view.getCurrentData());
        // }}
      />
    </div>
  );
}
