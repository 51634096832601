import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useAffiliateUser, useCheckout } from "../../services/services";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import { setAffiliateId } from "../../actions";
import { useDispatch } from "react-redux";
import useUserState from "../hooks/useUserState";
import { FaWhatsappSquare } from "react-icons/fa";
import { Modal } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import swal from "sweetalert";
import axios from "axios";
import CheckoutLogic from "../payments/CheckoutLogic";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1, 0),
    maxWidth: 380,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.grey[500],
    borderRadius: 0,
  },
  cardTitle: {
    fontSize: "1.5rem",
    marginBottom: theme.spacing(2),
  },
  cardDescription: {
    fontSize: "1rem",
    marginBottom: theme.spacing(2),
  },
  logoIcon: {
    marginRight: theme.spacing(1),
  },
  price: {
    fontSize: "1.5rem",
  },
  actionButton: {
    marginTop: theme.spacing(2),
  },
  divider: {
    height: "1px",
    background: theme.palette.grey[500],
    marginBottom: theme.spacing(3),
  },
  banner: {
    backgroundColor: theme.palette.error.main,
    color: "white",
    padding: theme.spacing(1),
    width: "fit-content",
    position: "relative",
    top: 0,
    left: "50%",
    transform: "translate(20%, -20%)",
    zIndex: 1,
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
  },
  bannerEmpty: {
    marginBottom: theme.spacing(5),
    width: "fit-content",
    position: "relative",
    top: 0,
    left: "50%",
    transform: "translate(20%, -20%)",
    zIndex: 1,
    boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
  },
  buyContainer: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(2), // Adjust the space between elements
  },

  underlineText: {
    textDecoration: "underline",
    fontSize: "10px",
    color: theme.palette.grey[500],
    marginTop: theme.spacing(1),
  },

  whatsappIcon: {
    color: "green",
    fontSize: "28px",
    transition: "all 0.3s",
    "&:hover": {
      transform: "scale(1.2)",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const PlanCard = ({
  id,
  name,
  description,
  price,
  beneficiary_qty,
  planAffiliated,
  affiliationId,
}) => {
  const classes = useStyles();
  const [userData] = useUserState();
  const JWT = userData?.jwt;

  const { affiliateUser } = useAffiliateUser(JWT);
  const { createSubscription } = useCheckout();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState("");

  const { handleSubcription } = CheckoutLogic();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const dataToAffiliate = {
    patientId: userData?.patientId,
    planId: id,
    status: "pending",
    affiliation_dt_start: new Date(),
  };
  // TODO: USAR NUEVOS ENDPOINTS DEL FLUJO DE GREENPAY
  const handleButtonClick = async () => {
    setLoading(true);
    const afiliate = await affiliateUser(dataToAffiliate);
    const afiliateId = afiliate?.id;
    const parent_id = afiliate?.patient_id;
    // const subscription = await createSubscription(afiliateId);
    const subReqData = buildSubscriptionData(afiliateId);
    const subscriptionUrl = await handleSubcription(subReqData);
    const data = {
      afiliateId,
      parent_id,
      subscription: "greenpay-payment",
    };
    dispatch(setAffiliateId(data));
    console.log("subscriptionUrl", subscriptionUrl);
    setLoading(false);
    // persist the affiliation id in the session storage to be used in verification callback screen
    await window.sessionStorage.setItem("affId", afiliateId);
    window.location = subscriptionUrl;
  };

  const buildSubscriptionData = (affiliationId) => {
    return {
      affiliationId: affiliationId,
      parent_id: userData?.patientId,
      price: Number(price),
      planName: name,
    };
  };

  // request to cancel affiliation, opens a modal to confirm and capture the reason
  const handleCancel = async () => {
    // if the reason is empty display a toast message
    if (cancelReason === "") {
      swal("Por favor ingrese una razón para cancelar la afiliación", {
        icon: "warning",
      });
      return;
    }

    // if the reason is not empty, proceed to cancel the affiliation
    const cancelationData = {
      affiliation_id: affiliationId,
      cancelation_reason: cancelReason,
    };
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL_BASE}/affiliations/cancelationRequest`,
        cancelationData,
        {
          headers: {
            Authorization: `Bearer ${JWT}`,
          },
        }
      );
      console.log(response);
      swal("Solicitud de cancelacion enviada exitosamente", {
        icon: "success",
      });
      setOpen(false);
    } catch (error) {
      console.log(error);
      swal("Error al cancelar la afiliación", {
        icon: "error",
      });
    }
  };

  return (
    <Card className={classes.card}>
      {planAffiliated ? (
        <div className={classes.banner}>
          <Typography variant="body3">Plan afiliado</Typography>
        </div>
      ) : (
        <div className={classes.bannerEmpty}></div>
      )}
      <CardContent>
        <Typography variant="h5" className={classes.cardTitle}>
          {name}
        </Typography>
        <div className={classes.divider}></div>
        <Typography variant="body1" className={classes.cardDescription}>
          Aplica para el titular y {beneficiary_qty} beneficiarios.
        </Typography>
        <div>
          <div className={classes.logoIcon}>
            <LocalHospitalIcon color="primary" fontSize="large" />
          </div>
          <Typography variant="body1" className={"mt-2"}>
            {description}
          </Typography>
        </div>
      </CardContent>
      <CardContent>
        <div>
          <Typography variant="h6" className={classes.price}>
            Aplica para el titular y {beneficiary_qty} beneficiarios.
          </Typography>
          <Typography variant="body1" className={classes.price}>
            {`$${price}`}
          </Typography>
        </div>
        <div className={classes.actionButton}>
          <div className={classes.buyContainer}>
            {!planAffiliated && (
              <Button
                onClick={handleButtonClick}
                variant="contained"
                color="primary"
                disabled={planAffiliated || loading}
              >
                {loading ? "Procesando..." : "Comprar"}
              </Button>
            )}
            {planAffiliated && (
              //   Cancel button
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpen(true)}
              >
                Cancelar Afiliacion
              </Button>
            )}
            {planAffiliated && (
              <>
                <span className={classes.underlineText}>
                  Contact Support for information
                </span>
                <a
                  href={`https://wa.me/${process.env.REACT_APP_WHATSAPP_NUMBER}`}
                  target="_blank"
                  className={classes.whatsappIcon}
                  rel="noreferrer"
                >
                  <FaWhatsappSquare />
                </a>
              </>
            )}
          </div>
        </div>
        {/* Modal to capture the reason and confirm the cancelation request */}

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          className={classes.modal}
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <div className="bg-white p-5 rounded">
            <h1 className="mt-2">Confirmar cancelación</h1>
            <p>¿Estás seguro de cancelar la afiliación?</p>
            <textarea
              name="cancelation-reason"
              id="cancelation-reason"
              rows="10"
              placeholder="Escribe la razón de la cancelación"
              className="mb-3 w-100 h-60-custom form-control col-md-12"
              onChange={(e) => setCancelReason(e.target.value)}
              value={cancelReason}
            ></textarea>
            <div className="d-flex justify-content-around">
              <Button
                variant="contained"
                color="primary"
                onClick={handleCancel}
                className="col-md-5"
              >
                Confirmar
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleClose}
                className="col-md-5"
              >
                Cancelar
              </Button>
            </div>
          </div>
        </Modal>
      </CardContent>
    </Card>
  );
};

export default PlanCard;
