import React, { useState } from "react";
import { FormControlLabel, Button, Checkbox } from "@material-ui/core";
import useBDocTranslator from "../hooks/useBDocTranslator";
import SelectAppt from "../providers/SelectAppt";

export default function ConsultationNotesDialog(props) {
  const [checked, setChecked] = useState(true);
  const { t } = useBDocTranslator();

  const handleChange = (event) => {
    setChecked(event.target.checked);
    props.handleVisibility(!checked);
  };

  return (
    <div
      style={{ width: "700px" }}
      className="shadow p-3 m-2 bg-white rounded-lg col-md-12 col-sm-12 position-relative"
    >
      <SelectAppt
        onChange={(v) => props.setApptSelected(v)}
        value={props.apptSelected}
        patientId={props.patientId}
      />
      <textarea
        name="consultation-notes-txt"
        id="consultation-notes-txt"
        rows="10"
        placeholder={t(
          "Consultation Notes Here, click to Pen button if you want minimize this section (the text entered will not be lost)"
        )}
        className="mb-3 w-100 h-60-custom form-control col-md-12"
        onChange={props.handleConsultationNotes}
        value={props.consultationNotesValue}
      ></textarea>
      <div
        style={{
          width: "700px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={props.handleSaveConsultationNotes}
          className="col-md-6"
          size="small"
          disabled={props.disabled}
        >
          {t("Save")}
        </Button>
        <div className="pl-3">
          <FormControlLabel
            control={<Checkbox checked={checked} onChange={handleChange} />}
            label="visible"
          />
        </div>
      </div>
    </div>
  );
}
