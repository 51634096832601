import React, { forwardRef, useMemo, useState } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import HealthImage from "../../images/healthcondition.svg";
import NotesImages from "../../images/notes-image.svg";
import useBDocTranslator from "../hooks/useBDocTranslator";
import HealthConditionModalForm from "./HealthConditionModalForm";
import swal from "sweetalert";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function HealthCondition(props) {
  const { t } = useBDocTranslator();
  const condition = useMemo(
    () =>
      props?.data.filter((item) => item.segment_name === props.conditionType),
    [props?.data]
  );

  const [open, setOpen] = useState(false);
  const [currentRow, setCurrentRow] = useState({});

  const columns = useMemo(() => {
    if (props.cie) {
      return [
        { title: "Id", field: "id", maxWidth: "40px" },
        { title: t("Notes"), field: "detail" },
        { title: "CIE", field: "cie" },
        { title: t("Condition Time"), field: "duration" },
      ];
    }
    return [
      { title: "Id", field: "id", maxWidth: "40px" },
      { title: t("Notes"), field: "detail" },
      { title: t("Register date"), field: "created_at", type: "datetime" },
    ];
  }, [props.cie]);

  const findEqualCie = useMemo(() => {
    if (!condition) return;
    const duplicates = [];
    for (let v of condition) {
      const filterArray = condition.filter((i) => i.id !== v.id);
      const findCie = filterArray.find((i) => i.cie === v.cie);
      if (findCie) {
        duplicates.push(findCie.cie);
      }
    }
    return [...new Set(duplicates)];
  }, [condition]);

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={columns}
        data={condition}
        title={props.tableTitle}
        icons={tableIcons}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF",
          },
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <span>
                <img style={{ width: "15%" }} src={NotesImages} alt="" />
              </span>
            ),
          },
        }}
        actions={
          props.canEdit
            ? [
                {
                  icon: (rowData) => <Edit />,
                  tooltip: t("Edit"),
                  onClick: (e, row) => {
                    setOpen(true);
                    setCurrentRow(row);
                  },
                },
                (rowData) => ({
                  disabled: !findEqualCie.includes(rowData.cie),
                  icon: (rowData) => <DeleteOutline />,
                  tooltip: t("Delete"),
                  onClick: (e, row) => {
                    swal({
                      title: t("Are you sure?"),
                      text: t(
                        "Once deleted, you will not be able to recover this!"
                      ),
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        props.handleDeleteCondition(row.id);
                      }
                    });
                  },
                }),
              ]
            : []
        }
      />
      <HealthConditionModalForm
        open={open}
        modalTitle={t(props.tableTitle)}
        handleClose={() => {
          setOpen(false);
          setCurrentRow({});
        }}
        conditionType={props.conditionType}
        handleRefreshData={props.handleRefreshData}
        edition={true}
        type={props.type}
        updatableData={currentRow}
      />
    </div>
  );
}

export default HealthCondition;
