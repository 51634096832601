import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import Icon from "@material-ui/core/Icon";
import Box from "@material-ui/core/Box";

export default function CardMain({ title, children, icon }) {
  const iconClasses = {
    root: {
      color: "#ba1ee1",
    },
  };

  return (
    <Card
      variant="outlined"
      className="m-2 w-100 card-higthlights rounded-md-custom"
    >
      <CardContent>
        <Box display="flex" alignItems="center">
          {icon && (
            <img
              src={icon}
              alt="spark"
              style={{ verticalAlign: "middle", marginRight: "0.5rem" }}
              sizes={"2"}
              width={"25"}
            />
          )}
          <Typography
            className="text-md-left font-weight-bolder"
            color="textPrimary"
            gutterBottom
          >
            {title}
          </Typography>
        </Box>
        <div className="mt-2">{children}</div>
      </CardContent>
    </Card>
  );
}
