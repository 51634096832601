import React, { useEffect, useState, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  makeStyles,
  TablePagination,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import axios from "axios";
import useUserState from "../../hooks/useUserState";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  defaultIcon: {
    fontSize: theme.spacing(5),
    color: theme.palette.text.secondary,
  },
}));

const HomeSummaryTable = () => {
  const classes = useStyles();
  const localState = useUserState();
  const [appointmentsData, setAppointmentsData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useMemo(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL_BASE}/reports/summary`, {
        headers: {
          Authorization: `Bearer ${localState[0].jwt}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setAppointmentsData(response.data.data);
      });
  }, []);

  const calculateDuration = (start, end) => {
    // calculate the duration of the appointment using appt_date_start and appt_date_end , extract duration in munutes
    const duration = new Date(end) - new Date(start);
    const minutes = Math.floor(duration / 60000);
    return minutes + " minutes";
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, appointmentsData.length - page * rowsPerPage);

  return (
    <Paper>
      <TableContainer component={Paper}>
        <Table
          className={classes.table}
          aria-label="provider appointments table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Photo</TableCell>
              <TableCell>Provider Name</TableCell>
              <TableCell>Specialty</TableCell>
              <TableCell>Appointment Type</TableCell>
              <TableCell>Appointment Date</TableCell>
              <TableCell>Duration</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {appointmentsData &&
              appointmentsData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((appointment) => (
                  <TableRow key={appointment.id}>
                    <TableCell>
                      <AccountCircle className={classes.defaultIcon} />
                    </TableCell>
                    <TableCell>{appointment.provider_name}</TableCell>
                    <TableCell>{appointment.appt_specialty}</TableCell>
                    <TableCell>{appointment.consultation_type}</TableCell>
                    <TableCell>
                      {new Date(appointment.appt_date_start).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      {calculateDuration(
                        appointment.appt_date_start,
                        appointment.appt_date_end
                      )}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        style={{ marginRight: 8 }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                      >
                        Cancel
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={appointmentsData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default HomeSummaryTable;
