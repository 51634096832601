import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Axios from "axios";
import { CSVLink } from "react-csv";
import { makeStyles } from "@material-ui/core/styles";
import DashboardMui from "../../../dashboard/DashboardMui";
import CharSquare from "../../../../images/chart-square.svg";
import useUserState from "../../../hooks/useUserState";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  Button,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Grid,
  IconButton,
} from "@material-ui/core";
import useTranslator from "../../../hooks/useBDocTranslator";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";

const useStyles = makeStyles((theme) => ({
  datePicker: {
    margin: theme.spacing(2),
  },
  chartContainer: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  tableContainer: {
    marginTop: theme.spacing(4),
  },
  table: {
    minWidth: 650,
  },
}));

const AppointmentDetailsReport = () => {
  const classes = useStyles();
  const [chartData, setChartData] = useState([]);
  const [detailsData, setDetailsData] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 30))
  );
  const [endDate, setEndDate] = useState(new Date());
  const localState = useUserState();
  const { t, changeDirectLanguage } = useTranslator();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const getData = async () => {
    const response = await Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/reports/appointments/details`,
      {
        headers: { Authorization: `Bearer ${localState[0].jwt}` },
        params: {
          startDate,
          endDate,
          providerId: localState[0].extendedData.id,
        },
      }
    );

    setChartData(response.data.chartData);
    setDetailsData(response.data.detailsData);
  };

  useEffect(() => {
    getData();
    changeDirectLanguage();
  }, [startDate, endDate]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <DashboardMui
      sectionTitle={t("Appointment Details Report")}
      description={t("View appointment details over time.")}
      icon={CharSquare}
    >
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.datePicker}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          label={t("Start Date")}
          value={startDate}
          onChange={(date) => setStartDate(date)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <KeyboardDatePicker
          className={classes.datePicker}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          label={t("End Date")}
          value={endDate}
          onChange={(date) => setEndDate(date)}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
        <Button variant="contained" color="primary" onClick={getData}>
          {t("Apply Filter")}
        </Button>
      </MuiPickersUtilsProvider>

      <Paper className={classes.chartContainer} elevation={3}>
        <ResponsiveContainer width="100%" height={400}>
          <LineChart data={chartData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="totalAppointments"
              stroke="#8884d8"
              name={t("Total Appointments")}
            />
          </LineChart>
        </ResponsiveContainer>
      </Paper>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table className={classes.table} aria-label="appointment details table">
          <TableHead>
            <TableRow>
              <TableCell>{t("Start Date")}</TableCell>
              <TableCell>{t("End Date")}</TableCell>
              <TableCell>{t("Consultation Type")}</TableCell>
              <TableCell>{t("Has Prescription")}</TableCell>
              <TableCell>{t("Has Payment")}</TableCell>
              <TableCell>{t("Price Total")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detailsData
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    {new Date(row.appt_date_start).toLocaleString()}
                  </TableCell>
                  <TableCell>
                    {new Date(row.appt_date_end).toLocaleString()}
                  </TableCell>
                  <TableCell>{row.consultation_type}</TableCell>
                  <TableCell>
                    {row.appt_has_prescription ? (
                      <CheckCircleIcon style={{ color: "green" }} />
                    ) : (
                      <CancelIcon style={{ color: "red" }} />
                    )}
                  </TableCell>
                  <TableCell>
                    {row.has_payment ? (
                      <CheckCircleIcon style={{ color: "green" }} />
                    ) : (
                      <CancelIcon style={{ color: "red" }} />
                    )}
                  </TableCell>
                  <TableCell>${row.price_total}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={detailsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <CSVLink data={detailsData}>Download CSV</CSVLink>
    </DashboardMui>
  );
};

export default AppointmentDetailsReport;
