import React, { useRef, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import useUserState from "../hooks/useUserState";
import Axios from "axios";
import useBDocTranslator from "../hooks/useBDocTranslator";
import Papa from "papaparse";
import { Button, CircularProgress } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { Link } from "react-router-dom";

export default function ModalAddBulkPatient(props) {
  const classes = useStyles();
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [patients, setPatients] = useState("");
  const [response, setResponse] = useState();
  const userLocalState = useUserState();
  const { t } = useBDocTranslator();

  const createUsers = () => {
    setLoading(true);
    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/patient/create/bulk`,
      {
        patients,
      },
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((res) => {
        setLoading(false);
        // props.close();
        setFileName("");
        setPatients();
        setResponse(res.data);
        if (props.recall) {
          props.recall();
          return;
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFileName(file.name);

    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          setPatients(result.data);
        },
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
    }
  };
  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.close}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          {
            <div className={classes.paper}>
              <div className="row col-md-12">
                <div className="col-md-8">
                  <h3>{t("Create Patients")}</h3>
                </div>
                <div className={classes.body}>
                  <p>{"File example:"}</p>
                  <Link
                    to="/files/patients_template.xlsx"
                    target="_blank"
                    download
                  >
                    {t("Download CSV template")}
                  </Link>
                  <div className={classes.template}>
                    <p className={classes.titleCsv}>{"nombre *"}</p>
                    <p className={classes.titleCsv}>{"apellido *"}</p>
                    <p className={classes.titleCsv}>{"email *"}</p>
                    <p className={classes.titleCsv}>{"plan"}</p>
                  </div>
                  <p>{"Si desea añadir un plan tiene que ser de precio 0"}</p>
                  {response && (
                    <div style={{ width: "100%", position: "relative" }}>
                      <p className={classes.responseTitle}>{"RESPUESTA"}</p>
                      <ClearIcon
                        onClick={() => {
                          setFileName("");
                          props.close();
                          setResponse();
                          setPatients();
                        }}
                        className={classes.responseClear}
                      />
                      <div className={classes.responseWrapper}>
                        {Object.keys(response).map((k) => {
                          const values = response[k];
                          if (!values) return <></>;
                          return (
                            <div className={classes.responseSec}>
                              <p className={classes.responseTitle}>{k} :</p>
                              {values.map((v) => (
                                <div className={classes.responseItem}>
                                  {`-- ${v.nombre} | ${v.email} | ${
                                    v.error ? t(v.error) : t("User created")
                                  }`}
                                </div>
                              ))}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {fileName && (
                    <div className={classes.fileName}>
                      <p>{fileName}</p>
                      <ClearIcon
                        onClick={() => {
                          setFileName("");
                          setPatients();
                        }}
                        className={classes.clear}
                      />
                    </div>
                  )}
                  {!fileName && !response && (
                    <div className={classes.buttonWrapper}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        className={classes.button}
                      >
                        {t("Choose CSV File")}
                      </Button>
                    </div>
                  )}
                  <input
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    type="file"
                    onChange={handleFileChange}
                    ref={hiddenFileInput}
                    style={{ display: "none" }}
                  />
                  {fileName && (
                    <>
                      <div className={classes.patientsWrapper}>
                        {patients &&
                          patients?.map((p) => (
                            <div>
                              {Object.keys(p)
                                .map((k) => p[k])
                                .join(" - ")}
                            </div>
                          ))}
                      </div>
                      <div className={classes.buttonWrapper}>
                        {loading ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={createUsers}
                            className={classes.button}
                          >
                            {t("Create")}
                          </Button>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          }
        </Fade>
      </Modal>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "whitesmoke",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "900px",
  },
  template: {
    display: "flex",
    alignItems: "center",
    margin: "1em 0 0 0",
  },
  titleCsv: {
    border: "1px solid #000",
    padding: ".5em",
    margin: "0",
  },
  fileName: {
    display: "flex",
    alignItems: "center",
    gap: "1em",
  },
  clear: {
    cursor: "pointer",
    fontSize: "20px",
  },
  body: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  button: {
    margin: "1em 0 2em 0",
    padding: ".5em 3em",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "center",
  },
  patientsWrapper: {
    height: "10em",
    overflowY: "auto",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
  },
  responseWrapper: {
    height: "10em",
    overflowY: "auto",
    width: "100%",
    display: "flex",
    justifyContent: "start",
    flexDirection: "column",
    alignItems: "start",
  },
  responseSec: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "start",
  },
  responseTitle: {
    fontSize: "15px",
    fontWeight: "500",
    textTransform: "uppercase",
  },
  responseItem: {
    display: "flex",
    width: "100%",
    display: "flex",
    justifyContent: "start",
    textAlign: "left",
  },
  responseClear: {
    position: "absolute",
    top: "0",
    right: "0",
    cursor: "pointer",
  },
}));
