import React, { useEffect, useMemo, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import StarsComponent from "../utils/StarsComponent";
import { useLocation, useParams } from "react-router-dom";
import RatingComponent from "./RatingComponent";
import useBDocTranslator from "../hooks/useBDocTranslator";
import { Button } from "@material-ui/core";
import useUserState from "../hooks/useUserState";
import axios from "axios";

export default function Rating() {
  const location = useLocation();
  const { rateProvider } = RatingComponent();
  const { t, changeDirectLanguage } = useBDocTranslator();
  const [providerFullname, setProviderFullname] = useState();
  const [profileImage, setProfileImage] = useState();
  const [currentRate, setCurrentRate] = useState(null);
  const { id } = useParams();
  const user = useUserState();
  const userLocalState = useMemo(() => {
    if (!user) return;
    return user[0];
  }, [user]);
  const jwtSession = userLocalState?.jwt;

  const fetchAllProviders = () => {
    try {
      axios
        .get(
          `${process.env.REACT_APP_API_URL_BASE}/appointments/findOne?id=${
            location.state?.appointmentId || id
          }`,
          {
            headers: { Authorization: "Bearer " + jwtSession }, //the jwt is a variable which holds the token
          }
        )
        .then((response) => {
          if (!response.data) return;
          setProviderFullname(
            `${response.data.provider?.firstName} ${response.data.provider?.lastname}`
          );
          if (response.data.provider?.user?.user_images?.length > 0)
            setProfileImage(
              response.data.provider?.user?.user_images[0].path_resource
            );
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  //Effect to fetch info
  useEffect(() => {
    fetchAllProviders();
    changeDirectLanguage();
  }, []);

  return (
    <div>
      <div className="d-flex justify-content-center">
        <div
          className="card rounded-lg shadow p-3 mb-5 bg-white"
          style={{ width: "25rem" }}
        >
          <div className="card-body">
            <h5 className="card-title">
              {t("Rate your experience")}
              {":"}
            </h5>
            <h5 className="card-subtitle mt-4 mb-2 text-muted">
              {providerFullname}
            </h5>
            <div className="d-flex justify-content-center">
              <Avatar
                alt={providerFullname}
                src={profileImage}
                style={{
                  width: "100px",
                  height: "100px",
                  border: "2px solid #085279a1",
                  backgroundColor: "black",
                }}
              />
            </div>

            <StarsComponent
              ratingChanged={(newRating) => {
                setCurrentRate(newRating);
              }}
              editRate={true}
            />
            <div className="d-flex justify-content-center">
              <p>{t("Your rating")}:</p>
              {currentRate === 1 ? (
                <p className="text-danger">{t("Very bad")}</p>
              ) : currentRate === 2 ? (
                <p className="text-danger">{t("Bad")}</p>
              ) : currentRate === 3 ? (
                <p className="text-warning">{t("Good")}</p>
              ) : currentRate === 4 ? (
                <p className="text-success">{t("Very good")}</p>
              ) : currentRate === 5 ? (
                <p className="text-success">{t("Excellent")}</p>
              ) : (
                <p className="text-muted">{t("Not rated")}</p>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  rateProvider(
                    currentRate,
                    location.state?.appointmentId || id
                  );
                }}
                disabled={currentRate === null}
              >
                {t("Save")}
              </Button>
            </div>
            <section>
              <div className="d-flex justify-content-center">
                {/* explain why the rating is important for us */}
                <p>{t("Your rating is important for us")}</p>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}
