import React, { useEffect, useState } from "react";
import {
  Button,
  useTheme,
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Avatar,
  Menu,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Link, useHistory } from "react-router-dom";
import doctor from "../../images/doctor.svg";
// import DefaultLogo from "../../images/logo-doc-nic.jpg";
import DashSidebarOption from "../dashboard/DashSidebarOption";
import "../../styles/customs.css";

//Icons Components
import { FaCalendarAlt, FaUsers, FaUserMd } from "react-icons/fa";
import clsx from "clsx";
import AppsIcon from "@material-ui/icons/Apps";
import AssessmentIcon from "@material-ui/icons/Assessment";
import BuildIcon from "@material-ui/icons/Build";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import HomeIcon from "@material-ui/icons/Home";
import TodayIcon from "@material-ui/icons/Today";
import SecurityIcon from "@material-ui/icons/Security";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import NotificationsActiveIcon from "@material-ui/icons/NotificationsActive";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import LocalPharmacyIcon from "@material-ui/icons/LocalPharmacy";
import useBDocTranslator from "../hooks/useBDocTranslator";
import PlaceIcon from "@material-ui/icons/Place";

// Local State
import { useSelector } from "react-redux";
import UseUserState from "../hooks/useUserState";

//Connection to handle and save the message
import { db } from "../../config/firebase";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "50px",
    height: "50px",
  },
  doctor: {
    paddingTop: "10px",
  },
  menu: {
    marginTop: "40px",
  },
  menuAppIcon: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuName: {
    color: "#ffffff",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  menuDivider: {
    display: "flex",
    height: "40px",
    maxHeight: "40px",
    width: "2px",
    backgroundColor: "#0c436a9e" /* #dcddde73*/,
    margin: "10px",
    marginRight: "15px",
    borderRadius: "8px",
    border: "none",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#0077B6",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: "none",
  },
  // appBar: {
  //   //width: `calc(100% - ${drawerWidth}px)`,
  //   //marginLeft: drawerWidth,
  //   backgroundColor: "#0077B6",
  //   zIndex: theme.zIndex.drawer + 1,
  // },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    //backgroundColor: "#212529",
  },
  drawerItems: {
    paddingLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "0px",
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: "60px",
    [theme.breakpoints.down("sm")]: {
      width: "45px",
    },
  },
  menuButton: {
    marginRight: 40,
  },
  // necessary for content to be below app bar
  toolbarSide: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: "#e5e5e5",
    padding: theme.spacing(3),
    height: "100vh !important",
    marginLeft: "-15px",
    marginTop: "30px",
  },
  avatar: {
    //marginLeft: "50px",
    //backgroundColor: theme.palette.secondary.main,
    width: "60px",
    height: "60px",
  },
  superiorToolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  iconColor: {
    color: "#d9eef7",
  },
}));

/* Functions for manage the rendering of icons from other Libs diff to material-ui */
function FaCalendarComp() {
  return <FaCalendarAlt style={{ fontSize: "1.3rem" }} />;
}

function FaUsersComp() {
  return <FaUsers style={{ fontSize: "1.3rem" }} />;
}

function FaUserMdComp() {
  return <FaUserMd style={{ fontSize: "1.3rem" }} />;
}

export default function DashboardAdmin(props) {
  const userState = UseUserState();
  const classes = useStyles();
  let history = useHistory();
  const [selectedMenu, setSelectedMenu] = useState(false);

  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowUp, setArrowUp] = useState(false);
  const profileImg = useSelector((state) => state.userImage.profileImg);
  const exists = useSelector((state) => state.userImage.exists);
  const userImgState = useSelector((state) => state.userImage);
  const newUserImgState = useSelector((state) => state.mewUserImage);
  //New Messages indicator
  const [messages, setMessages] = useState([]);
  const [badgeMessage, setBadgeMessage] = useState(0);
  // breadcumps options for dashboard
  const [breadcrumbsOptions, setBreadcrumbsOptions] = useState(() => {
    return [{ title: "home", url: "/" }];
  });
  const Logo = process.env.REACT_APP_TENANT_LOGO;

  const { t, changeDirectLanguage } = useBDocTranslator();

  const handleClick = (event) => {
    setArrowUp(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setArrowUp(false);
    setAnchorEl(null);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //TODO Finish this functionality
  const handleSelectMenu = () => {
    console.log("menu");
  };

  //Collections of icons for sidebar menu
  const iconsEnum = [
    {
      option: "Patients",
      icon: FaUsersComp,
      order: 1,
    },
    {
      option: "Home",
      icon: HomeIcon,
      order: 0,
    },
    {
      option: "Providers",
      icon: FaUserMdComp,
      order: 2,
    },
    {
      option: "Appointments",
      icon: TodayIcon,
      order: 3,
    },
    {
      option: "Settings",
      icon: BuildIcon,
      order: 4,
    },
    {
      option: "Roles",
      icon: SecurityIcon,
      order: 5,
    },
    {
      option: "Users",
      icon: SupervisorAccountIcon,
      order: 6,
    },
    {
      option: "My Calendar",
      icon: FaCalendarComp,
      order: 7,
    },
    {
      option: "Text Consultations",
      icon: QuestionAnswerIcon,
      order: 8,
    },
    {
      option: "Places",
      icon: PlaceIcon,
      order: 9,
    },
  ];

  const logOut = () => {
    window.sessionStorage.clear();
    history.push("/users/login");
  };

  //validate if the session or JWT Exist
  const validateSession = () => {
    if (!userState[0].jwt) {
      history.push("/users/login");
    }
  };

  //Find the corresponding Icon
  const findIcon = (optionName, iconsCollection) => {
    const result = iconsEnum.find(({ option }) => option === optionName);
    return result?.icon ? result.icon : HomeIcon;
  };

  //Effect to validate the user session
  useEffect(() => {
    changeDirectLanguage();
    validateSession();
    buildBreadcumsOptions();
  }, []);

  //Method to Fetch if user has any unreaded text consultation
  const getMessages = async () => {
    db.collection("textConsultations-v2")
      .where("send_to_userid", "==", userState[0].user.id)
      .onSnapshot((querySnapshot) => {
        const docs = [];
        querySnapshot.forEach((doc) => {
          //const newDate = .toLocaleString();
          docs.push({
            ...doc.data(),
            id: doc.id,
            // created: format(new Date(doc.createdAt), "MM-dd-yyyy"),
          });
        });
        //setLinks(docs);
        setMessages(docs);
        window.sessionStorage.setItem("msg", JSON.stringify(docs));
        const notifications = docs.filter(
          (msg) => msg.action_response_status === "pending"
        );
        setBadgeMessage(notifications.length);
      });
  };

  //To go to Text consultation screen
  const handleMessageClick = () => {
    history.push("/admin/textconsult");
  };

  const buildBreadcumsOptions = () => {
    const modules = userState[0].user.role.role_modules;
    let result = [{ title: "Home", url: "/users/home" }];
    if (modules.length > 0) {
      modules.forEach((role) => {
        result.push({ title: role.module.print_name, url: role.module.route });
      });
      setBreadcrumbsOptions(result);
    }
  };

  const getUserModules = () => {
    const modules = userState[0].user?.role?.role_modules;
    let result = [];

    if (modules.length > 0) {
      modules.forEach((role) => {
        result.push({
          title: role.module.print_name,
          url: role.module.route,
          id: role.module.id,
        });
      });
      // sort the array by title in ascending order
      result.sort((a, b) => (a.title > b.title ? 1 : -1));
      return result;
    }
    return result;
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        color="primary"
      >
        <Toolbar className={classes.superiorToolbar}>
          <div className="d-flex">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon className={classes.iconColor} />
            </IconButton>
          </div>
          <div className="d-flex">
            <Button className={classes.menuAppIcon}>
              <AppsIcon style={{ color: "white" }} />
            </Button>
            <Button>
              <NotificationsActiveIcon style={{ color: "white" }} />
            </Button>

            <hr className={classes.menuDivider} />
            {exists === true ? (
              <div className="img-cropper-profile align-items-center">
                <Avatar src={profileImg} className="avatar-custom" />
              </div>
            ) : (
              <div className="img-cropper-profile align-items-center">
                <Avatar
                  src={
                    userState[0].user.user_images[0] > 0
                      ? userState[0].user.user_images[0].path_resource
                      : doctor
                  }
                  className="avatar-custom"
                />
              </div>
            )}
            {/* {exists == null ? (
              <div className="img-cropper-profile align-items-center">
                <Avatar
                  src={userState.user.profile_image ? (userState.user.profile_image):(doctor)}
                  className="avatar-custom"
                />
              </div>
            ): ("")} */}
            <div>
              <IconButton onClick={handleClick} className="mt-2">
                {arrowUp === false ? (
                  <KeyboardArrowDownIcon style={{ color: "white" }} />
                ) : (
                  <KeyboardArrowUpIcon style={{ color: "white" }} />
                )}
              </IconButton>
              {/* <Button
                className={classes.menuName}
                aria-controls="simple-menu"
                aria-haspopup="true"
              >
                {userState[0].extendedData.firstName + " " + userState[0].extendedData.firstName}
              </Button> */}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.menu}
              >
                {/* <MenuItem onClick={(e) => handleClose()}>Healthcare profile</MenuItem> */}
                <MenuItem
                  onClick={(e) => {
                    handleClose();
                    history.push(`/users/editProfile`);
                  }}
                >
                  Edit profile
                </MenuItem>
              </Menu>
            </div>
            <Button onClick={logOut}>
              <ExitToAppIcon style={{ color: "white" }} />
            </Button>
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
        anchor="left"
      >
        <div className={classes.toolbarSide}>
          <Avatar src={Logo} variant="square" className={classes.logo} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon className={classes.iconColor} />
            ) : (
              <ChevronLeftIcon className={classes.iconColor} />
            )}
          </IconButton>
        </div>

        <Divider />
        <div className={classes.drawerItems}>
          <List>
            {/* default Home Component if role_modules does not have a home option */}
            {!getUserModules().some((el) => {
              return el.title === "Home";
            }) && (
              <Link to="/users/home" data-tip={!open ? "Home" : ""}>
                <DashSidebarOption
                  // linkTo="/admin/dashboard"
                  Icon={HomeIcon}
                  optionText="Home"
                />
              </Link>
            )}
            {/* Menu Sidebar Options for User */}
            {getUserModules().map((module) => {
              return (
                module.title !== "Roles" &&
                module.title !== "Users" && (
                  <Link
                    to={module.url}
                    data-tip={!open ? module.title : ""}
                    key={module.id}
                  >
                    <DashSidebarOption
                      Icon={findIcon(module.title, iconsEnum)}
                      optionText={t(module.title)}
                    />
                  </Link>
                )
              );
            })}
          </List>
          <Divider />
          <ListSubheader
            inset
            color="primary"
            //style={{ fontWeight: "bold", color: "cornflowerblue" }}
          >
            Security
          </ListSubheader>

          <List>
            {/* Menu Sidebar SECURITY OPCIONS FOR ADMIN */}
            {getUserModules().map((module) => {
              return (
                (userState[0].user.role.name.toUpperCase() === "ADMIN" ||
                  userState[0].user.role.name.toUpperCase() === "BROKER") &&
                (module.title === "Roles" || module.title === "Users" ? (
                  <Link
                    to={module.url}
                    key={module.id}
                    data-tip={!open ? module.title : ""}
                  >
                    <DashSidebarOption
                      Icon={findIcon(module.title, iconsEnum)}
                      optionText={module.title}
                    />
                  </Link>
                ) : null)
              );
            })}
          </List>
          <Divider />
          <List>
            <Link to="/users/report/list" data-tip={!open ? "Reports" : ""}>
              <ListItem button>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Reports" />
              </ListItem>
            </Link>
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        {/* <BreadCrumbsNav breadOptions={breadcrumbsOptions} /> */}
        {/* Here in this Div put a section title */}
        {/* <div className={classes.toolbarSidebar}>
          <h1 className="h1-section-title mt-4">
            {props?.sectionTitle || "Section"} {" | "}
            <span className="span-section-title">
              {props?.sectionSubtitle || "Section Detail"}
            </span>
          </h1>
        </div> */}
        {props.children}
      </main>
    </div>
  );
}
