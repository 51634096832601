import React, { useEffect, useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import useBDocTranslator from "../../hooks/useBDocTranslator";
import { Button, makeStyles } from "@material-ui/core";
import { addDays, format, lastDayOfMonth } from "date-fns";
import Axios from "axios";
import useUserState from "../../hooks/useUserState";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { CSVLink } from "react-csv";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    minWidth: "600px",
    height: "600px",
    background: "#fff",
    padding: "2em",
  },
  filterWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "1em",
    justifyContent: "space-between",
  },
  dates: {
    display: "flex",
    alignItems: "center",
    gap: "1em",
  },
  title: {
    fontSize: "25px",
    width: "100%",
    textAlign: "left",
    margin: "10px 0 10px 0",
  },
  extraInfo: {
    width: "100%",
    textAlign: "left",
  },
  filterButton: {
    width: "fit-content",
  },
  finalActions: {
    display: "flex",
    width: "100%",
    justifyContent: "end",
    gap: "1em",
    alignItems: "center",
  },
}));

const AppointmentStatusPerTime = () => {
  const { t } = useBDocTranslator();
  const initDates = {
    start: format(new Date(), "yyyy-MM-01"),
    end: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
  };
  const [dates, setDates] = useState(initDates);
  const userLocalState = useUserState();
  const [data, setData] = useState(null);
  const classes = useStyles();

  const getData = async () => {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/reports/provider/appt_status/time?start=${dates.start}&end=${dates.end}&id=${userLocalState[0]?.extendedData?.id}`,
      {
        headers: { Authorization: "Bearer " + userLocalState[0]?.jwt },
      }
    )
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        throw error;
      });
  };
  useEffect(() => {
    getData();
  }, []);

  const handleDateChange = (key, date) => {
    setDates((p) => ({ ...p, [key]: format(date, "yyyy-MM-dd") }));
  };

  const dataToCsv = useMemo(() => {
    const res = [
      [
        "fecha",
        "citas concluidas",
        "citas canceladas",
        "citas reprogramadas",
        "nuevas citas",
        "citas confirmadas",
      ],
    ];
    if (!data) return res;
    for (let v of data) {
      res.push([
        v.date,
        v.concluida,
        v.cancelada,
        v.reprogramada,
        v.nueva,
        v.confirmada,
      ]);
    }
    return res;
  }, [data]);

  return (
    <div className={classes.wrapper}>
      <p className={classes.title}>{t("Appointments status per time")}</p>
      <div className={classes.filterWrapper}>
        <div className={classes.dates}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline-start"
              label={t("Date From")}
              value={addDays(new Date(dates.start), 1)}
              onChange={(d) => handleDateChange("start", d)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline-end"
              label={t("Date To")}
              value={addDays(new Date(dates.end), 1)}
              onChange={(d) => handleDateChange("end", d)}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={getData}
          className={classes.filterButton}
        >
          {t("Apply filter")}
        </Button>
      </div>
      <ResponsiveContainer width="100%" height="65%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 15,
            left: 10,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="concluida" fill="#37b714" />
          <Bar dataKey="confirmada" fill="#7795f8" />
          <Bar dataKey="nueva" fill="#fcd569" />
          <Bar dataKey="reprogramada" fill="#cfcad4" />
          <Bar dataKey="cancelada" fill="#ff5079" />
        </BarChart>
      </ResponsiveContainer>
      <div className={classes.finalActions}>
        <CSVLink data={dataToCsv}>{t("Download to CSV")}</CSVLink>
      </div>
    </div>
  );
};

export default AppointmentStatusPerTime;
