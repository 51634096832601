import { useState } from "react";
import Axios from "axios";
import useUserState from "./useUserState";

export default function useMessages(module = "provider") {
  const [messages, setMessages] = useState([]);
  const [badgeMessage, setBadgeMessage] = useState(0);
  const [creationResponse, setCreationResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const userState = useUserState();

  const getApiMessages = async () => {
    setLoading(true);
    if (userState[0] === undefined) return;
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/messages/getmessages`,
      {
        headers: { Authorization: "Bearer " + userState[0]?.jwt }, //the jwt is a variable which holds the token
      }
    );
    setMessages(data);
    window.sessionStorage.setItem("msg", JSON.stringify(data));
    const notifications = data.filter((msg) => {
      if (module === "patient") return msg.patient_receiver_status === "UNREAD";

      return msg.provider_receiver_status === "UNREAD";
    });
    setBadgeMessage(notifications.length);
    setLoading(false);
  };

  const createMessage = async (msgs) => {
    setLoading(true);
    const { data } = await Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/messages/create`,
      {
        msgs,
      },
      {
        headers: { Authorization: "Bearer " + userState[0]?.jwt }, //the jwt is a variable which holds the token
      }
    );

    console.log("createMessage", data);
    setCreationResponse(data);
    setLoading(false);
  };

  const replyToMessage = async ({
    content,
    send_by_userid,
    send_to_userid,
    docId,
    patient_receiver_status,
    provider_receiver_status,
  }) => {
    setLoading(true);
    const { data } = await Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/messages/replyMessage`,
      {
        content,
        send_by_userid,
        send_to_userid,
        docId,
        patient_receiver_status,
        provider_receiver_status,
      },
      {
        headers: { Authorization: "Bearer " + userState[0]?.jwt }, //the jwt is a variable which holds the token
      }
    );

    console.log("replyToMessage", data);
    setCreationResponse(data);
    setLoading(false);
  };

  const updateMessage = async ({
    docId,
    provider_receiver_status,
    patient_receiver_status,
    send_to_userid,
  }) => {
    setLoading(true);
    const { data } = await Axios.put(
      `${process.env.REACT_APP_API_URL_BASE}/messages/updateMessage`,
      {
        send_to_userid,
        docId,
        patient_receiver_status,
        provider_receiver_status,
      },
      {
        headers: { Authorization: "Bearer " + userState[0]?.jwt },
      }
    );

    console.log("updateToMessage", data);
    setCreationResponse(data);
    setLoading(false);
  };

  return {
    messages,
    badgeMessage,
    getApiMessages,
    createMessage,
    creationResponse,
    loading,
    replyToMessage,
    updateMessage,
  };
}
