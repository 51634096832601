import React, { useState, useEffect } from "react";
import Jitsi from "react-jitsi";
import Loading from "../utils/Loading";
import { useHistory, useLocation } from "react-router-dom";

export default function VideoFrame(props) {
  const [displayName, setDisplayName] = useState(props.displayName);
  const [roomName, setRoomName] = useState(props.eventCode);
  const [password, setPassword] = useState(props.password); //Using the same Hash
  const [onCall, setOnCall] = useState(false);
  const [redirectOnLeft, setredirectOnLeft] = useState(false);
  let history = useHistory();
  let location = useLocation();

  const handleAPI = (JitsiMeetAPI) => {
    JitsiMeetAPI.executeCommand("allow", "camera; microphone");
    // JitsiMeetAPI.on("passwordRequired", function () {
    //   JitsiMeetAPI.executeCommand("password", password);
    // });
    JitsiMeetAPI.on("videoConferenceLeft", () => {
      console.log("Left");
      setredirectOnLeft(true);
    });
  };
  useEffect(() => {
    setOnCall(true);
  }, [onCall]);

  const LoadingFrame = () => {
    return (
      <div className="mt-5 pt-5">
        <Loading color="#3788d8d4" type="cylon" width="140" height="140" />
      </div>
    );
  };

  useEffect(() => {
    if (redirectOnLeft) {
      // setTimeout(() => {
      //   console.log("path name", location.pathname);
      //   if (location.pathname.includes("patient")) {
      //     history.push("/patient/dashboard");
      //   } else {
      //     history.push("/admin/dashboard");
      //   }
      // }, 500);
      setTimeout(() => {
        if (location.pathname.includes("patient") && props.rating === 0) {
          history.push({
            pathname: "/patient/rate/appointment",
            state: {
              appointmentId: props.appointmentId,
              providerFullname: props.providerFullname,
              profileImage: props.profileImage,
            },
          });
        } else {
          history.push("/admin/dashboard");
        }
      }, 500);
    }
    return () => clearTimeout();
  }, [redirectOnLeft]);

  return onCall ? (
    <div style={{ width: "100%", height: "100vh" }}>
      <Jitsi
        config={{ prejoinPageEnabled: false, disableDeepLinking: true }}
        roomName={roomName}
        displayName={displayName}
        password={password}
        loadingComponent={LoadingFrame}
        onAPILoad={handleAPI}
        onIframeLoad={() => console.log("frame loaded")}
        containerStyle={{ width: "100%", height: "100vh" }}
        domain="meets.hcgconsulting.net"
      />
    </div>
  ) : (
    <>
      <Loading color="#3788d8d4" />
    </>
  );
}
