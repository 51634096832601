/**
 *  Component For Providers Login
 */
import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import "../../styles/LoginProviders.css";
import "../../styles/LoginPatients.css";
import LoadingSpin from "../utils/Loading";
// import Logo from "../../images/logo-doc-nic.jpg";
import Copyright from "../utils/Copyright";
// Logos for side section and avatar in form
import Hospital from "../../images/hospital.png";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
/** Redux resources */
import { setUserAction } from "../../actions";
import { useDispatch } from "react-redux";
/** Matetial UI New Imports */
import {
  Button,
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Typography,
  Avatar,
  Box,
  Container,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import useBDocTranslator from "../hooks/useBDocTranslator";
import TranslationButton from "../utils/TranslationButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "30px",
    backgroundColor: "white",
    boxShadow: "5px 5px 16px 1px #1b3342",
    borderRadius: "10px",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LoginMain(props) {
  const classes = useStyles();
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    verified: false,
  });
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const queryType = "3"; // for Role ID 3 (provider)
  let history = useHistory();

  //Distpach of Redux
  const distpach = useDispatch();

  // To manage translations
  const { t, onChangeLanguaje, changeDirectLanguage } = useBDocTranslator();

  const Logo = process.env.REACT_APP_TENANT_LOGO;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClick = (e) => {
    e.preventDefault();
    if (email && password) {
      setLoading(true);
      Axios.post(`${process.env.REACT_APP_API_URL_BASE}/session`, {
        email,
        password,
        queryType,
      })
        .then((response) => {
          if (response.data) {
            //distpach values to main store
            console.log({
              RESPONSE: response.data,
            });
            distpach(setUserAction(response.data));
            if (location.state?.from) {
              history.push(location.state?.from);
              return;
            }
            history.push("/admin/dashboard");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setErrorMessage({
            error:
              error?.response?.data?.message ||
              "User not allowed, please contact tenant admin.",
            visible: true,
          });
        });
    } else {
      setErrorMessage({
        error: t("Please fill the email and password"),
        visible: true,
      });
      console.log("Please Verify Credentials");
    }
  };

  const handleCancel = () => {
    history.push("/");
  };

  useEffect(() => {
    changeDirectLanguage("es");
  }, []);

  return (
    <React.Fragment>
      <div className="container">
        <IconButton
          aria-label="delete"
          className={classes.margin}
          fontSize="large"
          onClick={handleCancel}
        >
          <ArrowBackIcon />
        </IconButton>

        <div className="row">
          <h5 className="m-auto w-50 text-center">
            {t("login.patient.new.here") + "?"}
            <Link href="/admin/provider/registration" variant="inherit">
              {t("login.patient.register")}
            </Link>
          </h5>
          <TranslationButton onChangeLanguaje={onChangeLanguaje} />
        </div>

        <div className="row d-flex justify-content-around px-3">
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <div className="icon-login-container">
                <Avatar className="icon-login-avatar" src={Logo}></Avatar>
              </div>
              <Typography component="h1" variant="h5">
                {t("login.provider.signin")}
              </Typography>
              {/* Section to manage error and feedback to user */}
              <div className="row mt-2">
                {errorMessage.visible && (
                  <Alert severity="error">{errorMessage.error}</Alert>
                )}
              </div>

              <form className={classes.form} noValidate>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t("password")}
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FormControlLabel
                  control={<Checkbox value="remember" color="primary" />}
                  label={t("login.patient.rememberme")}
                />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={handleClick}
                >
                  {t("login.signin")}
                </Button>
                <Grid container className="mt-2">
                  <Grid item xs>
                    <Link href="/admin/login/recovery" variant="body2">
                      {t("login.patient.forgot.password")}
                    </Link>
                  </Grid>
                </Grid>
                <div>
                  {loading && (
                    <div className="d-flex justify-content-center">
                      <LoadingSpin type="spinningBubbles" color="#1d3557" />
                    </div>
                  )}
                </div>
              </form>
              <Box mt={8}>
                <Copyright />
              </Box>
            </div>
          </Container>
          <div className="d-flex align-items-center">
            <img className="hospital" src={Hospital} alt="Hospital" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LoginMain;
