import React, { useEffect } from "react";
import DashboardMUI from "../dashboard/DashboardMui";
import AppointmentSummary from "../widgets/AppointmentSummary";
import Loading from "../utils/Loading";
import { resizeByLink } from "../utils/ResizeLinkCloudinary";
import lineChart from "../../images/line-chart.svg";
import moment from "moment";
import AppointmentHistory from "../widgets/AppointmentHistory";
import SandClockIcon from "../../images/hourglass.svg";
import useUserState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";
import {
  useAppointmentsHistoryByProvider,
  useGetProviderById,
} from "../../services/services";

function MainProviders() {
  const localState = useUserState();
  const providerId = localState[0]?.extendedData?.id;
  const jwt = localState[0]?.jwt;
  const {
    getProviderById,
    loading: loadingData,
    providerData: appointments,
  } = useGetProviderById(providerId, jwt);
  const {
    getAppointmentsHistoryByProvider,
    appointmentsHistory: bookingHistory,
  } = useAppointmentsHistoryByProvider(providerId, jwt);
  const { t } = useBDocTranslator();

  //Options for manage the Format of dates
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  useEffect(() => {
    getProviderById(providerId);
    getAppointmentsHistoryByProvider(providerId);
  }, []);

  const NoData = () => {
    return (
      <div className="text-center">
        <img src={SandClockIcon} className="w-25 mt-3" alt="sand clock" />
        <h3 className="m-3">{t("No upcoming consultations")}</h3>
      </div>
    );
  };

  const lastOrderRegister = (serviceOrders) => {
    if (serviceOrders.length > 0) {
      return serviceOrders.sort((a, b) => b?.id - a?.id);
    }
    return serviceOrders;
  };

  return (
    <DashboardMUI
      sectionTitle={t("Your Highlights")}
      description={t(
        "Here you can find a list of Next appointments and your highlights"
      )}
      icon={lineChart}
    >
      <div className="row">
        {/* Row 1 of Section */}
        <div className="d-flex col-md-6 col-sm-12">
          <div className="sub-container-widget shadow p-3 m-1 bg-white rounded-lg-custom col-md-12 col-sm-12 DivWithScroll">
            <h4 className="title-dashboard-card mb-4">
              {t("Upcoming Appointments")}
            </h4>
            {loadingData && <Loading color="#3788d8d4" />}
            {appointments?.length > 0
              ? appointments?.map((item) => {
                  // Patient Avatar IMG
                  const imgURL =
                    item.patient?.user?.user_images?.at(-1)?.path_resource;
                  const profileImageURL =
                    imgURL && resizeByLink(imgURL, "upload");

                  const appt_start = moment(item?.appt_date_start);
                  const appt_end = moment(item?.appt_date_end);
                  const provider_fullname = `${localState[0]?.extendedData?.firstName} ${localState[0]?.extendedData?.lastname}`;
                  const serviceName =
                    item.services_orders?.length > 0 &&
                    item.services_orders?.[0].service_type?.name;
                  const serviceOrderStatus = lastOrderRegister(
                    item.services_orders
                  );

                  const patientData =
                    item?.patient?.affiliations?.[0]?.plan?.name;

                  return (
                    <AppointmentSummary
                      key={item?.id}
                      event_code={item?.event_code}
                      patientData={patientData}
                      patient_fullname={
                        item?.patient?.firstName + " " + item?.patient?.lastname
                      }
                      password="br4ck3ts1t"
                      appt_date_start={appt_start
                        .utc()
                        .format("yy-MM-DD HH:mm")}
                      appt_date_end={appt_end.utc().format("yy-MM-DD HH:mm")}
                      providerFullname={provider_fullname}
                      service_name={serviceName}
                      event_type={item?.event_type}
                      appt_status_code={
                        item?.status_appointment?.appt_status_code
                      }
                      appt_status_name={item.status_appointment.description}
                      profileImage={profileImageURL}
                      appointmentId={item?.id}
                      paymentStatus={
                        serviceOrderStatus?.length > 0
                          ? serviceOrderStatus[0]?.status
                          : "N/A"
                      }
                      patientEmail={null}
                      appointmentReason={item?.appt_reason}
                      orderId={
                        serviceOrderStatus?.length > 0
                          ? serviceOrderStatus[0]?.id
                          : 0
                      }
                      patientId={item?.patient?.id}
                      price={
                        serviceOrderStatus?.length > 0
                          ? serviceOrderStatus[0]?.price_total
                          : 0
                      }
                      affiliations={item.patient?.affiliations}
                    />
                  );
                })
              : !loadingData && <NoData />}
          </div>
        </div>
        {/* Row 2 Section */}

        <div className="d-flex col-md-6 col-sm-12">
          <div className="sub-container-widget shadow p-3 m-1 bg-white rounded-lg-custom col-md-12 col-sm-12 DivWithScroll">
            <h4 className="title-dashboard-card mb-4">
              {t("Booking History")}
            </h4>
            {loadingData && <Loading color="#3788d8d4" />}
            {bookingHistory?.length > 0 &&
              bookingHistory?.map((item) => {
                // Patient Avatar IMG

                const imgURL =
                  item.patient?.user?.user_images?.at(-1)?.path_resource;
                const profileImageURL =
                  imgURL && resizeByLink(imgURL, "upload");

                const appt_start = moment(item?.appt_date_start);
                const appt_end = moment(item?.appt_date_end);
                const provider_fullname = `${localState[0]?.extendedData?.firstName} ${localState[0]?.extendedData?.lastname}`;
                const serviceName =
                  item?.services_orders?.length > 0
                    ? item?.services_orders?.[0].service_type?.name
                    : "";
                const serviceOrderStatus = lastOrderRegister(
                  item?.services_orders
                );

                return (
                  <AppointmentHistory
                    key={item?.id}
                    event_code={item?.event_code}
                    patient_fullname={
                      item?.patient?.firstName + " " + item?.patient?.lastname
                    }
                    password="br4ck3ts1t"
                    appt_date_start={appt_start.utc().format("yy-MM-DD HH:mm")}
                    appt_date_end={appt_end.utc().format("yy-MM-DD HH:mm")}
                    providerFullname={provider_fullname}
                    service_name={serviceName}
                    event_type={item?.event_type}
                    appt_status_code={
                      item?.status_appointment?.appt_status_code
                    }
                    appt_status_name={item?.status_appointment?.description}
                    profileImage={profileImageURL}
                    appointmentId={item?.id}
                    paymentStatus={
                      serviceOrderStatus?.length > 0
                        ? serviceOrderStatus[0]?.status
                        : "N/A"
                    }
                    patientEmail={null}
                    patientId={item?.patient?.id}
                  />
                );
              })}
          </div>
          {/* <div className="sub-container-widget shadow p-3 m-1 bg-white rounded-lg-custom col-md-6 col-sm-12 w-50">
                     <p>Cuadrante 4</p>
                     </div> */}
        </div>
      </div>
    </DashboardMUI>
  );
}

export default MainProviders;
