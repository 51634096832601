import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import stethoscope from "../../images/stethoscope.svg";
import useUserState from "../hooks/useUserState";
import Axios from "axios";
import HealthMeasurementDialog from "./HealthMeasurementDialog";
import useBDocTranslator from "../hooks/useBDocTranslator";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    overflowY: "auto",
    backgroundColor: "whitesmoke",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "100%",
    maxWidth: "900px",
    height: "400px",
  },
}));

export default function HealthMeasurementModalForm(props) {
  const classes = useStyles();
  const [responseMessage, setResponseMessage] = useState("");
  const userLocalState = useUserState();
  const [successFlag, setSuccessFlag] = useState(false);
  const { t } = useBDocTranslator();

  //Method creates a new health measurement record in the database
  const handleMeasureCreate = async (data, refresh) => {
    if (!validateData(data)) {
      setResponseMessage("Error, Please fill the Form");
      return;
    }

    if (props.editValue && data.measurement_type === "ECG") {
      await Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/measurement/edit`,
        {
          id: props.editValue?.id,
          result: data.result,
        },
        {
          headers: { Authorization: "Bearer " + userLocalState[0].jwt },
        }
      )
        .then((res) => {
          setSuccessFlag(true);
          props.handleRefreshData(props.patientId);
        })
        .catch((error) => {
          console.log(error);
          setResponseMessage("Error " + error);
        });
      return;
    }

    await Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/measurement/create`,
      data,
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((res) => {
        setSuccessFlag(true);
        setResponseMessage(
          "Measurement created, record is going to be displayed in the App"
        );
        if (refresh) {
          props.handleRefreshData(props.patientId);
        }
      })
      .catch((error) => {
        console.log(error);
        setResponseMessage("Error " + error);
      });
  };

  // validate the data object if it is valid
  function validateData(data) {
    let isValid = false;
    if (data.measurement_type !== "" && data.result !== "") {
      isValid = true;
    }
    return isValid;
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        // className="flex items-center justify-center overflow-y-auto"
        open={props.open}
        onClose={() => {
          props.handleClose();
          setResponseMessage("");
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          {
            <div className={classes.paper}>
              <div className="row col-md-12">
                <div className="col-md-4">
                  <img
                    src={stethoscope}
                    alt="rx icon"
                    className="w-30-custom"
                  />
                </div>
                <div className="col-md-8">
                  <h3>{t("Monitor")}</h3>
                </div>
                <div className="col-md-12">
                  <p
                    className={`font-weight-bold  ${
                      responseMessage.includes("Error")
                        ? "text-danger"
                        : "text-success"
                    } text-center`}
                  >
                    {t(responseMessage)}
                  </p>
                </div>
              </div>
              {/* Form */}
              <div className="row col-md-12">
                <HealthMeasurementDialog
                  handleMeasureCreate={handleMeasureCreate}
                  handleClose={() => {
                    props.handleClose();
                    setResponseMessage("");
                  }}
                  appointmentFiles={props.appointmentFiles}
                  successFlag={successFlag}
                  type={props.type}
                  patientId={props.patientId}
                  resetResponseMsg={() => setResponseMessage("")}
                  editValue={props.editValue}
                  setEditValue={props.setEditValue}
                />
              </div>
            </div>
          }
        </Fade>
      </Modal>
    </div>
  );
}
