import React from "react";
import useBDocTranslator from "../hooks/useBDocTranslator";

export default function CheckoutResume({
  serviceType,
  serviceDuration,
  price,
  sinpeInfo,
}) {
  const { t } = useBDocTranslator();

  return (
    <div className="col-md-6 col-sm-12">
      <section>
        <h2 className="font-weight-bold">{t("Order Resume")}</h2>
        <p>{t("Here you have the summary of your appontment to pay.")}</p>
        <div className="checkout-resume-table">
          <p className="mt-2">
            {t("Service")}
            {": "}
            <span className="font-weight-bold text-primary">
              {serviceType}
            </span>{" "}
          </p>
          <hr className="checkout-divider" />
          <p>
            {t("Duration")}
            {": "}
            <span className="font-weight-bold text-primary">
              {serviceDuration} min
            </span>{" "}
          </p>
          <hr className="checkout-divider" />

          <p>
            {t("Total to Pay")} (USD):{" "}
            <span className="font-weight-bold text-primary">${price}</span>
          </p>
        </div>
      </section>
      {/* a card with the Sinpe Movil information if the prop is received  */}
      {sinpeInfo && (
        <section className="mt-3">
          <h4 className="font-weight-bold pl-2">{t("Sinpe Movil")}</h4>
          <p>{t("Please make the payment to the following number:")}</p>
          <div className="checkout-resume-table">
            <p className="mt-2">
              {t("Numero de Monedero")}
              {": "}
              <span className="font-weight-bold text-primary">
                {sinpeInfo.phone}
              </span>{" "}
            </p>
            <hr className="checkout-divider" />
            <p>
              {t("Description del Pago")}
              {": "}
              <span className="font-weight-bold text-danger">
                {sinpeInfo.description}
              </span>{" "}
            </p>
          </div>
        </section>
      )}
    </div>
  );
}
