import React, { useState, useEffect, useMemo } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal, Box, Typography } from "@material-ui/core";
import axios from "axios";
import { format } from "date-fns";
import useUserState from "../../hooks/useUserState";

const CalendarAdminView = () => {
  const [appointmentData, setAppointmentData] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const localState = useUserState();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL_BASE}/reports/summary`, {
        headers: {
          Authorization: `Bearer ${localState[0].jwt}`,
        },
      })
      .then((response) => {
        console.log(response.data.data);
        setAppointmentData(response.data.data);
      });
  }, []);

  const events = useMemo(() => {
    return appointmentData.map((appointment) => ({
      id: appointment.id,
      title: ` DR - ${appointment.provider_name}`,
      start: appointment.appt_date_start,
      end: appointment.appt_date_end,
      backgroundColor:
        appointment.consultation_type === "VIRTUAL" ? "#478CCF" : "#77E4C8",
      extendedProps: {
        consultation_type: appointment.consultation_type,
        patient_name: appointment.patient_name,
        appt_specialty: appointment.appt_specialty,
        provider_name: appointment.provider_name,
        appointment_status: appointment.appointment_status,
        price_total: appointment.price_total,
        payment_status: appointment.payment_status,
        patient_phone: appointment.patient_phone,
        patient_email: appointment.patient_email,
      },
    }));
  }, [appointmentData]);

  const handleEventClick = (clickInfo) => {
    setSelectedEvent(clickInfo.event);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedEvent(null);
  };

  const eventColors = (eventInfo) => {
    return eventInfo.extendedProps &&
      eventInfo.extendedProps.consultation_type === "VIRTUAL"
      ? "#478CCF"
      : "#77E4C8";
  };

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        background: "white",
        margin: "5px",
        padding: "5px",
        borderRadius: "5px",
      }}
    >
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventClick={handleEventClick}
        // eventContent={(eventInfo) => (
        //   <div style={{ backgroundColor: eventColors(eventInfo) }}>
        //     <b>{eventInfo.timeText}</b>
        //     <i>{eventInfo.event.title}</i>
        //   </div>
        // )}
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        height="100%"
        contentHeight="auto"
      />
      <div className="legend">
        <div className="legend-item">
          <span
            className="color-box"
            style={{ backgroundColor: "#478CCF" }}
          ></span>
          <span>Cita Virtual</span>
        </div>
        <div className="legend-item">
          <span
            className="color-box"
            style={{ backgroundColor: "#77E4C8" }}
          ></span>
          <span>Cita Presencial</span>
        </div>
      </div>
      {selectedEvent && (
        <Modal open={open} onClose={handleClose}>
          <Box
            position="absolute"
            top="50%"
            left="50%"
            style={{
              transform: "translate(-50%, -50%)",
              width: 400,
              backgroundColor: "white",
              padding: 20,
            }}
          >
            <Typography variant="h6">{selectedEvent.title}</Typography>
            <Typography variant="subtitle1">
              Date: {format(new Date(selectedEvent.start), "PPpp")}
            </Typography>
            <Typography variant="subtitle1">
              Type: {selectedEvent.extendedProps.consultation_type}
            </Typography>
            <Typography variant="subtitle1">
              Patient: {selectedEvent.extendedProps.patient_name}
            </Typography>
            <Typography variant="subtitle1">
              Specialty: {selectedEvent.extendedProps.appt_specialty}
            </Typography>
            <Typography variant="subtitle1">
              Provider: {selectedEvent.extendedProps.provider_name}
            </Typography>
            <Typography variant="subtitle1">
              Status: {selectedEvent.extendedProps.appointment_status}
            </Typography>
            <Typography variant="subtitle1">
              Price: ${selectedEvent.extendedProps.price_total}
            </Typography>
            <Typography variant="subtitle1">
              Payment Status: {selectedEvent.extendedProps.payment_status}
            </Typography>
            <Typography variant="subtitle1">
              Phone: {selectedEvent.extendedProps.patient_phone}
            </Typography>
            <Typography variant="subtitle1">
              Email: {selectedEvent.extendedProps.patient_email}
            </Typography>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default CalendarAdminView;
