import React, { useState } from "react";
import useUserState from "./useUserState";
import Axios from "axios";

const useUploadEHRFiles = (patientId, callback) => {
  const [responseMessage, setResponseMessage] = useState("");
  const userLocalState = useUserState();
  const [successFlag, setSuccessFlag] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  //Method creates a new health measurement record in the database
  const handleUploadEHRFile = () => {
    setLoading(true);
    if (uploadedFiles.length === 0) {
      setResponseMessage("Error, Por favor suba un archivo.");
      return;
    }
    if (uploadedFiles.length > 5) {
      setResponseMessage("Error, Solo se permiten 5 archivos simultaneamente.");
      return;
    }

    const data = new FormData();
    data.append("patient_id", patientId);
    uploadedFiles.map((file) => {
      return data.append("file", file);
    });

    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/ehr/uploadLabsDocuments`,
      data,
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((res) => {
        setLoading(false);
        setSuccessFlag(true);
        setResponseMessage(
          "Archivos subidos, los archivos se van a mostrar en la App."
        );

        if (callback) callback(res.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        setResponseMessage("Error " + error);
      });
  };

  return {
    responseMessage,
    handleUploadEHRFile,
    successFlag,
    setSuccessFlag,
    setUploadedFiles,
    loading,
    uploadedFiles,
  };
};

export default useUploadEHRFiles;
