import React, { forwardRef, useMemo, useState } from "react";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import HealthImage from "../../images/healthcondition.svg";
import useBDocTranslator from "../hooks/useBDocTranslator";
import SendIcon from "@material-ui/icons/Send";
import Axios from "axios";
import useUserState from "../hooks/useUserState";
import { makeStyles } from "@material-ui/core";
import { differenceInMinutes } from "date-fns";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  action: {
    cursor: "pointer",
  },
}));

function stripHtml(html) {
  // Reemplazar cada etiqueta HTML con un espacio
  let text = html.replace(/<\/?[^>]+(>|$)/g, " ");

  // Reemplazar múltiples espacios por un solo espacio
  text = text.replace(/\s+/g, " ").trim();

  return text;
}

const waitTime = 10;

function Prescriptions(props) {
  const classes = useStyles();
  const { t } = useBDocTranslator();
  const userLocalState = useUserState();
  const [loading, setLoading] = useState([]);

  const resendPres = (prescId) => {
    setLoading((p) => [...p, prescId]);
    Axios.post(
      `${process.env.REACT_APP_API_URL_BASE}/prenscriptions/resend`,
      {
        prescription_id: prescId,
      },
      {
        headers: { Authorization: "Bearer " + userLocalState[0].jwt },
      }
    )
      .then((response) => {
        setLoading((p) => p.filter((i) => i !== prescId));
        if (props.recall) props.recall();
      })
      .catch((error) => {
        console.log(error);
        setLoading((p) => p.filter((i) => i !== prescId));
      });
  };

  const cleanedData = props.data.map((item) => {
    let waitResend = false;
    let lastResendTimeMinutes = 0;
    if (item.resend_date) {
      const lastResendTime = differenceInMinutes(
        new Date(),
        new Date(item.resend_date)
      );

      if (lastResendTime < waitTime) {
        waitResend = true;
        lastResendTimeMinutes = lastResendTime;
      }
    }

    return {
      ...item,
      patient_directions: stripHtml(item?.patient_directions),
      providerName: `${item.appointment.provider.firstName} ${item.appointment.provider.lastname}`,
      resend: waitResend ? (
        <span>{`${t("wait")} ${waitTime - lastResendTimeMinutes} ${t(
          "minutes"
        )}`}</span>
      ) : loading.includes(item.id) ? (
        <span>...{t("sending")}</span>
      ) : (
        <span className={classes.action}>
          <SendIcon
            className="cursor-pointer"
            onClick={() => resendPres(item.id)}
          />
        </span>
      ),
    };
  });

  const columns = useMemo(() => {
    const res = [
      { title: t("Id"), field: "id" },
      { title: t("Patient Directions"), field: "patient_directions" },
      { title: t("Created by"), field: "providerName" },
      {
        title: t("Created at"),
        field: "created_at",
        type: "datetime",
      },
      // { title: t("Pharmacy notes"), field: "pharmacy_note" },
    ];
    if (props.type === "pro") {
      res.push({ title: t("Resend"), field: "resend" });
    }
    return res;
  }, []);

  return (
    <div style={{ maxWidth: "100%" }}>
      <MaterialTable
        columns={columns}
        data={cleanedData}
        title={t("Prescriptions History")}
        icons={tableIcons}
        options={{
          headerStyle: {
            backgroundColor: "#01579b",
            color: "#FFF",
          },
          actionsColumnIndex: -1,
        }}
        localization={{
          body: {
            emptyDataSourceMessage: (
              <span>
                <img style={{ width: "20%" }} src={HealthImage} alt="" />
              </span>
            ),
          },
        }}
      />
    </div>
  );
}

export default Prescriptions;
