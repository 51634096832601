import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import LoadingSpin from "../utils/Loading";
import { useHistory, useParams } from "react-router-dom";
import ProviderProfileImage from "./ProviderProfileImage";
import "../../styles/customs.css";
import swal from "sweetalert";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { createMuiTheme } from "@material-ui/core/styles";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CancelIcon from "@material-ui/icons/Cancel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Chip from "@material-ui/core/Chip";
import Input from "@material-ui/core/Input";
//Redux
import { updateCurrentUser } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import UserLocalState from "../hooks/useUserState";
import useBDocTranslator from "../hooks/useBDocTranslator";

function ProviderProfileEdition({
  setCurrentSignature,
  setCurrentStamp,
  setPreviewStamp,
}) {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastname, setLastname] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [gender, setGender] = useState("");
  const [identification, setIdentification] = useState("");
  const [identificationType, setIdentificationType] = useState("");
  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [nationality, setNationality] = useState("");
  const [providerId, setProviderId] = useState("");
  const [summary, setSummary] = useState("");
  const [password, setPassword] = useState("");
  const [values, setValues] = useState({
    amount: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  const [title, setTitle] = useState("");

  const [data, setData] = useState("");
  const [jwtExpired, setJwtExpired] = useState("");
  const [openAlertImg, setOpenAlertImg] = useState(false);
  const [errorMessage, setErrorMessage] = useState({
    error: "",
    visible: false,
    msgClass: "",
  });
  //jwt received in the email an placed on uri
  let { id } = useParams();
  const [specialty, setSpecialty] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] = useState([]);
  const [countries, setCountries] = useState([]);
  const [medicalLicense, setMedicalLicense] = useState("");
  const [issuedBy, setIssuedBy] = useState("");
  const { t, changeDirectLanguage } = useBDocTranslator();
  const [primaryLanguage, setPrimaryLanguage] = useState("es");
  const [secondaryLanguage, setSecondaryLanguage] = useState("en");
  const [providerLocations, setProviderLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);

  //Distpach of Redux
  const distpach = useDispatch();

  //Extract the userState to Redux Glabal State
  const userLocal = UserLocalState();

  const newUserImageState = useSelector((state) => state.newUserImage);

  const clearForm = () => {
    document.getElementById("patient-form").reset();
    setDateOfBirth("");
    setEmail("");
    setGender("");
    setLastname("");
    setfirstName("");
    setPhone("");
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  // -------------------------PASSWORD----------------------------

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handlePaswword = (e) => {
    if (password !== "") {
      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/users/changePassword`,
        {
          token: id,
          newPassword: password,
        },
        {
          headers: { Authorization: "Bearer " + userLocal[0].jwt },
        }
      )
        .then((res) => {
          swal({
            title: "Success!",
            text: t("Password changed"),
            icon: "success",
            button: "OK",
          });
        })
        .catch((err) => {
          console.log(err);
          swal({
            title: "Failed!",
            text: t("Error changing password"),
            icon: "error",
            button: false,
          });
        });
    }
  };

  // -------------------------- ALERT -----------------------------

  const handleAlertImg = () => {
    setOpenAlertImg(true);
  };

  const handleCloseAlertImg = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlertImg(false);
  };

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  // -------------------------- IMAGE UPDATE CLOUDINARY --------------------

  const handleSaveProfileImg = (e) => {
    // console.log(userProfileImg.profileImg);
    if (newUserImageState?.newProfileImg) {
      setImgLoading(true);

      let imgProfile = newUserImageState.newProfileImg;

      // Assuming imgProfile is a File object
      const reader = new FileReader();
      reader.readAsDataURL(imgProfile);
      reader.onload = async () => {
        const dataUrl = reader.result;
        const response = await Axios({
          method: "POST",
          url: `${process.env.REACT_APP_API_URL_BASE}/userImage/save`,
          data: {
            file_name: `${firstName}ProfileImg`,
            created_by_user: userLocal[0].user.id,
            file_profile: dataUrl,
          },
          headers: {
            Authorization: "Bearer " + userLocal[0].jwt,
            // "Content-Type": "multipart/form-data",
          },
        });

        setImgLoading(false);
        handleAlertImg();
        const userImageElm = {
          path_resource: response.data.path_resource,
        };
        distpach(updateCurrentUser(userImageElm));
        //  releoad the page after 2 seconds
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      };

      // .catch(function (error) {
      //   setImgLoading(false);
      //   //handle error
      //   console.log(error);
      //   swal(
      //     "Error!",
      //     t("Something went wrong, please contact the Admin "),
      //     "error"
      //   );
      // });
    } else {
      console.log("no hay imagen seleccionada");
    }
  };

  // ----------------------- PROFILE UPDATE -------------------------

  const handleClick = (e) => {
    if (
      email &&
      phone &&
      firstName &&
      lastname &&
      dateOfBirth &&
      gender &&
      identification &&
      summary &&
      selectedSpecialty.length > 0
    ) {
      setLoading(true);

      Axios.put(
        `${process.env.REACT_APP_API_URL_BASE}/provider/update`,
        {
          phone1: phone,
          firstName,
          lastname,
          dateOfBirth,
          gender,
          identificationType,
          identification,
          country,
          nationality,
          id: providerId,
          summary,
          specialty: selectedSpecialty[0],
          medical_license: medicalLicense,
          issued_by: issuedBy,
          primary_language: primaryLanguage,
          secondary_language: secondaryLanguage,
          title,
        },
        {
          headers: { Authorization: "Bearer " + userLocal[0].jwt }, //the jwt is a variable which holds the token
        }
      )
        .then((response) => {
          setLoading(false);

          swal({
            tittle: "Success!",
            text: `${
              id
                ? t(
                    "Account Verification, Your request is under verification, please stay tuned"
                  )
                : t("Account Information Updated")
            }`,
            icon: "success",
            button: `${id ? t("Continue") : "Okay"}`,
          }).then((resOk) => {
            if (resOk && id) {
              history.push(`/admin/login`);
            } else {
              history.push(`/admin/dashboard`);
            }
          });
          setErrorMessage({
            error: "",
            visible: false,
            msgClass: "",
          });
          //clearForm();
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setErrorMessage({
            error: error,
            visible: true,
            msgClass: "alert alert-danger d-flex justify-content-center",
          });
        });
    } else {
      setErrorMessage({
        error: "Error, Please fill the Form",
        visible: true,
        msgClass: "alert alert-danger ",
      });
    }
    handleSaveProfileImg();
  };

  // -------------------- USEEFFECT ----------------------------

  useEffect(() => {
    changeDirectLanguage();
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/provider/review/` +
        userLocal[0].extendedData.id
    )
      .then((res) => {
        setLoading(false);
        setData(res.data);
        //Set Data to Controls
        findCountries();
        loadDataToControls(res?.data);
        if (setCurrentSignature) {
          setCurrentSignature(res?.data?.signature);
        }
        if (setCurrentStamp) {
          setCurrentStamp(res?.data?.stamp);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  }, []);

  //Specialties stored on BD
  const findSpecialties = async () => {
    try {
      const result = await Axios.get(
        `${process.env.REACT_APP_API_URL_BASE}/specialty/findAll`
      );
      if (result) {
        setSpecialty(result.data);
      }
    } catch (error) {
      console.error("Something wrong", error);
    }
  };

  //Find Countries On DB
  const findCountries = () => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/countries/findAll`)
      .then((res) => {
        setCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ----------------------- STYLES CLASSES --------------------

  const useStyles = makeStyles((theme) => ({
    form: {
      width: "100%", // Fix IE 11 issue.
      marginTop: theme.spacing(2),
    },
    textField: {
      width: "100%",
    },
  }));

  const theme = createMuiTheme({
    palette: {
      secondary: {
        main: "#e33371",
      },
    },
  });
  const classes = useStyles();

  // ----------------------  FUNC SET STATES --------------------

  async function loadDataToControls(data) {
    if (data) {
      const {
        id,
        firstName,
        lastname,
        identification,
        dateOfBirth,
        gender,
        country,
        nationality,
        phone1,
        identificationType,
        summary,
        medical_license,
        issued_by,
        medical_speciality,
        primary_language,
        secondary_language,
        title,
        provider_locations,
      } = data;
      setEmail(userLocal[0].user.email);
      setfirstName(firstName);
      setLastname(lastname);
      setIdentification(identification);
      setDateOfBirth(dateOfBirth && dateOfBirth.split("T")[0]);
      setGender(gender);
      setCountry(country);
      setNationality(nationality);
      setProviderId(id);
      setPhone(phone1);
      setIdentificationType(identificationType);
      setSummary(summary);
      setIssuedBy(issued_by);
      setMedicalLicense(medical_license);
      await findSpecialties();
      setSelectedSpecialty(() => {
        let spl = [medical_speciality?.id] || "General Practioner";
        return spl;
      });
      setPrimaryLanguage(primary_language);
      setSecondaryLanguage(secondary_language);
      setTitle(title);
      setProviderLocations(provider_locations);
    }
  }

  //-----------------JWT EXPIRED -----------------------

  const handleJwtExpired = (e) => {
    swal({
      tittle: "Failed!",
      text: "Link expired, please contact suport",
      icon: "error",
      button: "Suport",
      closeOnClickOutside: false,
    }).then((resOk) => {
      if (resOk) {
        history.push(`/admin/login`);
      }
    });
  };

  //------------------ HANDLE CONFIRM ------------------
  const handleConfirm = (e) => {
    // if (password && userLocal[0]) {
    //   handlePaswword();
    // } else {
    // }
    handlePaswword();
    handleClick(e);
  };

  //to handle the selected specialities selected on control
  const handleChangeSpecialty = (sp) => {
    if (sp) {
      setSelectedSpecialty(sp);
    }
  };

  // ----------------------  RETURN -------------------

  return (
    <>
      <div className="shadow-lg p-3 mb-5 bg-white rounded">
        <div className="row d-flex justify-content-center">
          <div className="col-md-4 d-flex justify-content-center">
            <ProviderProfileImage imgLoading={imgLoading} />
          </div>
          <div className="col-md-9">
            <form className="row" id="patient-form">
              <div className="form-group col-md-2">
                <FormControl variant="outlined" className={classes.form}>
                  <InputLabel required id="title">
                    {t("Title")}
                  </InputLabel>
                  <Select
                    labelId="title"
                    id="gender"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    label="Title"
                  >
                    <MenuItem value={""}>{t("None")}</MenuItem>
                    <MenuItem value={"DR."}>{t("Dr.")}</MenuItem>
                    <MenuItem value={"TECH."}>{t("Tech.")}</MenuItem>
                    <MenuItem value={"OTHER"}>{t("Other")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="form-group col-md-5">
                <TextField
                  margin="normal"
                  label={t("First Name")}
                  variant="outlined"
                  id="firstName"
                  fullWidth
                  onChange={(e) => setfirstName(e.target.value)}
                  required
                  value={firstName || ""}
                />
              </div>
              <div className="form-group col-md-5">
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  label={t("Last Name")}
                  variant="outlined"
                  id="lastname"
                  onChange={(e) => setLastname(e.target.value)}
                  value={lastname || ""}
                />
              </div>
              <div className="form-group col-md-12">
                <TextField
                  required
                  margin="normal"
                  label={t("Your Email")}
                  variant="outlined"
                  id="email"
                  value={email || ""}
                  autoComplete="email"
                  fullWidth
                  placeholder="name@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                  disabled
                />
              </div>
              <div className="form-group col-md-8">
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  label={t("Phone Number")}
                  variant="outlined"
                  id="phoneNumber"
                  value={phone || ""}
                  placeholder="888823456"
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="form-group col-md-4 ">
                <TextField
                  id="country"
                  required
                  select
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  placeholder="Select"
                  label={t("Country")}
                  value={country || "select"}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {countries &&
                    countries.map((singleCountry) => (
                      <MenuItem
                        key={singleCountry.id}
                        value={singleCountry.code}
                      >
                        {singleCountry.name}
                      </MenuItem>
                    ))}
                </TextField>
              </div>
              <div className="form-group col-md-3">
                <TextField
                  id="identificationType"
                  required
                  select
                  fullWidth
                  variant="outlined"
                  margin="normal"
                  placeholder="Select"
                  label={t("Identification Type")}
                  onChange={(e) => setIdentificationType(e.target.value)}
                  value={identificationType || "select"}
                >
                  <MenuItem value="DNI">DNI</MenuItem>
                  <MenuItem value="PASSPORT">Passport</MenuItem>
                  <MenuItem value="CED">Cedula de Identidad</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </TextField>
              </div>

              <div className="form-group col-md-9">
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  label={t("Identification number")}
                  variant="outlined"
                  id="identification"
                  onChange={(e) => setIdentification(e.target.value)}
                  value={identification || ""}
                />
              </div>
              <div className="form-group col-md-4">
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  margin="normal"
                  id="dateOfBirth"
                  name="dateOfBirth"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="date"
                  label={t("Date Of Birth")}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  value={dateOfBirth || ""}
                />
              </div>
              <div className="form-group col-md-8">
                <FormControl variant="outlined" className={classes.form}>
                  <InputLabel required id="gender">
                    {t("Gender")}
                  </InputLabel>
                  <Select
                    labelId="gender"
                    id="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    label="Gender"
                  >
                    <MenuItem value={""}>{t("None")}</MenuItem>
                    <MenuItem value={"MALE"}>{t("Male")}</MenuItem>
                    <MenuItem value={"FEMALE"}>{t("Female")}</MenuItem>
                    <MenuItem value={"OTHER"}>{t("Other")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              {/*  Fields for Primary and Secondary Language */}
              <div className="form-group col-md-6">
                <FormControl variant="outlined" className={classes.form}>
                  <InputLabel required id="primaryLang">
                    {t("Primary Language")}
                  </InputLabel>
                  <Select
                    labelId="primaryLang"
                    id="primaryLang"
                    value={primaryLanguage}
                    onChange={(e) => setPrimaryLanguage(e.target.value)}
                    label="Primary Language"
                  >
                    <MenuItem value={""}>{t("None")}</MenuItem>
                    <MenuItem value={"EN"}>{t("English")}</MenuItem>
                    <MenuItem value={"ES"}>{t("Español")}</MenuItem>
                    <MenuItem value={"DE"}>{t("Deutsch")}</MenuItem>
                    <MenuItem value={"FR"}>{t("French")}</MenuItem>
                    <MenuItem value={"PT"}>{t("Portuguese")}</MenuItem>
                    <MenuItem value={"IT"}>{t("Italian")}</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="form-group col-md-6">
                <FormControl variant="outlined" className={classes.form}>
                  <InputLabel required id="secondaryLang">
                    {t("Secondary Language")}
                  </InputLabel>
                  <Select
                    labelId="secondaryLang"
                    id="secondaryLang"
                    value={secondaryLanguage}
                    onChange={(e) => setSecondaryLanguage(e.target.value)}
                    label="Secondary Language"
                  >
                    <MenuItem value={""}>{t("None")}</MenuItem>
                    <MenuItem value={"EN"}>{t("English")}</MenuItem>
                    <MenuItem value={"ES"}>{t("Español")}</MenuItem>
                    <MenuItem value={"DE"}>{t("Deutsch")}</MenuItem>
                    <MenuItem value={"FR"}>{t("French")}</MenuItem>
                    <MenuItem value={"PT"}>{t("Portuguese")}</MenuItem>
                    <MenuItem value={"IT"}>{t("Italian")}</MenuItem>
                  </Select>
                </FormControl>
              </div>

              {/* License and Issued By */}

              <div className="form-group col-md-6">
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  label={t("Medical License")}
                  variant="outlined"
                  id="medical-license"
                  onChange={(e) => setMedicalLicense(e.target.value)}
                  value={medicalLicense || ""}
                />
              </div>

              <div className="form-group col-md-6">
                <TextField
                  required
                  fullWidth
                  margin="normal"
                  label={t("Issued By")}
                  variant="outlined"
                  id="issued-by"
                  onChange={(e) => setIssuedBy(e.target.value)}
                  value={issuedBy || ""}
                />
              </div>

              {/* End License and Issued By */}
              <div className="form-group col-md-12">
                <TextField
                  id="summary"
                  label={t("Professional Summary")}
                  multiline
                  fullWidth
                  rows={5}
                  value={summary}
                  variant="outlined"
                  onChange={(e) => setSummary(e.target.value)}
                />
              </div>
              {/* Specialties */}
              <div className="form-group col-md-12">
                <FormControl className="col-md-12">
                  <InputLabel id="specialty-label">{t("Specialty")}</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="specialty-label"
                    id="specialty"
                    multiple
                    value={selectedSpecialty}
                    onChange={(e) => handleChangeSpecialty(e.target.value)}
                    input={<Input id="select-multiple-chip" />}
                    renderValue={(selected) => (
                      <div className="d-flex flex-wrap">
                        {selected.map((item, idx) => {
                          const findSpecialtyTag = specialty.find(
                            (el) => el?.id === item
                          );
                          return (
                            <Chip
                              key={findSpecialtyTag?.id}
                              label={t(findSpecialtyTag?.speciality_name)}
                              className="m-1 specialty-chip"
                            />
                          );
                        })}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    {specialty.map((el) => (
                      <MenuItem
                        key={el.id}
                        value={el.id}
                        //style={getStyles(name, personName, theme)}
                      >
                        {t(el.speciality_name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              {/* Providers Locations */}
              <div className="form-group col-md-12">
                <FormControl className="col-md-12">
                  <InputLabel id="locations-label">
                    {t("Physical care centers")}
                  </InputLabel>
                  <Select
                    variant="outlined"
                    labelId="locations-label"
                    id="locations"
                    multiple
                    value={providerLocations}
                    onChange={(e) => console.log(e.target.value)}
                    input={<Input id="select-multiple-chip-locations" />}
                    renderValue={(selected) => (
                      <div className="d-flex flex-wrap">
                        {selected.map((item, idx) => {
                          return (
                            <Chip
                              color="secondary"
                              key={item?.id}
                              label={item?.name}
                              className="m-1 specialty-chip"
                            />
                          );
                        })}
                      </div>
                    )}
                    MenuProps={MenuProps}
                    disabled
                  >
                    {providerLocations.map((el) => (
                      <MenuItem
                        key={el.id}
                        value={el.id}
                        //style={getStyles(name, personName, theme)}
                      >
                        {t(el.name)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="form-group col-md-12">
                <FormControl className={classes.textField} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    {t("Password")}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={values.showPassword ? "text" : "password"}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    fullWidth
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    labelWidth={110}
                  />
                </FormControl>
              </div>
              <div className="col-md-12 mt-3 d-flex justify-content-center ">
                {errorMessage.visible && (
                  <div className={errorMessage.msgClass} role="alert">
                    {t(errorMessage?.error)}
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 d-flex justify-content-center ">
            <div className="p-3">
              <Button
                variant="contained"
                color="primary"
                startIcon={<CheckCircleOutlineIcon />}
                onClick={handleConfirm}
              >
                {t("Save")}
              </Button>
            </div>

            <div className="p-3">
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CancelIcon />}
                onClick={(e) => {
                  history.goBack();
                }}
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          {loading && (
            <div className="d-flex justify-content-center mt-3">
              <LoadingSpin type="spokes" color="#1d3557" />
            </div>
          )}
        </div>
      </div>
      <Snackbar
        open={openAlertImg}
        autoHideDuration={5000}
        onClose={handleCloseAlertImg}
      >
        <Alert onClose={handleCloseAlertImg} severity="success">
          {t("Success Profile Image Updated")}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ProviderProfileEdition;
