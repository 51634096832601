import { useState, useEffect, useMemo } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import CheckoutLogic from "./CheckoutLogic";
import DashboardPatient from "../dashboard/DashboardPatient";
import { Backdrop, CircularProgress } from "@material-ui/core";
import HeadingResume from "../widgets/HeadingResume";
import CheckSuccess from "../../images/checked-success.svg";
import SadFace from "../../images/sad-face.svg";
import mobilePaymentIcon from "../../images/mobile-payment.svg";
import useBDocTranslator from "../hooks/useBDocTranslator";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function GreenpayCheckoutCallback() {
  const classes = useStyles();
  const history = useHistory();
  const { token } = useParams();
  const [open, setOpen] = useState(false);
  const { handleSubcriptionCallback } = CheckoutLogic();
  const { t } = useBDocTranslator();
  const [message, setMessage] = useState("");

  //this method manage the backdrop status
  const handleClose = () => {
    setOpen(false);
  };

  const getItemFromLocalStorage = (key) => {
    return sessionStorage.getItem(key);
  };

  useEffect(() => {
    const affId = getItemFromLocalStorage("affId");
    if (token && affId) {
      setOpen(true);
      handleSubcriptionCallback({ data: token, affiliationId: affId })
        .then((response) => {
          console.log("response", response);
          if (response?.status === 200) {
            setMessage("Payment succeeded!");
          } else {
            setMessage("Your payment was not successful, please try again.");
          }
          setOpen(false);
          sessionStorage.removeItem("affId");
        })
        .catch((error) => {
          console.log("error", error);
          setMessage("Your payment was not successful, please try again.");
          setOpen(false);
          sessionStorage.removeItem("affId");
        });
    }
  }, []);

  return (
    <DashboardPatient>
      {/* Element that block the screen when request its happen */}
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <HeadingResume
        title={t("Checkout & Payment")}
        description={t(
          "Once you make the payment we'll notify to Healthcare Provider and we'll send you invoice copy"
        )}
        icon={mobilePaymentIcon}
      />
      <div>
        <div className="row m-3 p-3 shadow w-30 rounded-lg-custom bg-white">
          {/* Create a container to display a checkmark icon to show to the user that the payment was successful  */}
          {message === "Payment succeeded!" && (
            <div className="col-12 text-center">
              <h3 className="text-center">{t("Payment successful")}</h3>
              <p className="text-center">{t("Your payment was successful")}</p>
              <p className="text-center">
                {t(
                  "We'll notify to Healthcare Provider and we'll send you invoice copy"
                )}
              </p>
              <p className="text-center">
                {t("Thank you for using Our Services")}
              </p>
              {/*    green checkmark svg icon */}
              <img
                src={CheckSuccess}
                alt="checkmark icon"
                className="w-15-custom"
              />
            </div>
          )}

          {/*    Payment declined  */}
          {message === "Your payment was not successful, please try again." && (
            <div className="col-12 text-center">
              <h3 className="text-center">{t("Payment declined")}</h3>
              <p className="text-center">
                {t("Your payment was not successful, please try again")}
              </p>
              <img src={SadFace} alt="sad icon" className="w-15-custom" />
            </div>
          )}
          {/*    Go to Dashboard button */}
          <div className="col-12 text-center mt-3">
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push("/patient/dashboard")}
            >
              {t("Go to Dashboard")}
            </Button>
          </div>
        </div>
      </div>
    </DashboardPatient>
  );
}
