import React, { useEffect, useMemo, useState } from "react";
import DashboardAdmin from "../../DashboardAdmin";
import BackToReportsList from "../BackToReportsList";
import useBDocTranslator from "../../../hooks/useBDocTranslator";
import { Button, makeStyles } from "@material-ui/core";
import { format, lastDayOfMonth } from "date-fns";
import Axios from "axios";
import useUserState from "../../../hooks/useUserState";
import { CSVLink } from "react-csv";
import SelectCIE from "../../../utils/SelectCIE";
import TableCieReport from "../../../utils/TableCieReport";
import TablePatientsCieReport from "../../../utils/TablePatientsCieReport";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    minWidth: "600px",
    background: "#fff",
    padding: "2em",
    display: "flex",
    flexDirection: "column",
    gap: "1em",
  },
  filterWrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "1em",
    justifyContent: "space-between",
  },
  dates: {
    display: "flex",
    alignItems: "center",
    gap: "1em",
  },
  title: {
    fontSize: "25px",
    width: "100%",
    textAlign: "left",
    margin: "10px 0 10px 0",
  },
  extraInfo: {
    width: "100%",
    textAlign: "left",
    display: "flex",
    gap: "1em",
    alignItems: "center",
  },
  filterButton: {
    width: "fit-content",
  },
  filterCie: {
    width: "80%",
  },
  finalActions: {
    display: "flex",
    width: "100%",
    justifyContent: "end",
    gap: "1em",
    alignItems: "center",
  },
}));

const PatientsReportCie10 = () => {
  const { t } = useBDocTranslator();
  const initDates = {
    start: format(new Date(), "yyyy-MM-01"),
    end: format(lastDayOfMonth(new Date()), "yyyy-MM-dd"),
  };
  const userLocalState = useUserState();
  const [ciePatients, setCiePatients] = useState(null);
  const classes = useStyles();

  const [cie, setCie] = useState();

  const getData = async () => {
    Axios.get(
      `${process.env.REACT_APP_API_URL_BASE}/reports/admin/cie/patient${
        cie?.length > 0 ? `?cie=${JSON.stringify(cie.map((i) => i.code))}` : ""
      }`,
      {
        headers: { Authorization: "Bearer " + userLocalState[0]?.jwt },
      }
    )
      .then((response) => {
        setCiePatients(response.data);
      })
      .catch((error) => {
        throw error;
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const ciePatientsToCsv = useMemo(() => {
    const res = [["CIE10", "descripcion", "pacientes"]];
    if (!ciePatients) return res;
    for (let v of ciePatients) {
      res.push([v.cie, v.description, v.patients]);
    }
    return res;
  }, [ciePatients]);

  return (
    <DashboardAdmin>
      <div className={classes.wrapper}>
        <BackToReportsList />
        <div className={classes.filterWrapper}>
          <div className={classes.filterCie}>
            <SelectCIE
              setCie={setCie}
              multiple
              label={t("Filter by CIE Code or description")}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={getData}
            className={classes.filterButton}
          >
            {t("Apply filter")}
          </Button>
        </div>
        <TableCieReport data={ciePatients || []} />
        <div className={classes.finalActions}>
          <CSVLink data={ciePatientsToCsv}>{t("Download to CSV")}</CSVLink>
        </div>
      </div>
    </DashboardAdmin>
  );
};

export default PatientsReportCie10;
