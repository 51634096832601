import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import LoadingSpin from "../utils/Loading";
import Button from "@material-ui/core/Button";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import TablePatients from "../utils/TablePatients";
import useUserState from "../hooks/useUserState";
import PacienteSVG from "./../../images/paciente.svg";
import DashboardAdmin from "../administrator/DashboardAdmin";
import useBDocTranslator from "../hooks/useBDocTranslator";
import ModalAddBulkPatient from "./ModalAddBulkPatient";

export default function PatientsAdminList(props) {
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [openBulk, setOpenBulk] = useState(false);
  let history = useHistory();
  const { t } = useBDocTranslator();

  //Action to send to Button
  const handleAddButton = () => {
    history.push("/admin/patientRegister");
  };

  //Function for Edit the selected Provider
  const handleClickManageAppt = (row) => {
    history.push("/users/op/appt/patient/" + row.id);
  };

  //extracting the persisted data on Redux and localStorage
  const userLocalState = useUserState();

  const getPatients = () => {
    Axios.get(`${process.env.REACT_APP_API_URL_BASE}/patient/`, {
      headers: {
        Authorization: "Bearer " + userLocalState[0].jwt, //the jwt is a variable which holds the token
      },
    })
      .then((res) => {
        setLoading(false);
        setData(res?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    getPatients();
  }, []);

  const handleEdit = (row) => {
    history.push("/users/patient/edit/" + row?.id);
  };

  return (
    <DashboardAdmin
      sectionTitle="Patients"
      icon={PacienteSVG}
      description="Details of Patients Registered on Practice or Tenant"
    >
      <div className="d-flex flex-column col-md-12">
        <div
          className="d-flex justify-content-end col-md-12 m-3"
          style={{ gap: "1em" }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={handleAddButton}
            disabled
          >
            {t("Add Patient")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => setOpenBulk(true)}
            // disabled
          >
            {t("Add Bulk Patients")}
          </Button>
        </div>
        <ModalAddBulkPatient
          open={openBulk}
          close={() => setOpenBulk(false)}
          recall={getPatients}
        />
        {data ? (
          <TablePatients
            parentComponent={"admin"}
            data={data}
            clickEvent={handleClickManageAppt}
            handleEdit={handleEdit}
            translator={t}
          />
        ) : (
          <LoadingSpin type="spokes" color="#023E8A" />
        )}
      </div>
    </DashboardAdmin>
  );
}
