import React, { useEffect, useState } from "react";
import CardMain from "./CardMain";
import { Grid, makeStyles } from "@material-ui/core";
import DashboardAdmin from "../DashboardAdmin";
import ContentCardProfile from "./ContentCardProfile";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import DateRangeIcon from "@material-ui/icons/DateRange";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import useUserState from "../../hooks/useUserState";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import FiberNewIcon from "@material-ui/icons/FiberNew";
import AiChat from "../../aiChat/AiChat";
import SparkIcon from "../../../images/spark.svg";
import HomeSummaryTable from "../reports/HomeSummaryTable";
import CalendarAdminView from "./CalendarAdminView";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column", // "row" | "column" | "row-reverse" | "column-reverse
    justifyContent: "space-evenly",
    marginTop: "20px",
  },
}));

export default function MainInsights() {
  const classes = useStyles();
  const userState = useUserState();
  const [metrics, setMetrics] = useState({});

  useEffect(() => {
    // check if user.role.name === "admin" to stract and set the metrics to the state
    if (userState[0].user?.role?.name === "ADMIN") {
      setMetrics(userState[0].user.metadata);
    }
  }, []);

  return (
    <DashboardAdmin>
      <div className={classes.root}>
        <Grid container spacing={3} className="row">
          <Grid item xs={12} className="d-flex">
            {/*<CardMain title="Company Profile">*/}
            {/*  <ContentCardProfile />*/}
            {/*</CardMain>*/}
            <CardMain title="Patients">
              <div className="text-center">
                <SupervisedUserCircleIcon
                  style={{ fontSize: 100, color: "#0a8ed4" }}
                />
                <h1 className="font-weight-bold">{metrics?.pat || 0}</h1>
              </div>
            </CardMain>
            <CardMain title="Staff Members">
              <div className="text-center">
                <PeopleAltIcon style={{ fontSize: 100, color: "#0a8ed4" }} />
                <h1 className="font-weight-bold">{metrics?.pro || 0}</h1>
              </div>
            </CardMain>
            <CardMain title="Consultations">
              <div className="text-center">
                <DateRangeIcon style={{ fontSize: 100, color: "#0a8ed4" }} />
                <h1 className="font-weight-bold">{metrics?.appt || 0}</h1>
              </div>
            </CardMain>
            <CardMain title="Affiliations">
              <div className="text-center">
                <PermContactCalendarIcon
                  style={{ fontSize: 100, color: "#0a8ed4" }}
                />
                <h1 className="font-weight-bold">{metrics?.aff || 0}</h1>
              </div>
            </CardMain>
            <CardMain title="New Affiliations">
              <div className="text-center">
                <FiberNewIcon style={{ fontSize: 100, color: "#0a8ed4" }} />
                <h1 className="font-weight-bold">{metrics?.aff24 || 0}</h1>
              </div>
            </CardMain>
          </Grid>
        </Grid>
        {/* AI Summary */}
        {/* <Grid item xs={12}>
          <CardMain title="AI Summary" icon={SparkIcon}>
            <p>Reporte con AI</p>
          </CardMain>
        </Grid> */}
      </div>
      <div className="mt-5 mb-2">
        <CalendarAdminView />
      </div>
    </DashboardAdmin>
  );
}
